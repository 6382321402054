/* ==============================
module / other
================================= */
body{
  background: $color-def-bg;
  &.clear{
    background: none;
  }
}
.clearfix{
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}
//areamap

/* ==============================
module / map
================================= */

#map{
  // width: 1180px;
  width: 100%;
  max-width: 660px;
  height: 677px;
  border-color: #e5e5e5;
  margin-left: auto;
  @include max-980{
    max-width: auto;
    max-width: initial;
    height: 80vh;
  };

}
//吹き出し
.map-balloon-name{
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}
.map-balloon-link{
  text-align: center;

}
.gm-style-iw{
  >div{
    display: block !important;
    width: max-content;
    margin:20px auto 0 !important;
  }
}

.areamap{
  .archive-content-inner{
    position: relative;
    padding-top:0;
    padding-bottom: 0;
    margin-top: 45px;
    margin-bottom: 60px;
    overflow: hidden;
  }
}

//map-list-wrap

.map-list-wrap{
  width: 520px;
  height: 677px;
  padding:20px 0;
  background-color: #fff;
  position: absolute;
  top: -20px;
  left: 0;
  box-shadow: 1px 0px 3px -1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1;
  transition:  .3s;
}
//map-side-close
.map-side-close{
  position: absolute;
  width: 40px;
  top:20px;
  right:-40px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  background:rgba(0,0,0,0.7);
  border-radius: 0 5px 5px 0;
  @include max-980{
    display: none;
  }
  span{
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    line-height: 1.2;
  }
  .icon-prev{
    font-size: 1.8rem;
    font-weight: bold;
  }
}
.map-side-close-inner{
  padding:20px 0;
}


.map-list-visual{
  width: 100%;
  height: 158px;
  background-image: url('assets/images/areamap/map_visual.jpg');
  position: relative;
  z-index: 2;
  .map-visual-inner{
    text-align: center;
    .area-title{
      width: 100%;
      position: absolute;
      top: 50px;
      font-size: 3rem;
      color: #ffffff;
      font-weight: bolder;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
      .icon-area{
        font-size: 4rem;
        vertical-align: sub;
      }
    }
  }
}

.mdl-map-list{
  width: 100%;
  // display: table;
  padding: 40px;
  box-sizing: border-box;
  overflow-y: auto;
  &>li{
    position: relative;
    padding-bottom: 25px;
    display: inline-table;
    width: 210px;
    vertical-align: top;
    span{
      display: inline-block;
      padding-left:15px;
      text-decoration: none;
      line-height: 1.3;
      font-size: 1.6rem;
      color: $color-def;
      font-weight: bolder;
      cursor: pointer;
      &:hover{
        color: $color-link;
      }
    }
  }
  .icon-arrow_right{
    &:before{
      position: absolute;
      padding-left: 0;
      left: 0;
      top: 0px;
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

}

//map-detail-wrap

.map-detail-wrap{
  @extend .map-list-wrap;
  overflow: scroll;
}

.map-detail-visual{
  width: 100%;
  background-color: #eaeeef;
  background-image: none;
  .map-visual-inner{
    position: relative;
    width: 100%;
    .detail-thumb{
      img{
        @include max-980{
          display: inline-block;
        }
      }
    }
  }
  .thumb-area{
    // background-image: url(assets/images/areamap/spot-sample.jpg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    box-sizing: border-box;
    height: 158px;
    position: relative;
    width: 230px;
    vertical-align: top;
    // text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    @include table-cell;
    @include max-980{
      height: auto;
    }

  }  .spot-info-wrap{
    width: 290px;
    @include table-cell;
    padding:15px 20px;
    box-sizing: border-box;
    vertical-align: top;
    @include max-980{
      padding:10px;
    }
    .spot-title{
      font-size: 2.2rem;
      font-weight: bolder;
      @include max-980{
        font-size: 1.4rem;
      }
    }
    .spot-desc{
      padding-bottom: 10px;
      @include max-980{
        font-size: 1rem;
      }
    }
  }
}

.map-spot-btn{
  font-size: 1.6rem;
  height: 40px;
  line-height: 40px;
  @include max-980{
    font-size: 1.2rem;
    height: 25px;
    line-height: 25px;
    margin: 0;
    width: 100%;
  }
}

.map-event-btn{
  font-size: 1.8rem;
  height: 64px;
  line-height: 64px;
  background: none;
  border: 2px solid #00bbfb;
  color: $color-skyblue;
  @include max-980{
    font-size: 1.2rem;
    height: 40px;
    line-height: 40px;
  }
}

.map-eventlist-wrap{
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  @include max-980{
    padding:15px;
  }
  .detail-side-ttl{
    border: none;
    color: #767676;
    padding-bottom: 0;
    @include max-980{
      font-size: 1.2rem;
      padding-left: 25px;
      &:before{
        font-size: 2rem;
        top:-4px;
      }
    }
  }
}

.mdl-article-mapspot {
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0;
  a{
    color: $color-def;
  }
  >li{
    border-bottom: 1px solid #e5e5e5;
  }
  @include max-980{
    margin-bottom:20px;
    >li{
      border-bottom: none;
    }
  }
  .article-inner{
    padding: 20px 0;
    vertical-align: top;
    @include max-980{
      padding: 10px 0;

      .detail-thumb{
        img{
          display: inline-block;
        }
      }
    }
  }
  .thumb-area{
    // background-image: url(assets/images/event/sample.jpg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    box-sizing: border-box;
    height: 90px;
    position: relative;
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    @include table-cell;
    @include max-980{
      height: 52px;
      width: 52px;
    }
  }
  .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    vertical-align: top;
    @include table-cell;
    .title {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative;
      @include max-980{
        font-size: 1.1rem;
      }
    }
    .panel-info {
      font-size: 1.6rem;
      @include max-980{
        font-size: 1rem;
        span{
          &:before{
            font-size: 1.6rem;
          }
        }
      }

    }
  }
  a{
    text-decoration: none;
    &:after {
      border: none;
    }
  }
}

//map-slide
.map-slide{
  width: 520px;
  height: 677px;
  padding:20px 0;
  left: -100%;
  background-color: #fff;
  position: absolute;
  top: -20px;
  box-shadow: 1px 0px 3px -1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: left .3s;
  @include max-980{
    display: block;
    position: fixed;
    width: 85%;
    height: 80%;
    padding:0;
    top:50%;
    left: 50%;
    transform:translate(-50%,-50%);
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &.modal-open{
      opacity: 1;
      visibility: visible;
      z-index: 10002;
    }
  };
}

#map-slide-front{
  @include max-980{
    display: none;
  };
}
//map only
.modal-close{
  display: none;
  @include max-980{
    display: block;
    margin-top: 15px;
    text-align: center;

  }
}
.modal-close-inner{
  display: inline-block;;
  img{
    width: 14px;
    vertical-align: middle;
  }
  span{
    display: inline-block;
    margin-left: 5px;
    font-size: 1.4rem;
    font-weight: bold;
    vertical-align: middle;
  }
}


/* ==============================
Responsive
================================= */
@media screen and (max-width: 980px){
  a{
    &:hover{
      opacity: 1 !important;
    }
  }
  .dateSlide {
    margin-bottom: 15px;
  }
  .dateSlideList {
    width: 100%;
    li {
      width: 70px;
    }
  }
  .event-list-area, .top-spot-list {
    margin: 0 8px 20px;
  }
}
