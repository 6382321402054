/* ==============================
module / icon
================================= */
@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?r6igjb');
  src:  url('/assets/fonts/icomoon.eot?r6igjb#iefix') format('embedded-opentype'),
    url('/assets/fonts/icomoon.ttf?r6igjb') format('truetype'),
    url('/assets/fonts/icomoon.woff?r6igjb') format('woff'),
    url('/assets/fonts/icomoon.svg?r6igjb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-car:before {
  content: "\e927";
}
.icon-ship:before {
  content: "\e928";
}
.icon-bus:before {
  content: "\e929";
}
.icon-amusement:before {
  content: "\e900";
}
.icon-area:before {
  content: "\e901";
}
.icon-arrow_bottom_thin:before {
  content: "\e902";
}
.icon-arrow_bottom:before {
  content: "\e903";
}
.icon-arrow_left_thin:before {
  content: "\e904";
}
.icon-arrow_left:before {
  content: "\e905";
}
.icon-arrow_right_thin:before {
  content: "\e906";
}
.icon-arrow_right:before {
  content: "\e907";
}
.icon-arrow_top_thin:before {
  content: "\e908";
}
.icon-arrow_top:before {
  content: "\e909";
}
.icon-calender1:before {
  content: "\e90a";
}
.icon-calender2:before {
  content: "\e90b";
}
.icon-calender3:before {
  content: "\e90c";
}
.icon-coupon:before {
  content: "\e90d";
}
.icon-course:before {
  content: "\e90e";
}
.icon-event:before {
  content: "\e90f";
}
.icon-facebook1:before {
  content: "\e910";
}
.icon-facebook2:before {
  content: "\e911";
}
.icon-feature:before {
  content: "\e912";
}
.icon-food:before {
  content: "\e913";
}
.icon-home:before {
  content: "\e914";
}
.icon-language:before {
  content: "\e915";
}
.icon-leaning:before {
  content: "\e916";
}
.icon-line:before {
  content: "\e917";
}
.icon-mobile:before {
  content: "\e918";
}
.icon-others:before {
  content: "\e919";
}
.icon-pin:before {
  content: "\e91a";
}
.icon-prev:before {
  content: "\e91b";
}
.icon-relax:before {
  content: "\e91c";
}
.icon-search:before {
  content: "\e91d";
}
.icon-setting:before {
  content: "\e91e";
}
.icon-shopping:before {
  content: "\e91f";
}
.icon-speaker:before {
  content: "\e920";
}
.icon-station:before {
  content: "\e921";
}
.icon-tourism:before {
  content: "\e922";
}
.icon-twitter:before {
  content: "\e923";
}
.icon-useful:before {
  content: "\e924";
}
.icon-window:before {
  content: "\e925";
}
.icon-youtube:before {
  content: "\e926";
}
