/* ==============================
module / assign
================================= */

//table-green-cover
.table-green-cover{
  position: relative;
  background:#ddd;
  padding:15px 20px;
  box-sizing: border-box;
}
.table-green-cover-clear{
  position: absolute;
  top:15px;
  right: 20px;
  text-decoration: underline;
  font-size: 1.2rem;
  color: #4094DD;
  cursor: pointer;

  a{
    text-decoration: underline;
    font-size: 1.2rem;
  }
}

//login-table
.login-table{
  width: 100%;
  th{
    width: 190px;
    text-align: left;
    font-size:1.4rem;
    line-height:1.7;
    font-weight: normal;
    box-sizing: border-box;
    padding: 0 10px 30px 0;
  }
  td{
    padding: 0 0 30px 0;
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"]
    {
      width: 100%;
    }
  }
}

//mdl-def-table
.mdl-def-table{
  width: 100%;
  tr{
    border-top:1px solid #ddd-gray;
    border-bottom:1px solid #ddd-gray;
  }
  th{
    position: relative;
    width: 25%;
    padding:20px 20px;
    line-height:1.67;
    text-align: left;
    vertical-align: middle;
    // border-top:4px solid #fff;
    // border-bottom:3px solid #fff;
    background: #ddd-gray;
    box-sizing: border-box;
    vertical-align: middle;
    &:before{
      position: absolute;
      content:"";
      display: block;
      width: 100%;
      height: 2px;
      top:0px;
      left:0;
      background:#fff;
      z-index: 1;

    }
    &:after{
      position: absolute;
      content:"";
      display: block;
      width: 100%;
      height: 2px;
      bottom:0px;
      left:0;
      background:#fff;
      z-index: 1;
    }
  }
  td{
    width: 100%;
    padding:20px 20px;
    background: #fff;
    line-height:1.71;
    box-sizing: border-box;
    vertical-align: top;
  }
  .table-green-cover{
    margin-top:10px;
  }
  &.mini{
    th{
      padding:10px;
      width: 84px;
      font-size: 1.2rem;
      line-height:1.33;
      font-weight: normal;
    }
    td{
      width: auto;
      padding:10px;
      font-size: 1.2rem;
      line-height:1.33;
    }
  }
}
.def-table-ttl-wrap{
  display: table;
  width: 100%;
  table-layout: fixed;
}
.def-table-ttl{
  width: 170px;
  display: table-cell;
}
.def-table-label{
  width: 34px;
  display: table-cell;
  text-align: right;
  vertical-align: middle;
}
.table-label{
  font-size: 1rem;
  color: #fff;
  display: inline-block;
  width: 34px;
  text-align: center;
  padding:3px 5px;
  border-radius: 4px;
  box-sizing: border-box;
  &.green{
    background:$color-turquoise;
  }
  &.red{
    background:$color-red;
  }
}
.table-in-block{
  @include clearfix;
  margin-bottom: 5px;
}
.table-in-cel-left{
  float: left;
  width: calc(100% - 80px);
}
.table-in-cel-right{
  float: right;
  width: 80px;
  text-align: right;
}
.table-calendar-center{
  font-size: 1.2rem;
  display: inline-block;
  padding:0 5px;
}

//mdl-table-in-block

.table-in-block-160{
  width: 160px;
  padding-right: 10px;
  box-sizing: border-box;
  vertical-align: middle;
}
.table-in-block-130{
  width: 130px;
  padding-right: 10px;
  vertical-align: middle;
}
.table-in-block-450{
  width: 450px;
}
.table-in-block-full{
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle;
}


//mdl-bid-table
.mdl-bid-table{
  width: 100%;
  tr{
    border-top:1px solid #ddd-gray;
    border-bottom:1px solid #ddd-gray;
  }
  th{
    position: relative;
    width: 25%;
    padding:20px 20px;
    font-size: 1.2rem;
    line-height:1.67;
    text-align: left;
    vertical-align: middle;
    border-top:5px solid #fff;
    border-bottom:4px solid #fff;
    background: #ddd-gray;
    box-sizing: border-box;
    vertical-align: middle;
    &:before{
      position: absolute;
      content:"";
      display: block;
      width: 100%;
      height: 1px;
      top:-3px;
      left:0;
      background:#ddd-gray;
    }
    &:after{
      position: absolute;
      content:"";
      display: block;
      width: 100%;
      height: 1px;
      bottom:-3px;
      left:0;
      background:#ddd-gray;
    }
  }
  td{
    // width: 280px;
    width: calc((100% - 25%)/2);
    padding:20px 20px;
    font-size: 1.2rem;
    line-height:1.71;
    box-sizing: border-box;
    vertical-align: middle;
  }
  .table-green-cover{
    margin-top:10px;
  }
  &.mini{
    th{
      padding:10px;
      width: 84px;
      font-size: 1.2rem;
      line-height:1.33;
      font-weight: normal;
    }
    td{
      width: auto;
      padding:10px;
      font-size: 1.2rem;
      line-height:1.33;
    }
  }
  .def-thead{
    border-top: none;
  }
}

.def-thead{
  th{
    background:none;
    &:before{
      display: none;
    }
  }
  td{
    position: relative;
    font-weight: bold;
    line-height:1.67;
    text-align: center;
    span{
      font-size: 1.2rem;
      position: relative;
      z-index: 2;
    }
  }
  .bid-table-border{
    position: relative;
  }
  .bid-table-cover{
    position: absolute;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background:#ddd-gray;
    top:2px;
    left: 2px;
    z-index: 1;
  }
}
.bid-table-border{
  border-right: 1px solid #ddd-gray;
}
//conditions-list-table
.conditions-list-table{
  width: 100%;
  table-layout: fixed;
  font-size: 1.2rem;
}
td{
  vertical-align: top;
}
.conditions-list-label{
  width: 120px;
  box-sizing: border-box;
  vertical-align: top;
  >span{
    display: inline-block;
    width: 104px;
    text-align: center;
    background: $color-def;
    font-size: 1rem;
    color: #fff;
    padding:5px;
    box-sizing: border-box;
  }
}
.conditions-list-cat{
  width: 155px;
  padding:5px 0;
}
.conditions-list-icon{
  width: 15px;
  text-align: center;
  padding:5px 0;
}
.conditions-list-about{
  width: 100%;
  padding:5px 0;
  line-height:1.67;
}
//mdl-black-table
.mdl-black-table{
  width: 100%;
  // table-layout: fixed;
  tr{
    border-bottom: 1px solid #ddd-gray;
  }
  td{
    border-right: 1px solid #ddd-gray;
    background: #fff;
    text-align: center;
    height: 70px;
    padding:20px 30px;
    box-sizing: border-box;
    vertical-align: middle;
    a{
      text-decoration: underline;
    }
  }
  tr{
    >td{
      &:first-child{
        border-left: 1px solid #ddd-gray;
      }
      &:last-child{
      }
    }
  }
  &.no-border{
    tr{
      &:last-child{
        border-bottom: none;
      }
      >td{
        &:first-child{
          border-left: none;
        }
        &:last-child{
          border-right: none;
        }
      }
    }
  }
  .black-table-name{
    text-align: left;
  }
  .btn-wrap{
    .btn-t{
      width: 140px;
    }
    .btn{
      width: 140px;
    }
  }
  .color-red{
    display: block;
    font-weight: bold;
  }
  .color-red-message{
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
}

.black-table-head{
  td{
    height: 30px;
    padding:0;
    background: $color-def;
    border-right:none;
    color:#fff;
    text-align: center;
    font-weight: bold;
    font-weight: 1.4rem;
    vertical-align: middle;
  }
}
.table-active-label{
  width: 130px;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding:3px 0;
  box-sizing: border-box;
  &.red{
    background:$color-red;
  }
  &.green{
    background:$color-turquoise;
  }
  &.blue{
    background:#0C5671;
  }
  &.gray{
    background:$colro-form;
  }
}
