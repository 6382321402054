/* ==============================
module / footer
================================= */
.l-banner-white {
  width: 100%;
  min-width: 1280px;
  background: #fff;
  .inner {
    padding: 60px 0 30px;
    .l-banner-list {
      ul {
        @include flex-start;
      }
    }
  }
}
.l-banner-gray {
  width: 100%;
  min-width: 1280px;
  .inner {
    padding: 30px;
  }
  .l-banner-carousel {
    margin: 0 auto;
    width: 1141px;
    .l-banner-carousel-body {
      .prev, .next {
        cursor: pointer;
        font-size: 40px;
        position: absolute;
        top: 10px;
        &:hover {
          opacity: 0.8;
        }
      }
      .prev {
        left: -40px;
      }
      .next {
        right: -40px;
      }
    }
    .l-banner-carousel-item {
      margin: 0 10px;
    }
  }
}

.l-footer{
  width: 100%;
  min-width: 1280px;
  background: #fff;

  a{
    color: inherit;
    text-decoration: none;
  }
  .footer-main {
    margin: 0 auto;
    padding: 40px 0;
    position: relative;
    width: 1180px;
    .l-footer-row {
      @include flex-start;
    }
    .l-footer-menu {
      width: 236px;
      dt {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 10px;
      }
      dd {
        line-height: 2;
        a:hover {
          text-decoration: underline;
          @media screen and (max-width: 980px){
            text-decoration: none;

          }
        }
      }
    }
  }
  .l-footer-sub {
    border-top: 1px solid $color-def-border;
    position: relative;
    .l-footer-pagetop {
      background: #eaeeef;
      @include border-top-left-radius(5px);
      @include border-top-right-radius(5px);
      color: $color-skyblue;
      font-weight: bold;
      height: 30px;
      position: absolute;
      top: -31px;
      right: 0;
      width: 140px;
      span {
        display: block;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        width: 100%;
        height: 100%;
        &:hover {
          opacity: .7;
        }
      }
    }
    .l-footer-subMenu {
      @include clearfix;
      dl {
        display: inline-block;
        float: left;
        margin-right: 20px;
        &:after {
          background: $color-def-border;
          content: "";
          display: inline-block;
          height: 20px;
          width: 1px;
          vertical-align: middle;
        }
        &:last-child {
          &:after {
            background: none;
          }
        }
        dt {
          float: left;
          font-weight: bold;
          margin-right: 20px;
        }
        dd {
          float: left;
          ul {
            li {
              display: inline-block;
              margin-right: 20px;
            }
            &.l-footer-sns {
              margin-top: -4px;
              li {
                color: #767676;
                font-size: 30px;
                line-height: 1;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.l-footer-inner {
  margin: 0 auto;
  padding: 15px 0 7px;
  overflow: hidden;
  width: 1180px;
}
.l-footer-navi {
  background: $color-skyblue;
  color: #fff;
  .l-footer-inner {
    padding: 10px 0;
  }
  .l-footer-naviList {
    color: #fff;
    font-weight: bold;
    float: left;
    li {
      display: inline-block;
      margin-right: 40px;
      i {
        font-size: 2rem;
        display: inline;
        vertical-align: sub;
      }
    }
  }
}
.copyright{
  @include clearfix;
  float: right;
  font-size: 1rem;
  line-height: 2.4;
  color: #fff;
}

/* ==============================
Responsive
================================= */
@media screen and (max-width: 980px){
  .l-footer {
    min-width: 100%;
    .footer-main {
      width: 100%;
      padding: 0;
      .l-footer-row {
        display: block;
      }
      .l-footer-menu {
        border-top: 1px solid $color-def-border;
        width: 100%;
        dt {
          position: relative;
          height: 44px;
          line-height: 44px;
          margin-bottom: 0;
          padding-left: 15px;
          cursor: pointer;
          &.active{
            .footer-row-icon-02{
              opacity: 0;
            }
          }
        }
        dd {
          background: $color-def-bg;
          overflow: hidden;
          display: none;
          &.active {
          }
        }
      }
      .l-footer-menuList {
        overflow: hidden;
        li {
          margin: 10px 0;
          padding-left: 25px;
        }
        a{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .l-footer-sub {
      border-top: none;
      .l-footer-pagetop {
        bottom: 0;
        top: initial;
        width: 100%;
        height: 45px;
        span {
          line-height: 45px;
        }
      }
      .l-footer-subMenu {
        padding-bottom: 45px;
        dl {
          float: none;
          width: 100%;
          display: block;
          &:after {
            display: none;
          }
          &:nth-child(2) {
            border-bottom: 1px solid $color-def-border;
          }
          dt {
            display: block;
            position: relative;
            font-size: 1.6rem;
            margin-right: 0;
            float: none;
          }
          dd {
            float: none;
            width: 100%;
            .footer-access-list{
              li{
                display: block;
                margin: 10px 0;
                margin-right: 0;
                padding-left: 25px;
              }
            }
            ul {
              &.l-footer-sns {
                text-align: center;
                margin: 30px 0;
                li {
                  font-size: 5rem;
                  margin: 0;
                }
              }
            }
            // &.active {
            //   ul {
            //     li {
            //       display: block;
            //       width: 100%;
            //       margin: 10px 0;
            //       padding-left: 25px;
            //       line-height: 2;
            //     }
            //   }
            // }
          }
        }
      }
      .l-footer-menu {
        border-top: 1px solid $color-def-border;
        width: 100%;
        dt {
          height: 44px;
          line-height: 44px;
          margin-bottom: 0;
          padding-left: 15px;
          &.active{
            .footer-row-icon-02{
              opacity: 0;
            }
          }
        }
        dd {
          background: $color-def-bg;
          overflow: hidden;
        }
      }
    }
    //footer-row-icon
    .footer-row-icon{
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      top:50%;
      right: 7px;
      transform: translateY(-50%);
      span{
        display: block;
        position: absolute;
        background: #a6a6a6;
      }
    }
    .footer-row-icon-01{
      width: 100%;
      height: 2px;
      top:5px;
    }
    .footer-row-icon-02{
      width: 2px;
      height: 100%;
      right: 5px;
      -webkit-transition: .3s;
      transition: .3s;
      opacity: 1;
    }
  }
  .l-banner-white {
    min-width: 100%;
    .inner {
      box-sizing: border-box;
      padding: 30px 8px;
      .l-banner-list {
        ul {
          // display: block;
          li {
            width: 32%;
            text-align: center;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .l-banner-gray {
    min-width: 100%;

    .inner {
      padding: 25px 8px;
      box-sizing: border-box;
    }
    .l-banner-carousel {
      width: 100%;
    }
  }
  .l-footer-inner {
    width: 100%;
    padding: 0;
  }
  .l-footer-navi {
    text-align: center;
    .l-footer-naviList {
      float: none;
      li {
        display: block;
        margin-right: 0;
        line-height: 2;
      }
    }
  }
  .copyright {
    float: none;
  }
}
@include max-640{
  .l-banner-white{
    .inner {
      .l-banner-list {
        ul {
          display: block;
          li {
            width: 100%;
            text-align: center;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

/* ==============================
module / breadcrumb
================================= */
.breadcrumb-wrap{
  padding:10px 0;
  background: #fff;
  border-top: 1px solid #eaeeef;
  border-bottom: 1px solid #eaeeef;
}
.breadcrumb{
  width: 1180px;
  margin:0 auto;
  >li{
    display: inline-block;
    margin-right: 5px;
    &:after{
      @include icon;
      content: "\e906";
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }
    &:first-child{
      font-weight: bold;
    }
    &:last-child{
      &:after{
        display: none;
      }
    }
    span{
      vertical-align: middle;
    }
  }
  .icon-home{
    font-size: 2rem;
  }
  a{
    text-decoration: none;
    color: $color-def;
    vertical-align: middle;
  }
}
/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .breadcrumb{
    width: 100%;
    >li{
      font-size: 1rem;
    }
  }
}
