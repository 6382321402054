/* ==============================
module / header
================================= */
.l-header{
  width: 100%;
  min-width: 1280px;
  // display: none; //あとで戻す
  a{
    text-decoration: none;
    color: inherit;
  }
  div{
    line-height: 1;
  }
  @media screen and (max-width: 640px) {
    min-width: auto;
  }
}
.header-top{
  background-color: #fff;
  border-top: 4px solid $color-skyblue;
  border-bottom: 1px solid $color-def-border;
  padding: 15px 25px;
}
.header-top-inner{
  @include flex-center;
  margin:0 auto;
  @include clearfix;
}
.header-left{
  width: calc(100% - 480px);
  vertical-align: middle;
}
.header-right{
  @include flex-center;
  width: 480px;
  vertical-align: middle;
}
.header-logo{
  display: inline-block;
  vertical-align: middle;
  img{
    width: 194px;
  }
}
//header-search
.l-header-search {
  display: inline-block !important;
  margin: 0 20px;
  vertical-align: middle;
}
.l-search {
  display: inline-block;
  position: relative;
  border: 2px solid $color-def-border;
  background: #eee;
  border-radius: 3px;
  height: 3.6rem;
  .header-input-wrap {
    display: block;
    position: relative;
    float: left;
    box-sizing: border-box;
    height: 3.6rem;
    &.search-select {
      border-right: 1px dashed $color-def-border;
      width: 80px;
      &.icon-arrow_bottom {
        position: relative;
        &:before {
          font-weight: bold;
          position: absolute;
          top: 33%;
          margin: auto;
          right: 5px;
        }
      }
    }
    &.search-button {
      top: -2px;
      right: -1px;
      z-index: 1;
      height: 4rem;
    }
  }
}
.header-search-input {
  position: relative;
  z-index: 1;
  padding-left: 8px;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  line-height: normal;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 3.6rem;
  &.button {
    @include border-top-right-radius(3px);
    @include border-bottom-right-radius(3px);
    background: #767676;
    color: #fff;
    height: 4rem;
    width: 4rem;
    padding: 0;
    i {
      font-size: 30px;
    }
    &:hover {
      opacity: .8;
    }
  }
}
input[type="text"].header-search-input {
  border: none;
  width: 380px;
}

//header-option
.header-option-list {
  .option-list-item {
    display: inline-block;
    font-size: .71rem;
    margin-right: 18px;
    vertical-align: middle;
    &:before {
      display: inline-block;
      font-size: 2rem;
      vertical-align: inherit;
    }
    &.select-lng{
      cursor: pointer;
      display: inline-block;
      position: relative;
      z-index: 999;
      i {
        font-size: 1.4rem;
        margin-left: 5px;
        vertical-align: inherit;
      }
    }
    .lng-list {
      position: absolute;
      z-index: 999;
      width: 100px;
      overflow: hidden;
      height: 0;
      left: 5px;
      -webkit-transition: .3s;
      transition: .3s;
      margin-top: 5px;
      li {
        background-color: $color-softblue;
        border-bottom: 1px solid $color-skyblue;
        height: 24px;
        text-align: center;
        &:last-child {
          border-bottom: none;
        }
        a {
          color: $color-skyblue;
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 24px;
        }
      }
      &.show {
        height: 124px;
        -webkit-transition: .3s;
        transition: .3s;
      }
    }
  }

}
//header-sns
.l-header-snsList {
  @include flex-center;
  li {
    margin-left: 8px;
    span {
      background: #ddd;
      color: #fff;
      border-radius: 50%;
      font-size: 26px;
      height: 26px;
      width: 26px;
      &.icon-twitter { background: #55acee;}
      &.icon-facebook1 { background: #315096;}
      &.icon-youtube { background: #d00f31;}
    }
  }
}

//nav
nav{
  margin: 0 auto;
  width: 100%;
  background: #fff;
  .l-nav-inner {
    @include flex-center;
    margin: 0 auto;
    width: 1235px;
  }
}
.l-header-navList{
  background: #fff;
  @include flex-start;
  width: 990px;
  >li{
    position: relative;
    width: calc(100% / 9);
    height:90px;
    text-align: center;
    &:nth-child(2) {
      margin-right: 10px;
      &:after {
        background: $color-def-border;
        bottom: 0;
        content: "";
        display: block;
        height: 70px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
      >span {
        display: block;
        padding: 60px 0 0;
        &:before{
          font-size: 60px;
          display: block;
          width: 60px;
          height: 60px;
          position: absolute;
          top:5px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
    @each $category, $color in $cate-color {
      &.#{$category} a span {
        color: $color;
      }
    }
  }
  @media screen and (min-width: 981px){
  .navList-item:hover, .current {
    a {
      &:before {
        position:absolute;
        left:0;
        bottom: 0;
        right: 0;
        margin: auto;
        content:"";
        display: block;
        border: 8px solid transparent;
        width: 0px;
      }
      &:after{
        position:absolute;
        left:0;
        bottom:0;
        content:"";
        display: block;
        width: 100%;
        height: 3px;
      }
    }
    @each $category, $color in $cate-color {
      &.#{$category} a:before { border-bottom-color: $color;}
      &.#{$category} a:after { background: $color;}
    }
  }
}
}
.l-header-subnav {
  @include flex-center;
  .subnav-menu {
    @include border-radius(3px);
    font-weight: bold;
    height: 65px;
    margin-left: 10px;
    position: relative;
    text-align: center;
    width: 110px;
    &.skyblue {
      background: $color-skyblue;
      color: #fff;
    }
    &.softblue {
      background: $color-softblue;
      color: $color-skyblue;
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
      >span {
        display: block;
        font-weight: bold;
        padding: 40px 0 0;
        &:before{
          font-size: 30px;
          font-weight: normal;
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          top:5px;
          left: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
}

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  #page-wrap{
    position: fixed;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 100;
  }
  #modal-wrap{
    position: fixed;
    top:0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 10001;
  }
  //header-top
  .l-header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    z-index: 10000;
  }
  .header-top{
    position: relative;
    height: 50px;
    box-sizing: border-box;
  }
  .header-top-inner{
    display: block;
  }
  .header-left{
    width: 100%;
    text-align: center;
  }
  .header-logo{
    img{
      width: 109.5px;
    }
  }
  //nav
  nav {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    .l-nav-inner{
      display: block;
      width: 100%;
      padding-bottom:20px;
      border-bottom: 1px solid #eee;
    }
  }
  .nav-slide-cover{
    display: block;
    width: 80%;
    height: 100%;
    padding-bottom: 90px;
    position: fixed;
    top:50px;
    right: -80%;
    margin:0 0 0 auto;
    background: #fff;
    overflow-y:auto;
    box-sizing: border-box;
    z-index: 1000;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .l-header-navList{
    display: block;
    width: 100%;
    padding:20px 10px 25px;
    box-sizing: border-box;
    >li{
      position: static;
      width: 100%;
      height: auto;
      text-align: left;
      margin-top: 20px;
      &:first-child{
        margin-top: 0;
      }
      >a{
        position: relative;
        height: 30px;
        padding-left: 30px;
        line-height: 30px;
        font-size: 1.5rem;
        box-sizing: border-box;
        span{
          position: absolute;
          left: 0;
          top:0;
          padding:0;
          display: block;
          &:before{
            position: static ;
            margin:0;
            width: 30px;
            height: 30px;
            font-size: 3rem;
          }
        }
      }
      &:nth-child(2){
        &:after{
          display: none;
        }
      }
    }
  }
  .l-header-subnav{
    display: block;
    padding:0 10px;
    .subnav-menu{
      width: 100%;
      height: 45px;
      margin:0;
      a{
        font-size: 1.4rem;
        >span{
          display: inline-block;
          line-height: 45px;
          vertical-align: top;
          padding:0;
          &:before{
            position: static;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
      &.skyblue{
        margin-bottom: 10px;
      }
    }
  }
  //lng-list
  .lng-list{
    margin-top: 20px;
    padding:0 10px;
    >li{
      margin-top: 15px;
      &:first-child{
        margin-top: 0;
      }
    }
    a{
      font-size: 1.3rem;
    }
  }
  //l-header-snsList
  .l-header-snsList{
    margin-top: 20px;
    display: block;
    letter-spacing: -.4em;
    >li{
      display: inline-block;
      letter-spacing: normal;
    }
  }
  .l-header-search{
    display: none !important;
  }
  // nav-btn
  .nav-btn{
    position: absolute;
    right: 10px;
    top: 9px;
  }
  .nav-btn-menu{
    position: relative;
    width: 20px;
    height: 15px;
    display: block;
    margin:0 auto;
    >span{
      position: absolute;
      display: block;
      width: 100%;
      right: 0;
      height: 2px;
      background: #333;
      -webkit-transition: .4s;
      transition: .4s;
      &:nth-child(1){
        top: 0;
      }
      &:nth-child(2){
        top: 6.5px;
      }
      &:nth-child(3){
        bottom:0;
      }
    }
  }
  .search-btn-txt{
    font-size: 1rem;
    -webkit-transform:scale(.8);
    transform:scale(.8);
  }
  //search-btn
  .search-btn{
    position: absolute;
    left: 10px;
    top: 3px;
    >span{
      position: absolute;
      top:0;
      left: 0;
      width: 40px;
      height: 28px;
      display: block;
      text-align: center;
      -webkit-transition: .3s;
      transition: .3s;

      &:before{
        font-size: 2.8rem;
      }
    }
    .search-btn-txt{
      position: absolute;
      top:25px;
      // left: 0;
      // width: 100%;
      width: 50px;
      left: -5px;
      display: block;
    }
    .search-btn-close{
      position: relative;
      width: 40px;
      height: 28px;
      display: block;
      margin: 0 auto;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: .3s;
      transition: .3s;
      >span{
        position: absolute;
        display: block;
        width: 28px;
        left: 6px;
        height: 2px;
        background: #333;
        -webkit-transition: .3s;
        transition: .3s;
        &:nth-child(1){
          top: 14px;
          transform: rotate(45deg);
        }
        &:nth-child(2){
          bottom: 12px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  //search-nav
  .search-nav{
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    transition-timing-function:ease-in-out;
    transition-timing-function:ease-in-out;

  }
  .search-nav-inner{
    padding:30px 10px 30px;
    box-sizing: border-box;
  }
  .front-ttl{
    text-align: center;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 2rem;
  }
  .front-sub-ttl{
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: center;
    color: #282828;
  }
  .search-nav-content{
    margin-top: 20px;
  }
  .l-search{
    display: block;
  }
  input[type="text"]{
    &.header-search-input{
      width: 100%;
      border:1px solid #cacaca;
      background: #eee;
    }
  }
  .header-input-wrap{
    position: relative;
    &:before{
      font-size: 3rem;
      display: block;
      top: 2px;
      right: 0;
      position: absolute;
      color: #767676;
      z-index: 10;
    }
  }

}
