/* ==============================
module / top
================================= */
.l-main {
  position: relative;
}
.top-section h2 {
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 45px;
  text-align: center;
  span {
    vertical-align: middle;
    &.en {
      @include en;
      font-size: 4rem;
      margin-right: 30px;
      position: relative;
      &:after {
        background: $color-def;
        bottom: 0;
        content: "";
        display: inline-block;
        height: 36px;
        margin: auto 0 auto 30px;
        position: absolute;
        top: 0;
        vertical-align: middle;
        width: 2px;
      }
    }
    &.ja {
      margin-left: 30px;
    }
  }
}
.top-section.l-pkup h2 {
  span.en:after {
    background: #fff;
  }
}

//main-visual
.visual{
  position: relative;
  width: 100%;
  height: 440px;
  background-image:
    url(assets/images/top/bg_dot.png),
    url(assets/images/top/visual_top.jpg);
  background-position: center bottom;
  background-repeat:
    repeat,
    no-repeat;
  background-size:
    auto,
    cover;
  box-sizing: border-box;
}
.visual-inner {
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 1180px;
  .visual-lead {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 190px;
    .visual-lead-main {
      color: #fff;
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      text-shadow: 0px 0px 10px rgba(33,33,33,0.5);
    }
  }
}

//news
.news {
  background: #fff;
  box-shadow: 0px 2px 1px rgba(183,183,183,0.34);
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 410px;
  width: 1180px;
  z-index: 99;
  a{
    text-decoration: none;
    color: inherit;
  }
  .l-news {
    @include table;
    line-height: 60px;
    dt {
      @include table-cell;
      background: $color-skyblue;
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      height: 60px;
      text-align: center;
      width: 145px;
    }
    dd {
      @include table-cell;
      padding-left: 20px;
      .l-news-btn {
        color: $color-skyblue;
        display: inline-block;
        float: right;
        font-weight: bold;
        margin-right: 40px;
        position: relative;
        i {
          font-size: 2rem;
          font-weight: bold;
          margin: -1rem 0 0 6px;
          top: 50%;
          position: absolute;
        }
      }
    }
  }
}
//cover
.l-tc-panel{
  a{
    position: relative;
    z-index: 2;
  }
}
.cover{
  position: absolute;
  top:0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
}

//top-category
.top-category {
  background: url(assets/images/top/bg_panel.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  position: relative;
  width: 100%;
  a{
    color: inherit;
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%;
  }
}
.l-tc-inner {
  @include flex-center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 45px 0;
  position: relative;
  width: 1210px;
  z-index: 1;
  .l-tc-panel {
    @include border-radius(5px);
    border: 2px solid $color-def-border;
    box-sizing: border-box;
    display: block;
    height: 290px;
    margin: 15px;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    width: calc(100% / 4 - 30px);
    @each $category, $color in $cate-color {
      &.#{$category} {
        border-color: $color;
      }
      &.#{$category} .icon, &.#{$category} .category-slug {
        color: $color;
      }
      &.#{$category} .hover-action {
        background-color: $color;
      }
    }
    a{
      position: relative;
      z-index: 2;
      &:hover {
        opacity: 1;
        .icon, h3, .category-slug {
          @media screen and (min-width: 981px){
            color: #fff;

          }
        }
      }
    }
  }
}
.panel-inner {
  bottom: 0;
  height: 150px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 150px;
  .icon {
    font-size: 8.5rem;
    transition: color 0.2s ease-in-out;
  }
  h3 {
    font-size: 2rem;
    transition: color 0.2s ease-in-out;
  }
  .category-slug {
    @include en;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
  }
}

//hover & cover
.l-tc-panel .hover-action {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1;
}
.l-tc-panel:hover .hover-action {
  width: 400%;
  height: 400%;
}
.cover{
  position: absolute;
  top:0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
}


//weekly event
.top-event-list {
  @include flex-start;
  flex-wrap: wrap;
}

//hot spot
.l-hs-list {
  background: $color-softblue;
  .top-spot-list {
    @include flex-start;
    flex-wrap: wrap;
  }
}

//pick up
.l-pkup {
  background: url(assets/images/event/fireworks.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  height: 675px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  &:before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1;
  }
  .btn {
    @include btn-color;
  }
}
.top-pickup-contents {
  @include table;
  margin-bottom: 40px;
  .thumbnail {
    @include table-cell;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 360px;
    width: 540px;
  }
  .text {
    @include table-cell;
    font-size: 2rem;
    padding-left: 50px;
    a{
      display: block;
      text-decoration: none;
      color: inherit;
      width: 100%;
      height: 100%;
      &:hover {
        opacity: .8;
      }
    }
    .pickup-ttl {
      font-size: 3.4rem;
      line-height: 1;
      margin-bottom: 20px;
    }
    .pickup-date {
      display: block;
      font-size: 1.8rem;
      margin-bottom: 50px;
    }
  }
}

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px){
  .visual {
    height: 250px;
  }
  .visual-inner {
    width: 100%;
    .visual-lead {
      top: 110px;
      .visual-lead-main {
        font-size: 2.5rem;
      }
    }
  }
  .news {
    height: 30px;
    margin: 0 8px;
    top: 235px;
    width: calc(100% - 16px);
    .l-news {
      line-height: 30px;
      dt {
        font-size: .9rem;
        height: 30px;
        width: 70px;
      }
      dd {
        font-size: 1rem;
        padding-left: 10px;
      }
    }
  }
  .l-tc-inner {
    padding: 35px 0 15px;
    width: 100%;
    .l-tc-panel {
      border: none;
      margin: 3px 3px 3px 10px;
      height: 50px;
      width: calc(100% / 2 - 13px);
      &:nth-child(even) {
        margin: 3px 10px 3px 3px;
      }
      &:after {
        content: "";
        display: block;
        width: 0;
      	height: 0;
        border: 8px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
      }
      @each $category, $color in $cate-color {
        &.#{$category}:after {
          border-top-color: $color;
          border-left-color: $color;
        }
      }
    }
  }
  .panel-inner {
    box-sizing: border-box;
    height: 50px;
    line-height: 1;
    text-align: left;
    padding: 10px 5px 5px 45px;
    width: auto;
    .icon {
      font-size: 4.5rem;
      left: 0;
      line-height: 50px;
      position: absolute;
      top: 0;
    }
    h3 {
      font-size: 1.4rem;
      letter-spacing: -.1rem;
    }
    .category-slug {
      font-size: .9rem;
    }
  }
  .top-section {
    h2 {
      font-size: initial;
      margin-bottom: 25px;
      span {
        &.en {
          font-size: 2.4rem;
          display: block;
          margin-right: 0;
          &:after {
            display: none;
          }
        }
        &.ja {
          font-weight: normal;
          margin: 5px 0;
        }
      }
    }
  }
  .l-pkup {
    height: auto;
  }
  .top-pickup-contents {
    display: block;
    padding: 0 8px;
    box-sizing: border-box;
    margin-bottom: 20px;
    .thumbnail {
      display: block;
      width: 100%;
      height: 200px;
    }
    .text {
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
      padding-left: 0;
      margin-top: 15px;
      .pickup-ttl{
        font-size: 1.7rem;
        margin-bottom: 8px;
      }
      .pickup-date {
        font-size: 1rem;
        margin-bottom: 20px;
      }
    }
  }
}
