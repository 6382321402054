/* ==============================
module / list
================================= */

//mdl-col-list
.mdl-col-list{
  @include border-radius(5px);
  background: #fff;
  box-shadow: 0px 2px 1px rgba(183,183,183,0.34);
  margin-bottom: 36px;
  position: relative;
  a{
    color: inherit;
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%;
    &:after {
      @include border-radius(5px);
      border: 1px solid $color-def-border;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

//イベントのリスト
.mdl-article-event {
  width: 575px;
  .article-inner {
    @include table;
    box-sizing: border-box;
    padding: 15px;
  }
  .thumb-area {
    @include table-cell;
    background-image: url(assets/images/event/sample.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid $color-def-border;
    text-indent:100%;
    white-space:nowrap;
    overflow:hidden;
    height: 190px;
    width: 190px;
    vertical-align: top;
  }
  .text-area {
    @include table-cell;
    padding-left: 10px;
    position: relative;
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
    }
    .tag {
      bottom: 0;
      position: absolute;
      li {
        background: #eaeeef;
        display: inline-block;
        line-height: 1;
        margin: 3px;
        padding: 5px;
        &:before {
          content: "#";
        }
      }
    }
  }
}

//スポットのリスト
.mdl-article-spot {
  height: 370px;
  width: 360px;
  .thumb-area {
    background-image: url(assets/images/event/sample.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    height: 240px;
    position: relative;
    width: 360px;
    vertical-align: top;
    .rl-event {
      background: #ddd;
      @include border-radius(50%);
      left: 10px;
      height: 50px;
      position: absolute;
      top: 10px;
      width: 50px;
      &:before {
        font-size: 5rem;
        color: #fff;
      }
    }
  }
  .text-area {
    box-sizing: border-box;
    height:130px;
    padding: 20px;
    position: relative;
    .label-category {
      background: #333;
      color: #fff;
      display: block;
      font-weight: bold;
      height: 30px;
      left: 0;
      line-height: 30px;
      position: absolute;
      text-align: center;
      top: -15px;
      width: 110px;
    }
    .title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative;
    }
    .panel-info {
      bottom: 20px;;
      position: absolute;
    }
  }
  @each $category, $color in $cate-color {
    &.#{$category} .rl-event, &.#{$category} .label-category {
      background: $color;
    }
  }
}

//お役立ち情報のリスト
.mdl-article-usefulinfo {
  width: 100%;
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0;
  .article-inner{
  padding-bottom: 20px;
  }
  .thumb-area-wrap {
    width: 250px;
    vertical-align: top;
    @include table-cell;
    .thumb-area{
      background-image: url(assets/images/event/sample.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      box-sizing: border-box;
      height: 166.66px;
      position: relative;
      width: 250px;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap;
    }
    .panel-info {
      span {
        display: block;
        &:before {
          font-size: 2rem;
          margin-right: 0;
          vertical-align: sub;
        }
      }
    }
  }
  .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    @include table-cell;
    .title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative;
    }
    .panel-info {
      font-size: 1.6rem;
    }
  }
  a{
    color: $color-link;
    display: inline;
    height: 100%;
    text-decoration: none;
    width: 100%;
    &:after {
      display: none;
    }
  }
}

//お役立ち情報のリスト
.mdl-article-search {
  width: 100%;
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0;
  .article-inner{
  padding-bottom: 20px;
  }
  .thumb-area{
    // background-image: url(assets/images/event/sample.jpg);
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;
    // box-sizing: border-box;
    // height: 166.66px;
    position: relative;
    width: 250px;
    // text-indent: 100%;
    overflow: hidden;
    white-space: nowrap;
    @include table-cell;
    @include max-980{
      width: 100px;
    };
  }
  .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    @include table-cell;
    .title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative;
    }
    .panel-info {
      font-size: 1.6rem;
    }
    @include max-980{
      padding-left: 7px;
      .title{
        margin-bottom: 5px;
        font-size: 1.1rem;
      }
      .panel-info{
        font-size: 1rem;

      }
    };
  }
  a{
    text-decoration: none;
    &:after {
      border: none;
    }
  }
}




//.panel-info
.panel-info {
  span {
    display: block;
    &:before {
      font-size: 2rem;
      margin-right: 5px;
      vertical-align: sub;
    }
  }
}
//pager
.pager-wrap{
  position: relative;
}
.pager{
  text-align: center;
  letter-spacing: -.4em;
  >li{
    display: inline-block;
    margin:0 2.5px 0;
    width: 50px;
    height: 50px;
    letter-spacing: normal;
    vertical-align: middle;
    background: #fff;
    border-radius: 3px;
    border:1px solid #dadada;
    box-sizing: border-box;
    @media screen and (max-width: 980px) {
      width: 33px;
      height: 33px;
      font-size: 1.2rem;
    }
    span{
      display: block;
      height: 48px;
      line-height: 48px;
      color: #7d7d7d;
      font-size: 1.8rem;
      font-weight: bold;
      @media screen and (max-width: 980px) {
        height: 31px;
        line-height: 31px;
        font-size: 1.2rem;
      }
      &:before{
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
    &.is-active{
      background: #434343;
      span{
        color: #fff;
      }
    }
  }
  a{
    display: block;
    height: 48px;
    line-height: 48px;
    text-decoration: none;
    color: $color-def;
    font-size: 1.8rem;
    font-weight: bold;
  }
  .empty{
    visibility: hidden;
    opacity: 0;
  }
  .pager-prev{
    margin-right: 45px;
    @media screen and (max-width: 980px) {
      margin-right: 15px;
    }
  }
  .pager-next{
    margin-left: 45px;
    @media screen and (max-width: 980px) {
      margin-left: 15px;
    }
  }
}
.pager-all-num{
  position: absolute;
  font-size: 1.8rem;
  left: 0;
  top:25px;
}

@media screen and (max-width: 980px){
  .mdl-col-list {
    margin-bottom: 10px;
  }
  .mdl-article-event {
    width: 100%;
    .article-inner {
      padding: 8px;
    }
    .thumb-area {
      width: 100px;
      height: 100px;
    }
    .text-area {
      padding-left: 8px;
      .title {
        font-size: 1.1rem;
        margin-bottom: 0;
      }
      .panel-info {
        font-size: 1rem;
        span {
          height: 15px;
          line-height: 1.5rem;
        }
      }
      .tag {
        font-size: .9rem;
        li {
          margin: 4px 2px 0;
          padding: 3px 4px;
        }
      }
    }
  }
  .mdl-article-spot {
    width: calc(100% / 2 - 3px);
    height: 185px;
  }
  .mdl-article-spot {
    .thumb-area {
      width: 100%;
      height: 100px;
      .rl-event {
        width: 25px;
        height: 25px;
        top: 5px;
        left: 5px;
        &:before {
          font-size: 2.5rem;
        }
      }
    }
    .text-area {
      height: 85px;
      padding: 10px 6px 6px;
      .label-category {
        font-size: .8rem;
        width: 80px;
        height: 15px;
        line-height: 15px;
        letter-spacing: -.1rem;
        top: -8px;
      }
      .title {
        font-size: 1.1rem;
      }
      .panel-info {
        font-size: 1rem;
        bottom: 10px;
        span {
          height: 15px;
          line-height: 1.5rem;
        }
      }
    }
  }
  .mdl-article-usefulinfo {
    .article-inner{
      padding-bottom: 0px;
    }
    .thumb-area-wrap {
      width: calc(100% / 3);
      font-size: 1rem;
      .thumb-area{
        width: 100%;
        height: 21vw;
      }
    }
    .text-area {
      padding-left: 7px;
      .title {
        font-size: 1.1rem;
      }
      .panel-info {
        font-size: 1rem;
        bottom: 10px;
        span {
          display: inline;
          height: 15px;
          line-height: 1.5rem;
        }
      }
    }
  }
}


// //mdl-inlineblock-list
// .mdl-inlineblock-list{
//   >li{
//     display: inline-block;
//     margin-right: 20px;
//   }
// }
// //mdl-col-stacking
// .mdl-col-stacking{
//   .col-ttl{
//     background: $color-def;
//     color: #fff;
//     text-align: left;
//     padding:2px 10px;
//     box-sizing: border-box;
//     font-size: 1.6rem;
//     line-height:1.73;
//     font-weight: bold;
//     border-radius: 3px 3px 0 0;
//   }
//   .col-content{
//     box-sizing: border-box;
//     background: #fff;
//     border:solid 1px $color-def-border;
//     border-top: none;
//     border-radius: 0 0 3px 3px;
//     box-sizing: border-box;
//   }
//   .col-num-top{
//     margin-bottom: 5px;
//     text-align: center;
//     font-size: 1.4rem;
//     font-weight: bold;
//     line-height:1.6;
//   }
//   .col-num-content{
//     text-align: center;
//   }
//   .col-num{
//     font-size: 5rem;
//     line-height:1.6;
//     font-weight: bold;
//   }
//   .col-num-txt{
//     margin-left: 5px;
//     font-size: 2rem;
//     line-height:1.6;
//     font-weight: bold;
//   }
//   .col-link{
//     text-align: center;
//     a{
//       text-decoration: underline;
//     }
//   }
//   .col-ttl-capture{
//     font-size: 1rem;
//     margin-left: 5px;
//   }
// }
// .col-stacking-list{
//   >li{
//     border-bottom: 1px solid #ddd;
//     &:last-child{
//       border-bottom: none;
//     }
//     a{
//       display: table;
//       table-layout: fixed;
//       width: 100%;
//       height: 100%;
//       padding:10px 25px;
//       text-decoration: none;
//       box-sizing: border-box;
//       color: #888888;
//     }
//   }
// }
// .col-stacking-label{
//   display: table-cell;
//   vertical-align: middle;
//   width: 100px;
//   text-align: center;
//   >span{
//     display: block;
//     color:#fff;
//     font-size: 1.2rem;
//     font-weight: bold;
//     // padding:5px 3px;
//     box-sizing: border-box;
//   }
//   .green{
//     background: $color-turquoise;
//   }
//   .blue{
//     background: #0C5671;
//   }
//   .yellow{
//     background:#BF9514;
//   }
//   .red{
//     background:#A80F0F;
//   }
// }
// .col-stacking-img{
//   display: table-cell;
//   vertical-align: middle;
//   width: 75px;
//   text-align: center;
//   img{
//     width: 42px;
//     height: 42px;
//   }
// }
// .col-stacking-content{
//   display: table-cell;
//   vertical-align: middle;
// }
// .col-stacking-time{
//   width: 140px;
//   text-align: center;
//   display: table-cell;
//   vertical-align: middle;
// }
// .col-stacking-ttl{
//   font-size: 1.4rem;
//   font-weight: bold;
//   line-height:1.71;
// }
// .col-stacking-txt{
//   font-size: 1.4rem;
//   line-height:1.71;
// }
//
// //mdl-def-list
// .mdl-def-list{
//   >li{
//     display: table;
//     table-layout: fixed;
//     width: 100%;
//   }
// }
// .def-list-time{
//   display: table-cell;
//   vertical-align: top;
//   width: 110px;
//   padding-right: 15px;
//   box-sizing: border-box;
// }
// .def-list-content{
//   display: table-cell;
//   vertical-align: top;
//   width: 100%;
// }
// .col-content{
//   .mdl-def-list{
//     >li{
//       margin-top: 10px;
//       padding:0 25px;
//       line-height:1.71;
//       &:first-child{
//         margin-top: 0;
//       }
//     }
//   }
// }
// //mdl-arrow-list
// .mdl-arrow-list{
//   >li{
//     position: relative;
//     padding-left: 10px;
//     margin-top: 5px;
//     font-size: 1rem;
//     line-height:1.7;
//     &:first-child{
//       margin-top: 0;
//     }
//     &:before{
//       display: block;
//       position: absolute;
//       @include icon;
//       content: "\e90c";
//       left:0;
//       top:4px;
//     }
//   }
// }
// //mdl-box-list
// .mdl-box-list{
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items:center;
//   align-items:center;
//   -webkit-justify-content: space-between;
//   justify-content: space-between;
//   padding:20px;
//   background: #ddd;
//   box-sizing: border-box;
//   >li{
//   }
//   .mdl-col{
//     .col-ttl{
//       text-align: center;
//     }
//     .col-num{
//       font-size: 3.3rem;
//     }
//     .col-num-content{
//       color:#707070;
//     }
//     .col-content{
//       padding:0;
//     }
//   }
//   .mdl-border-box{
//     width: 325px;
//   }
// }
// //mdl-table-in-list
// .mdl-table-in-list{
//   >li{
//     @include flex-center;
//     flex-wrap:wrap;
//     margin-top: 10px;
//     &:first-child{
//       margin-top: 0
//     }
//   }
// }
//
// .mdl-def-input-list{
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: top;
//   align-items: top;
//   -webkit-flex-wrap: wrap;
//   flex-wrap: wrap;
//   &.col2{
//     >li{
//       width: 50%;
//       &:nth-child(n+3){
//         margin-top: 20px;
//       }
//     }
//   }
//   &.col5{
//     >li{
//       width: 20%;
//       &:nth-child(n+6){
//         margin-top: 20px;
//       }
//     }
//   }
//   &.full{
//     >li{
//       width: 100%;
//       &:nth-child(n+2){
//         margin-top: 20px;
//       }
//     }
//   }
//   >li{
//     width: 25%;
//     padding-right: 10px;
//     box-sizing: border-box;
//   }
// }
// //mdl-side-list
// .mdl-side-list{
//   >li{
//     margin-top: 15px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
// .side-list-ttl{
//   font-weight: bold;
//   font-size: 1.4rem;
// }
// .side-list-txt{
//   margin-top: 5px;
//   font-size: 1.2rem;
//   line-height:1.67;
// }
// //mdl-side-block-list
// .mdl-side-block-list{
//   >li{
//     padding:10px 0;
//     border-bottom: 1px solid $color-def-border;
//     box-sizing: border-box;
//     &:first-child{
//       padding-top: 0;
//     }
//     &:last-child{
//       border-bottom: 0;
//     }
//   }
// }
// //side-block-in-list
// .side-block-in-list{
//   >li{
//     display: table;
//     table-layout: fixed;
//     width: 100%;
//     margin-top: 10px;
//     box-sizing: border-box;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
//   .side-list-in-ttl{
//     width: 80px;
//     padding-right: 5px;
//     display: table-cell;
//     font-size: 1.2rem;
//     font-weight: bold;
//     box-sizing: border-box;
//   }
//   .side-list-in-txt{
//     display: table-cell;
//     font-size: 1.2rem;
//   }
// }
// //mdl-side-job-list
// .mdl-side-job-list{
//   >li{
//     .side-job-list-content{
//       &:last-child{
//         margin-bottom: 0;
//       }
//     }
//   }
//   .side-job-list-content{
//     margin-bottom: 12px;
//   }
// }
// .side-job-list-txt{
//   padding-left: 8px;
//   box-sizing: border-box;
// }
//
//
// //mdl-pager
// .pager-center{
//   margin-top: 30px;
//   text-align: center;
// }
// .mdl-pager{
//   >li{
//     display: inline-block;
//     height: 22px;
//     background:#fff;
//     box-sizing: border-box;
//     vertical-align: top;
//     a{
//       display: block;
//       width: 100%;
//       height: 100%;
//       font-size:1.2rem;
//       padding:0 5px;
//       line-height: 20px;
//       color: $color-def;
//       text-decoration: none;
//       box-sizing: border-box;
//       border:1px solid $color-def-border;
//       border-radius: 2px;
//     }
//   }
//   .pager-leader{
//     padding:5px;
//     border:none;
//     background: none;
//   }
// }
// .pager-pages{
//   &.is-active{
//     background: $color-def;
//     color: #fff;
//     padding:5px;
//     font-size:1.2rem;
//     padding:0 5px;
//     line-height: 20px;
//     border:1px solid $color-def;
//     border-radius: 2px;
//   }
// }
// .pager-prev{
//   position: relative;
//   width: 22px;
// }
// .pager-next{
//   position: relative;
//   width: 22px;
// }
// .search-place-list{
//   >li{
//     width: 100%;
//     display: table;
//     table-layout: fixed;
//     margin-top: 10px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
// .search-place-prefecture{
//   display: table-cell;
//   width: 70px;
// }
// .search-place-city{
//   display: table-cell;
// }
// //search-list
// .search-list{
//   >li{
//     margin-top: 25px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
// //mdl-conditions-list
// .mdl-conditions-list{
//   >li{
//     border-top:1px solid $color-def;
//   }
// }
// .conditions-list-top{
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   width: 100%;
//   height: 60px;
//   padding:0 10px;
//   background:#ddd-gray;
//   box-sizing: border-box;
// }
// .conditions-list-ttl{
//   font-size: 1.4rem;
//   color: $color-link;
//   font-weight: bold;
// }
// .conditions-list-time-ttl{
//   margin-right: 10px;
//   font-size: 1.2rem;
//   color: #9F9F9F;
// }
// .conditions-list-content{
//   padding:10px 10px 30px;
//   box-sizing: border-box;
//   @include clearfix;
// }
// .conditions-list-content-left{
//   width: calc(100% - 180px);
//   padding-right: 10px;
//   box-sizing: border-box;
//   float: left;
// }
// .conditions-list-content-right{
//   width: 180px;
//   float: right;
// }
// .conditions-list-change{
//   margin-top: 10px;
// }
// .conditions-list-cancel{
//   margin-top: 10px;
// }
// .conditions-list-table-list{
//   >li{
//     margin-top: 10px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
//
// /* ==============================
// module / tag
// ================================= */
// .search-tag{
//   width: 440px;
//   >li{
//     display: inline-block;
//     padding:4px 4px;
//     margin-bottom: 10px;
//     font-size: 1rem;
//     line-height: 1;
//     font-weight: bold;
//     color:#5F5F5F;
//     border:2px solid #272623;
//     box-sizing: border-box;
//     border-radius: 3px;
//     margin-left: 3px;
//   }
// }
//
//
// /* ==============================
// module / 定義リスト
// ================================= */
// .mdl-define{
//   .define-ttl{
//     line-height:1.67;
//     margin-top: 20px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
// .define-ttl{
//   font-size: 1.8rem;
//   color: $color-turquoise;
//   line-height:1.72;
//   font-weight: bold;
// }
// .define-sub-ttl{
//   font-weight: bold;
//   font-size: 1.4rem;
//   line-height:1.71;
// }
// .define-txt{
//   margin-top: 5px;
//   font-size: 1.4rem;
//   line-height:1.71;
// }
// .define-content{
//   .define-col{
//     margin-top: 10px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
//
// /* ==============================
// module / パンくず
// ================================= */
// .mdl-breadcrumb{
//   >li{
//     display: inline-block;
//     position: relative;
//     line-height: 1;
//     .icon-arrow_left{
//       &:before{
//         color: $color-def;
//         display: inline-block;
//         margin-right: 5px;
//         font-size: 1.2rem;
//       }
//     }
//     a{
//       text-decoration: underline;
//       font-size: 1.2rem;
//     }
//   }
// }
// /* ==============================
// module / タブナビゲーション
// ================================= */
// .mdl-tab-nav{
//   @include  clearfix;
//   border-bottom: 4px solid $color-turquoise;
//   >li{
//     float: left;
//     width: 200px;
//     height: 40px;
//     margin-left: 10px;
//     &:first-child{
//       margin-left: 0;
//     }
//   }
// }
// .tab-nav-btn{
//   width: 100%;
//   height: 100%;
//   line-height: 39px;
//   font-size: 1.4rem;
//   font-weight: bold;
//   color: $colro-form;
//   border:1px solid $colro-form;
//   border-bottom: none;
//   display: block;
//   text-align: center;
//   text-decoration: none;
//   box-sizing: border-box;
//   -webkit-transition: 0s;
//   transition: 0s;
//   cursor: pointer;
//   &:hover{
//     opacity: 1;
//   }
// }
