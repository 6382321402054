/* ==============================
base/base
================================= */
html{
  color: #333;
}
body{
  //フォント指定
  @include min;
  //デフォルトフォントサイズ14px
  font-size: 1.4rem;
  position: static;
  // font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
  width: 100%;
  min-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  word-break: break-all;
  -webkit-font-smoothing: antialiased;
  @media all and (-ms-high-contrast:none){
    font-family:  "メイリオ", sans-serif;
  }
}
div,p{
  line-height:1.71;
}
ul,ol,li{
  margin: 0;
  padding:0;
  list-style-type: none;
}
a{
  text-decoration: none;
  color: $color-link;
  cursor: pointer;
  text-decoration: underline;
  -webkit-transition: .3s;
  transition: .3s;
  &:hover{
    opacity: .7;
  }
}
main{
  display: block;
}
img{
  vertical-align: bottom;
  // max-width: 100%;
  width: 100%;
}
i{
  font-style: normal;
}
button{
  border: none;
  border-color:none;
  cursor: pointer;
  &:focus{
    outline: 0;
  }
}
table{
  border-collapse: collapse;
  word-break: break-all;
}
/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  // border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}
:placeholder-shown {
  color: #9B9B9B;
}
::-webkit-input-placeholder {
  color: #9B9B9B;
}
:-moz-placeholder {
  color: #CCC;
  color: #9B9B9B;
}
::-moz-placeholder {
  color: #CCC;
  color: #9B9B9B;
}
:-ms-input-placeholder {
  color: #9B9B9B;
}
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"],
textarea
{
  box-sizing: border-box;
}

input[type="date"] {
-webkit-appearance: none;
border-radius: 0;
}

//select初期化
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box;
}

//レスポンシブ
.pc {
  display: inherit !important;
}
.pc-block {
  display: block !important;
}
.sp {
  display: none !important;
}

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px){
  body {
    min-width: inherit;
  }
  .pc {
    display: none !important;
  }
  .sp {
    display: inherit !important;
  }
}
