/* ==============================
module / search
================================= */
.search{
  .l-search{
    width: 100%;
    background-color: #ffffff;
    height: 45px;
    display: block;
    box-sizing: border-box;
  }
  .archive-content{
    @include max-980{
      padding:0;
    }
  }
  .page-ttl-txt{
    color: $color-def;
    text-shadow: none;
  }
  .page-ttl-bottom{
    color: $color-def;
    text-shadow: none;
  }
  .search-button{
    width: 11rem;
    border: none;
    position: absolute;
    right: 0;
    height: 100%;
    .button{
      background-color: #767676;
      height: 100%;
      width: 100%;
      font-size: 1.8rem;
      color: #fff;
      font-weight: bolder;
      border-radius: 0 5px 5px 0;
    }
  }
}
.event-search-keyword {
  .l-search {
    width: 100%;
    background-color: #ffffff;
    height: 45px;
    display: block;
    box-sizing: border-box;
  }
  .search-button{
    width: 11rem;
    border: none;
    position: absolute;
    right: 0;
    height: 100%;
    .button{
      background-color: #767676;
      height: 100%;
      width: 100%;
      font-size: 1.8rem;
      color: #fff;
      font-weight: bolder;
      border-radius: 0 5px 5px 0;
    }
  }
}
.search-keyword{
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%;
  .search-input{
    width: 100%;
    height: 100%;
    border: none;
    font-size: 1.8rem;
  }
  @include max-980{
    &:before{
      @include icon;
      content: "\e91d";
      color: $color-input;
      position: absolute;
      top:2px;
      right: 5px;
      font-size: 3.5rem;
    }
    .search-input{
      padding-right: 40px;
      font-size: 1.6rem;
    }
  }
}

.form{
  width: 703px;
  margin: 0 auto;
  @include max-980{
    width: 100%;
    padding:0 7px;
    box-sizing: border-box;
  }
}
.event {
  .form {
    @include max-980 {
      padding: 0;
    }
  }
}


.search-wrap{
  background-color: $color-softblue;
  padding: 5px;
}

.event {
  .search-wrap {
    background: #dcdcdc;
  }
}

.mdl-serch-input-list{
  display: flex;
  padding: 20px 0;
  >li{
    margin-right: 30px;
    margin-bottom: 10px;
  }
  @include max-980{
    display: block;
    padding: 10px 0;
    >li{
      display: inline-block;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
}
//search-more
.search-more{
  margin-top: 40px;
}

//search-list
.search-list{
  >li{
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
.search-archive-list-wrap{
  position: relative;
}

/* ==============================
module / #search-spot #search-event
================================= */
#search-event{
  display: none;
  @include max-980{
    padding-bottom: 10px;
  }

}
#search-spot{
  display: none;
  .panel-block{
    span{
      font-size: 1.6rem;
      font-weight: normal;
    }
  }
  @include max-980{
    padding-bottom: 10px;
  };
}
