/* ==============================
module / font
================================= */

//page デフォルト大見出し
.page-visual{
  background-size:cover;
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.page-visual-inner{
  position: relative;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
  @media screen and (max-width: 980px) {
    height: 115px;
  }
}
.page-ttl{
  position: absolute;
  width: 100%;
  top:50%;
  transform: translateY(-50%);
}
.page-ttl-txt{
  display: block;
  font-size: 5.4rem;
  line-height:1;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing:0.1em;
  text-shadow: 0 0 8px rgba(0,0,0,.2);
  @media screen and (max-width: 980px) {
    font-size: 2.4rem;
  }
}
.page-ttl-bottom{
  display: block;
  margin-top: 15px;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing:0.01em;
  text-shadow: 0 0 8px rgba(0,0,0,.2);
  @media screen and (max-width: 980px) {
    font-size: 1.1rem;
  }
}

//page コース詳細の見出し
.course-detail {
  .page-visual-inner{
    position: relative;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    @media screen and (max-width: 980px) {
      height: 115px;
    }
  }
  .page-ttl-top {
    display: block;
    color: #fff;
    font-size: 2.4rem;
    text-align: center;
    letter-spacing:0.01em;
    margin-bottom: 30px;
    text-shadow: 0 0 8px rgba(0,0,0,.2);
    @media screen and (max-width: 980px) {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
  }
  .page-ttl-bottom {
    font-size: 2.6rem;
    letter-spacing: .1em;
    @media screen and (max-width: 980px) {
      font-size: 1.3rem;
      margin-top: 5px;
    }
  }
}
