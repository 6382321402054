/* ==============================
動的
================================= */
//common
#page-wrap{
  &.is-active{
    opacity: 1;
    visibility: visible;
  }
}
#modal-wrap{
  &.is-active{
    opacity: 1;
    visibility: visible;
  }
}
body.is-fixed{
  position: fixed;
  width: 100%;
  height: 100%;
}
//header nav
nav{
  &.is-active{
    opacity: 1;
    visibility: visible;
    .nav-slide-cover{
      right: 0;
    }
  }
}
.is-active{
  .nav-btn-menu{
    >span{
      &:nth-child(1){
        top:7px;
        transform: rotate(45deg);
      }
      &:nth-child(2){
        right:-200%;
      }
      &:nth-child(3){
        bottom:6px;
        transform: rotate(-45deg);
      }
    }
  }
}
.search-nav{
  &.is-active{
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }
}
.search-btn{
  &.is-active{
    .icon-search{
      opacity: 0;
      visibility: hidden;
    }
    .search-btn-close{
      opacity: 1;
      visibility: visible;
    }
  }
  &.is-deactivate{
    color: #989898;
  }
}

.nav-btn{
  &.is-deactivate{
    .nav-btn-menu{
      >span{
        background: #989898;
      }
    }
    .search-btn-txt{
      color: #989898;
    }
  }
}

//table-more
//search-more-link

.search-more-link{
  .icon-arrow_bottom{
    transform: rotate(0deg);
    transition: .3s;
  }
  &.is-open{
    .icon-arrow_bottom{
      transform: rotate(180deg);
    }

  }
}


/* ==============================
module / タブナビゲーション
================================= */
//mdl-tab-content
.mdl-tab-nav{
  >li{
    &.is-active{
      .tab-nav-btn{
        color: #fff;
        background: $color-turquoise;
        border:1px solid $color-turquoise;
        border-bottom: none;
      }
    }
  }
}

.mdl-tab-content{
  >li{
    display: none;
    &.is-active{
      display: block;
    }
  }
}
/* ==============================
module / detail sns追従
================================= */
.detail-sns{
  &.is-active{
  }
}

/* ==============================
module / areamap
================================= */
.mdl-map-list{
  .is-active{
    span{
      color: $color-link;
    }
  }
}
.map-list-wrap{
  &.is-hide{
    left: -20%;
  }
}
.map-slide{
  &.is-selected{
    left: 0;
  }
}
/* ==============================
module / search
================================= */
#search-event{
  &.is-active{

  }
}
#search-spot{
  &.is-active{

  }
}
