@charset "UTF-8";
/* =========================================================
 Foundation
========================================================= */
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
/*-----------------------------------------------------------------
	Customize: tcm 4f
	Last Updated : 2018.06.28
-----------------------------------------------------------------*/
/*-----------------------------------------------------------------
		基本設定
-----------------------------------------------------------------*/
/*要素のフォントサイズやマージン・パディングをリセット*/
@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0; }

body, div, pre, p, blockquote, form, fieldset, input, textarea, select, option, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, table, th, td, tr, embed, object, a, img {
  word-wrap: break-word; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.4rem;
  line-height: 1;
  font-family: "メイリオ", Meiryo,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Verdana, "游ゴシック", YuGothic,  sans-serif;
  -webkit-text-size-adjust: 100%; }

/*新規追加要素のデフォルトはすべてインライン要素になっているので、section要素などをブロック要素へ変更*/
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none; }

/*blockquote要素、q要素の前後にコンテンツを追加しないように指定*/
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

/*縦方向の揃え位置を中央揃えに指定しています*/
input, select {
  vertical-align: middle; }

/*画像を縦に並べた時に余白が出ないように*/
img {
  border: 0;
  font-size: 0;
  line-height: 0; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

/* クリア */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
  font-size: 1px; }

.clearfix {
  display: inline-table; }

/* Hides from IE-mac \*/
* html .clearfix {
  height: 1%; }

.clearfix {
  display: block; }

/* End hide from IE-mac */
*:first-child + html .clearfix {
  /* IE7用 */
  width: 100%; }

/*-----------------------------------------------------------------
		調整用
-----------------------------------------------------------------*/
/* 整列系 */
.ta-center {
  text-align: center !important; }

.ta-right {
  text-align: right; }

.ta-left {
  text-align: left; }

/* フォント関連 */
.font-normal {
  font-weight: normal; }

.fs100 {
  font-size: 100% !important; }

.fs100 li {
  font-size: 100% !important; }

/* マージン調整 */
.mt0 {
  margin-top: 0px  !important; }

.mt5 {
  margin-top: 5px  !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt25 {
  margin-top: 25px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt35 {
  margin-top: 35px !important; }

.mt40 {
  margin-top: 40px !important; }

.mt45 {
  margin-top: 45px !important; }

.mt50 {
  margin-top: 50px !important; }

.mb0 {
  margin-bottom: 0px  !important; }

.mb5 {
  margin-bottom: 5px  !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb25 {
  margin-bottom: 25px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb35 {
  margin-bottom: 35px !important; }

.mb40 {
  margin-bottom: 40px !important; }

.mb45 {
  margin-bottom: 45px !important; }

.mb50 {
  margin-bottom: 50px !important; }

.mb60 {
  margin-bottom: 60px !important; }

.mb80 {
  margin-bottom: 80px !important; }

.mb100 {
  margin-bottom: 100px !important; }

.mb110 {
  margin-bottom: 110px !important; }

.mb120 {
  margin-bottom: 120px !important; }

.ml0 {
  margin-left: 0px  !important; }

.ml5 {
  margin-left: 5px  !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml20 {
  margin-left: 20px !important; }

.ml25 {
  margin-left: 25px !important; }

.ml30 {
  margin-left: 30px !important; }

.ml35 {
  margin-left: 35px !important; }

.ml40 {
  margin-left: 40px !important; }

.ml45 {
  margin-left: 45px !important; }

.ml50 {
  margin-left: 50px !important; }

.mr0 {
  margin-right: 0px  !important; }

.mr5 {
  margin-right: 5px  !important; }

.mr10 {
  margin-right: 10px !important; }

.mr15 {
  margin-right: 15px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr25 {
  margin-right: 25px !important; }

.mr30 {
  margin-right: 30px !important; }

.mr35 {
  margin-right: 35px !important; }

.mr40 {
  margin-right: 40px !important; }

.mr45 {
  margin-right: 45px !important; }

.mr50 {
  margin-right: 50px !important; }

.pt0 {
  padding-top: 0px  !important; }

.pt5 {
  padding-top: 5px  !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt25 {
  padding-top: 25px !important; }

.pt30 {
  padding-top: 30px !important; }

.pt35 {
  padding-top: 35px !important; }

.pt40 {
  padding-top: 40px !important; }

.pt45 {
  padding-top: 45px !important; }

.pt50 {
  padding-top: 50px !important; }

.pb0 {
  padding-bottom: 0px  !important; }

.pb5 {
  padding-bottom: 5px  !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb25 {
  padding-bottom: 25px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.pb35 {
  padding-bottom: 35px !important; }

.pb40 {
  padding-bottom: 40px !important; }

.pb45 {
  padding-bottom: 45px !important; }

.pb50 {
  padding-bottom: 50px !important; }

.pb60 {
  padding-bottom: 60px !important; }

.pb80 {
  padding-bottom: 80px !important; }

.pb100 {
  padding-bottom: 100px !important; }

.pb110 {
  padding-bottom: 110px !important; }

.pb120 {
  padding-bottom: 120px !important; }

.pl0 {
  padding-left: 0px  !important; }

.pl5 {
  padding-left: 5px  !important; }

.pl10 {
  padding-left: 10px !important; }

.pl15 {
  padding-left: 15px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl25 {
  padding-left: 25px !important; }

.pl30 {
  padding-left: 30px !important; }

.pl35 {
  padding-left: 35px !important; }

.pl40 {
  padding-left: 40px !important; }

.pl45 {
  padding-left: 45px !important; }

.pl50 {
  padding-left: 50px !important; }

.pr0 {
  padding-right: 0px  !important; }

.pr5 {
  padding-right: 5px  !important; }

.pr10 {
  padding-right: 10px !important; }

.pr15 {
  padding-right: 15px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr25 {
  padding-right: 25px !important; }

.pr30 {
  padding-right: 30px !important; }

.pr35 {
  padding-right: 35px !important; }

.pr40 {
  padding-right: 40px !important; }

.pr45 {
  padding-right: 45px !important; }

.pr50 {
  padding-right: 50px !important; }

/* =========================================================
 Base
========================================================= */
/* ==============================
base/base
================================= */
html {
  color: #333; }

body {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 1.4rem;
  position: static;
  width: 100%;
  min-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  word-break: break-all;
  -webkit-font-smoothing: antialiased; }
  @media all and (-ms-high-contrast: none) {
    body {
      font-family: "メイリオ", sans-serif; } }

div, p {
  line-height: 1.71; }

ul, ol, li {
  margin: 0;
  padding: 0;
  list-style-type: none; }

a {
  text-decoration: none;
  color: #2d8ad7;
  cursor: pointer;
  text-decoration: underline;
  -webkit-transition: .3s;
  transition: .3s; }
  a:hover {
    opacity: .7; }

main {
  display: block; }

img {
  vertical-align: bottom;
  width: 100%; }

i {
  font-style: normal; }

button {
  border: none;
  border-color: none;
  cursor: pointer; }
  button:focus {
    outline: 0; }

table {
  border-collapse: collapse;
  word-break: break-all; }

/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer; }

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none; }

input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px; }

:placeholder-shown {
  color: #9B9B9B; }

::-webkit-input-placeholder {
  color: #9B9B9B; }

:-moz-placeholder {
  color: #CCC;
  color: #9B9B9B; }

::-moz-placeholder {
  color: #CCC;
  color: #9B9B9B; }

:-ms-input-placeholder {
  color: #9B9B9B; }

input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"],
textarea {
  box-sizing: border-box; }

input[type="date"] {
  -webkit-appearance: none;
  border-radius: 0; }

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background: none transparent;
  vertical-align: middle;
  font-size: inherit;
  color: inherit;
  box-sizing: content-box; }

.pc {
  display: inherit !important; }

.pc-block {
  display: block !important; }

.sp {
  display: none !important; }

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px) {
  body {
    min-width: inherit; }
  .pc {
    display: none !important; }
  .sp {
    display: inherit !important; } }

/* =========================================================
 Layout
========================================================= */
/* ==============================
layout/layout
================================= */
.inner {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  padding: 60px 0; }

.page-wrap {
  width: 1180px;
  padding-top: 20px;
  padding-bottom: 60px;
  margin: 0 auto;
  box-sizing: border-box; }

.l-main {
  width: 100%;
  min-width: 1280px; }

.l-side-container {
  display: table;
  width: 100%;
  table-layout: fixed; }

.l-side {
  width: 270px;
  display: table-cell;
  box-sizing: border-box;
  vertical-align: top; }

.l-content {
  padding-left: 30px;
  display: table-cell;
  box-sizing: border-box;
  vertical-align: top; }

@media screen and (max-width: 980px) {
  .inner {
    max-width: inherit;
    padding: 25px 0 30px; }
  .l-main {
    min-width: 100%;
    margin-top: 50px; } }

/* =========================================================
 Module
========================================================= */
/* ==============================
module / icon
================================= */
@font-face {
  font-family: 'icomoon';
  src: url("/assets/fonts/icomoon.eot?r6igjb");
  src: url("/assets/fonts/icomoon.eot?r6igjb#iefix") format("embedded-opentype"), url("/assets/fonts/icomoon.ttf?r6igjb") format("truetype"), url("/assets/fonts/icomoon.woff?r6igjb") format("woff"), url("/assets/fonts/icomoon.svg?r6igjb#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-car:before {
  content: "\e927"; }

.icon-ship:before {
  content: "\e928"; }

.icon-bus:before {
  content: "\e929"; }

.icon-amusement:before {
  content: "\e900"; }

.icon-area:before {
  content: "\e901"; }

.icon-arrow_bottom_thin:before {
  content: "\e902"; }

.icon-arrow_bottom:before {
  content: "\e903"; }

.icon-arrow_left_thin:before {
  content: "\e904"; }

.icon-arrow_left:before {
  content: "\e905"; }

.icon-arrow_right_thin:before {
  content: "\e906"; }

.icon-arrow_right:before {
  content: "\e907"; }

.icon-arrow_top_thin:before {
  content: "\e908"; }

.icon-arrow_top:before {
  content: "\e909"; }

.icon-calender1:before {
  content: "\e90a"; }

.icon-calender2:before {
  content: "\e90b"; }

.icon-calender3:before {
  content: "\e90c"; }

.icon-coupon:before {
  content: "\e90d"; }

.icon-course:before {
  content: "\e90e"; }

.icon-event:before {
  content: "\e90f"; }

.icon-facebook1:before {
  content: "\e910"; }

.icon-facebook2:before {
  content: "\e911"; }

.icon-feature:before {
  content: "\e912"; }

.icon-food:before {
  content: "\e913"; }

.icon-home:before {
  content: "\e914"; }

.icon-language:before {
  content: "\e915"; }

.icon-leaning:before {
  content: "\e916"; }

.icon-line:before {
  content: "\e917"; }

.icon-mobile:before {
  content: "\e918"; }

.icon-others:before {
  content: "\e919"; }

.icon-pin:before {
  content: "\e91a"; }

.icon-prev:before {
  content: "\e91b"; }

.icon-relax:before {
  content: "\e91c"; }

.icon-search:before {
  content: "\e91d"; }

.icon-setting:before {
  content: "\e91e"; }

.icon-shopping:before {
  content: "\e91f"; }

.icon-speaker:before {
  content: "\e920"; }

.icon-station:before {
  content: "\e921"; }

.icon-tourism:before {
  content: "\e922"; }

.icon-twitter:before {
  content: "\e923"; }

.icon-useful:before {
  content: "\e924"; }

.icon-window:before {
  content: "\e925"; }

.icon-youtube:before {
  content: "\e926"; }

/* ==============================
module / font
================================= */
.page-visual {
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: border-box; }

.page-visual-inner {
  position: relative;
  width: 100%;
  height: 200px;
  box-sizing: border-box; }
  @media screen and (max-width: 980px) {
    .page-visual-inner {
      height: 115px; } }

.page-ttl {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%); }

.page-ttl-txt {
  display: block;
  font-size: 5.4rem;
  line-height: 1;
  font-weight: bold;
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 980px) {
    .page-ttl-txt {
      font-size: 2.4rem; } }

.page-ttl-bottom {
  display: block;
  margin-top: 15px;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
  letter-spacing: 0.01em;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 980px) {
    .page-ttl-bottom {
      font-size: 1.1rem; } }

.course-detail .page-visual-inner {
  position: relative;
  width: 100%;
  height: 300px;
  box-sizing: border-box; }
  @media screen and (max-width: 980px) {
    .course-detail .page-visual-inner {
      height: 115px; } }

.course-detail .page-ttl-top {
  display: block;
  color: #fff;
  font-size: 2.4rem;
  text-align: center;
  letter-spacing: 0.01em;
  margin-bottom: 30px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 980px) {
    .course-detail .page-ttl-top {
      font-size: 1.1rem;
      margin-bottom: 0; } }

.course-detail .page-ttl-bottom {
  font-size: 2.6rem;
  letter-spacing: .1em; }
  @media screen and (max-width: 980px) {
    .course-detail .page-ttl-bottom {
      font-size: 1.3rem;
      margin-top: 5px; } }

/* ==============================
module / list
================================= */
.mdl-col-list {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2px 1px rgba(183, 183, 183, 0.34);
  margin-bottom: 36px;
  position: relative; }
  .mdl-col-list a {
    color: inherit;
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%; }
    .mdl-col-list a:after {
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      border-radius: 5px;
      border: 1px solid #DDD;
      box-sizing: border-box;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%; }

.mdl-article-event {
  width: 575px; }
  .mdl-article-event .article-inner {
    display: table;
    width: 100%;
    table-layout: fixed;
    box-sizing: border-box;
    padding: 15px; }
  .mdl-article-event .thumb-area {
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    background-image: url(assets/images/event/sample.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 1px solid #DDD;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    height: 190px;
    width: 190px;
    vertical-align: top; }
  .mdl-article-event .text-area {
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    padding-left: 10px;
    position: relative; }
    .mdl-article-event .text-area .title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px; }
    .mdl-article-event .text-area .tag {
      bottom: 0;
      position: absolute; }
      .mdl-article-event .text-area .tag li {
        background: #eaeeef;
        display: inline-block;
        line-height: 1;
        margin: 3px;
        padding: 5px; }
        .mdl-article-event .text-area .tag li:before {
          content: "#"; }

.mdl-article-spot {
  height: 370px;
  width: 360px; }
  .mdl-article-spot .thumb-area {
    background-image: url(assets/images/event/sample.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-sizing: border-box;
    height: 240px;
    position: relative;
    width: 360px;
    vertical-align: top; }
    .mdl-article-spot .thumb-area .rl-event {
      background: #ddd;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      left: 10px;
      height: 50px;
      position: absolute;
      top: 10px;
      width: 50px; }
      .mdl-article-spot .thumb-area .rl-event:before {
        font-size: 5rem;
        color: #fff; }
  .mdl-article-spot .text-area {
    box-sizing: border-box;
    height: 130px;
    padding: 20px;
    position: relative; }
    .mdl-article-spot .text-area .label-category {
      background: #333;
      color: #fff;
      display: block;
      font-weight: bold;
      height: 30px;
      left: 0;
      line-height: 30px;
      position: absolute;
      text-align: center;
      top: -15px;
      width: 110px; }
    .mdl-article-spot .text-area .title {
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative; }
    .mdl-article-spot .text-area .panel-info {
      bottom: 20px;
      position: absolute; }
  .mdl-article-spot.event .rl-event, .mdl-article-spot.event .label-category {
    background: #81ac1c; }
  .mdl-article-spot.feature .rl-event, .mdl-article-spot.feature .label-category {
    background: #00b4de; }
  .mdl-article-spot.amusement .rl-event, .mdl-article-spot.amusement .label-category {
    background: #fa9e2c; }
  .mdl-article-spot.tourism .rl-event, .mdl-article-spot.tourism .label-category {
    background: #f73c35; }
  .mdl-article-spot.leaning .rl-event, .mdl-article-spot.leaning .label-category {
    background: #2d8ad7; }
  .mdl-article-spot.food .rl-event, .mdl-article-spot.food .label-category {
    background: #e71865; }
  .mdl-article-spot.shopping .rl-event, .mdl-article-spot.shopping .label-category {
    background: #00b063; }
  .mdl-article-spot.relax .rl-event, .mdl-article-spot.relax .label-category {
    background: #a36b98; }
  .mdl-article-spot.others .rl-event, .mdl-article-spot.others .label-category {
    background: #767676; }

.mdl-article-usefulinfo {
  width: 100%;
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0; }
  .mdl-article-usefulinfo .article-inner {
    padding-bottom: 20px; }
  .mdl-article-usefulinfo .thumb-area-wrap {
    width: 250px;
    vertical-align: top;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    .mdl-article-usefulinfo .thumb-area-wrap .thumb-area {
      background-image: url(assets/images/event/sample.jpg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      box-sizing: border-box;
      height: 166.66px;
      position: relative;
      width: 250px;
      text-indent: 100%;
      overflow: hidden;
      white-space: nowrap; }
    .mdl-article-usefulinfo .thumb-area-wrap .panel-info span {
      display: block; }
      .mdl-article-usefulinfo .thumb-area-wrap .panel-info span:before {
        font-size: 2rem;
        margin-right: 0;
        vertical-align: sub; }
  .mdl-article-usefulinfo .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    .mdl-article-usefulinfo .text-area .title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative; }
    .mdl-article-usefulinfo .text-area .panel-info {
      font-size: 1.6rem; }
  .mdl-article-usefulinfo a {
    color: #2d8ad7;
    display: inline;
    height: 100%;
    text-decoration: none;
    width: 100%; }
    .mdl-article-usefulinfo a:after {
      display: none; }

.mdl-article-search {
  width: 100%;
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0; }
  .mdl-article-search .article-inner {
    padding-bottom: 20px; }
  .mdl-article-search .thumb-area {
    position: relative;
    width: 250px;
    overflow: hidden;
    white-space: nowrap;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    @media screen and (max-width: 980px) {
      .mdl-article-search .thumb-area {
        width: 100px; } }
  .mdl-article-search .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    .mdl-article-search .text-area .title {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative; }
    .mdl-article-search .text-area .panel-info {
      font-size: 1.6rem; }
    @media screen and (max-width: 980px) {
      .mdl-article-search .text-area {
        padding-left: 7px; }
        .mdl-article-search .text-area .title {
          margin-bottom: 5px;
          font-size: 1.1rem; }
        .mdl-article-search .text-area .panel-info {
          font-size: 1rem; } }
  .mdl-article-search a {
    text-decoration: none; }
    .mdl-article-search a:after {
      border: none; }

.panel-info span {
  display: block; }
  .panel-info span:before {
    font-size: 2rem;
    margin-right: 5px;
    vertical-align: sub; }

.pager-wrap {
  position: relative; }

.pager {
  text-align: center;
  letter-spacing: -.4em; }
  .pager > li {
    display: inline-block;
    margin: 0 2.5px 0;
    width: 50px;
    height: 50px;
    letter-spacing: normal;
    vertical-align: middle;
    background: #fff;
    border-radius: 3px;
    border: 1px solid #dadada;
    box-sizing: border-box; }
    @media screen and (max-width: 980px) {
      .pager > li {
        width: 33px;
        height: 33px;
        font-size: 1.2rem; } }
    .pager > li span {
      display: block;
      height: 48px;
      line-height: 48px;
      color: #7d7d7d;
      font-size: 1.8rem;
      font-weight: bold; }
      @media screen and (max-width: 980px) {
        .pager > li span {
          height: 31px;
          line-height: 31px;
          font-size: 1.2rem; } }
      .pager > li span:before {
        font-size: 1.8rem;
        font-weight: bold; }
    .pager > li.is-active {
      background: #434343; }
      .pager > li.is-active span {
        color: #fff; }
  .pager a {
    display: block;
    height: 48px;
    line-height: 48px;
    text-decoration: none;
    color: #3e3e3e;
    font-size: 1.8rem;
    font-weight: bold; }
  .pager .empty {
    visibility: hidden;
    opacity: 0; }
  .pager .pager-prev {
    margin-right: 45px; }
    @media screen and (max-width: 980px) {
      .pager .pager-prev {
        margin-right: 15px; } }
  .pager .pager-next {
    margin-left: 45px; }
    @media screen and (max-width: 980px) {
      .pager .pager-next {
        margin-left: 15px; } }

.pager-all-num {
  position: absolute;
  font-size: 1.8rem;
  left: 0;
  top: 25px; }

@media screen and (max-width: 980px) {
  .mdl-col-list {
    margin-bottom: 10px; }
  .mdl-article-event {
    width: 100%; }
    .mdl-article-event .article-inner {
      padding: 8px; }
    .mdl-article-event .thumb-area {
      width: 100px;
      height: 100px; }
    .mdl-article-event .text-area {
      padding-left: 8px; }
      .mdl-article-event .text-area .title {
        font-size: 1.1rem;
        margin-bottom: 0; }
      .mdl-article-event .text-area .panel-info {
        font-size: 1rem; }
        .mdl-article-event .text-area .panel-info span {
          height: 15px;
          line-height: 1.5rem; }
      .mdl-article-event .text-area .tag {
        font-size: .9rem; }
        .mdl-article-event .text-area .tag li {
          margin: 4px 2px 0;
          padding: 3px 4px; }
  .mdl-article-spot {
    width: calc(100% / 2 - 3px);
    height: 185px; }
  .mdl-article-spot .thumb-area {
    width: 100%;
    height: 100px; }
    .mdl-article-spot .thumb-area .rl-event {
      width: 25px;
      height: 25px;
      top: 5px;
      left: 5px; }
      .mdl-article-spot .thumb-area .rl-event:before {
        font-size: 2.5rem; }
  .mdl-article-spot .text-area {
    height: 85px;
    padding: 10px 6px 6px; }
    .mdl-article-spot .text-area .label-category {
      font-size: .8rem;
      width: 80px;
      height: 15px;
      line-height: 15px;
      letter-spacing: -.1rem;
      top: -8px; }
    .mdl-article-spot .text-area .title {
      font-size: 1.1rem; }
    .mdl-article-spot .text-area .panel-info {
      font-size: 1rem;
      bottom: 10px; }
      .mdl-article-spot .text-area .panel-info span {
        height: 15px;
        line-height: 1.5rem; }
  .mdl-article-usefulinfo .article-inner {
    padding-bottom: 0px; }
  .mdl-article-usefulinfo .thumb-area-wrap {
    width: calc(100% / 3);
    font-size: 1rem; }
    .mdl-article-usefulinfo .thumb-area-wrap .thumb-area {
      width: 100%;
      height: 21vw; }
  .mdl-article-usefulinfo .text-area {
    padding-left: 7px; }
    .mdl-article-usefulinfo .text-area .title {
      font-size: 1.1rem; }
    .mdl-article-usefulinfo .text-area .panel-info {
      font-size: 1rem;
      bottom: 10px; }
      .mdl-article-usefulinfo .text-area .panel-info span {
        display: inline;
        height: 15px;
        line-height: 1.5rem; } }

/* ==============================
module / btn
================================= */
.btn-wrap {
  text-align: center;
  line-height: 1; }

.btn {
  background: #00bbfb;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  width: 100%;
  max-width: 615px;
  padding: 0 10px;
  font-size: 2.4rem;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  overflow: hidden;
  box-sizing: border-box; }
  .btn:hover {
    opacity: 0.8; }

@media screen and (max-width: 980px) {
  .btn {
    font-size: 1.5rem;
    margin: 0 8px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    width: calc(100% - 16px); } }

/* ==============================
module / form
================================= */
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="date"],
textarea {
  padding: 7px;
  border-radius: 4px;
  font-size: 1.2rem;
  border: 1px solid #D5D5D5;
  box-sizing: border-box;
  color: #989898; }

input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"] {
  width: 130px; }

input[type="text"] {
  width: 66.7%; }

textarea {
  width: 75%;
  resize: none; }
  textarea.bid {
    width: 100%;
    height: 60px; }

select {
  width: 130px;
  padding: 0 8px;
  height: 30px;
  border-radius: 4px;
  font-size: 1.2rem;
  border: 1px solid #D5D5D5;
  box-sizing: border-box; }

input[type="radio"],
input[type="checkbox"] {
  margin-right: 5px; }

label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: top;
  align-items: top; }
  label input[type="radio"] {
    margin-top: 0; }
  label input[type="checkbox"] {
    margin-top: 0; }
  label > span {
    display: inline-block;
    vertical-align: top;
    line-height: 1.71; }

.select-cover {
  position: relative;
  display: inline-block; }
  .select-cover:before {
    position: absolute;
    top: 8px;
    right: 7px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #363636;
    pointer-events: none; }
  .select-cover:after {
    position: absolute;
    bottom: 8px;
    right: 7px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #363636;
    pointer-events: none; }
  .select-cover.full {
    width: 66.7%; }
    .select-cover.full select {
      width: 100%; }
  .select-cover.max {
    width: 100%; }
    .select-cover.max select {
      width: 100%; }

.table-calendar-wrap {
  position: relative;
  display: inline-block; }
  .table-calendar-wrap:before {
    position: absolute;
    display: block;
    content: url(/images/common/calendar.png);
    width: 16px;
    height: 16px;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    z-index: 2; }

input[type="text"].table-calendar {
  width: auto; }

.table-calendar {
  position: relative;
  z-index: 1; }

.panel-block label {
  transition: .3s; }

.panel-block input[type="radio"] {
  display: none; }
  .panel-block input[type="radio"]:checked + label {
    background: url(assets/images/common/radio_ck.png) no-repeat center left;
    background-size: 24px 24px; }
  .panel-block input[type="radio"] + label {
    background: url(assets/images/common/radio.png) no-repeat center left;
    background-size: 24px 24px; }

.panel-block span {
  font-size: 1.8rem;
  font-weight: bold;
  padding-left: 30px;
  color: #767676; }

@media screen and (max-width: 980px) {
  .panel-block input[type="radio"]:checked + label {
    background: url(assets/images/common/radio_ck_sp.png) no-repeat center left;
    background-size: 18px 18px; }
  .panel-block input[type="radio"] + label {
    background: url(assets/images/common/radio_sp.png) no-repeat center left;
    background-size: 18px 18px; }
  .panel-block span {
    font-size: 1.3rem;
    font-weight: bold;
    padding-left: 20px;
    color: #767676; } }

/* ==============================
module / assign
================================= */
.table-green-cover {
  position: relative;
  background: #ddd;
  padding: 15px 20px;
  box-sizing: border-box; }

.table-green-cover-clear {
  position: absolute;
  top: 15px;
  right: 20px;
  text-decoration: underline;
  font-size: 1.2rem;
  color: #4094DD;
  cursor: pointer; }
  .table-green-cover-clear a {
    text-decoration: underline;
    font-size: 1.2rem; }

.login-table {
  width: 100%; }
  .login-table th {
    width: 190px;
    text-align: left;
    font-size: 1.4rem;
    line-height: 1.7;
    font-weight: normal;
    box-sizing: border-box;
    padding: 0 10px 30px 0; }
  .login-table td {
    padding: 0 0 30px 0; }
    .login-table td input[type="text"],
    .login-table td input[type="email"],
    .login-table td input[type="password"],
    .login-table td input[type="tel"] {
      width: 100%; }

.mdl-def-table {
  width: 100%; }
  .mdl-def-table tr {
    border-top: 1px solid #ddd-gray;
    border-bottom: 1px solid #ddd-gray; }
  .mdl-def-table th {
    position: relative;
    width: 25%;
    padding: 20px 20px;
    line-height: 1.67;
    text-align: left;
    vertical-align: middle;
    background: #ddd-gray;
    box-sizing: border-box;
    vertical-align: middle; }
    .mdl-def-table th:before {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      top: 0px;
      left: 0;
      background: #fff;
      z-index: 1; }
    .mdl-def-table th:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      bottom: 0px;
      left: 0;
      background: #fff;
      z-index: 1; }
  .mdl-def-table td {
    width: 100%;
    padding: 20px 20px;
    background: #fff;
    line-height: 1.71;
    box-sizing: border-box;
    vertical-align: top; }
  .mdl-def-table .table-green-cover {
    margin-top: 10px; }
  .mdl-def-table.mini th {
    padding: 10px;
    width: 84px;
    font-size: 1.2rem;
    line-height: 1.33;
    font-weight: normal; }
  .mdl-def-table.mini td {
    width: auto;
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.33; }

.def-table-ttl-wrap {
  display: table;
  width: 100%;
  table-layout: fixed; }

.def-table-ttl {
  width: 170px;
  display: table-cell; }

.def-table-label {
  width: 34px;
  display: table-cell;
  text-align: right;
  vertical-align: middle; }

.table-label {
  font-size: 1rem;
  color: #fff;
  display: inline-block;
  width: 34px;
  text-align: center;
  padding: 3px 5px;
  border-radius: 4px;
  box-sizing: border-box; }
  .table-label.green {
    background: #00b4de; }
  .table-label.red {
    background: #f73c35; }

.table-in-block {
  margin-bottom: 5px; }
  .table-in-block:after {
    content: "";
    display: block;
    clear: both; }

.table-in-cel-left {
  float: left;
  width: calc(100% - 80px); }

.table-in-cel-right {
  float: right;
  width: 80px;
  text-align: right; }

.table-calendar-center {
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 5px; }

.table-in-block-160 {
  width: 160px;
  padding-right: 10px;
  box-sizing: border-box;
  vertical-align: middle; }

.table-in-block-130 {
  width: 130px;
  padding-right: 10px;
  vertical-align: middle; }

.table-in-block-450 {
  width: 450px; }

.table-in-block-full {
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle; }

.mdl-bid-table {
  width: 100%; }
  .mdl-bid-table tr {
    border-top: 1px solid #ddd-gray;
    border-bottom: 1px solid #ddd-gray; }
  .mdl-bid-table th {
    position: relative;
    width: 25%;
    padding: 20px 20px;
    font-size: 1.2rem;
    line-height: 1.67;
    text-align: left;
    vertical-align: middle;
    border-top: 5px solid #fff;
    border-bottom: 4px solid #fff;
    background: #ddd-gray;
    box-sizing: border-box;
    vertical-align: middle; }
    .mdl-bid-table th:before {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      top: -3px;
      left: 0;
      background: #ddd-gray; }
    .mdl-bid-table th:after {
      position: absolute;
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      bottom: -3px;
      left: 0;
      background: #ddd-gray; }
  .mdl-bid-table td {
    width: calc((100% - 25%)/2);
    padding: 20px 20px;
    font-size: 1.2rem;
    line-height: 1.71;
    box-sizing: border-box;
    vertical-align: middle; }
  .mdl-bid-table .table-green-cover {
    margin-top: 10px; }
  .mdl-bid-table.mini th {
    padding: 10px;
    width: 84px;
    font-size: 1.2rem;
    line-height: 1.33;
    font-weight: normal; }
  .mdl-bid-table.mini td {
    width: auto;
    padding: 10px;
    font-size: 1.2rem;
    line-height: 1.33; }
  .mdl-bid-table .def-thead {
    border-top: none; }

.def-thead th {
  background: none; }
  .def-thead th:before {
    display: none; }

.def-thead td {
  position: relative;
  font-weight: bold;
  line-height: 1.67;
  text-align: center; }
  .def-thead td span {
    font-size: 1.2rem;
    position: relative;
    z-index: 2; }

.def-thead .bid-table-border {
  position: relative; }

.def-thead .bid-table-cover {
  position: absolute;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: #ddd-gray;
  top: 2px;
  left: 2px;
  z-index: 1; }

.bid-table-border {
  border-right: 1px solid #ddd-gray; }

.conditions-list-table {
  width: 100%;
  table-layout: fixed;
  font-size: 1.2rem; }

td {
  vertical-align: top; }

.conditions-list-label {
  width: 120px;
  box-sizing: border-box;
  vertical-align: top; }
  .conditions-list-label > span {
    display: inline-block;
    width: 104px;
    text-align: center;
    background: #3e3e3e;
    font-size: 1rem;
    color: #fff;
    padding: 5px;
    box-sizing: border-box; }

.conditions-list-cat {
  width: 155px;
  padding: 5px 0; }

.conditions-list-icon {
  width: 15px;
  text-align: center;
  padding: 5px 0; }

.conditions-list-about {
  width: 100%;
  padding: 5px 0;
  line-height: 1.67; }

.mdl-black-table {
  width: 100%; }
  .mdl-black-table tr {
    border-bottom: 1px solid #ddd-gray; }
  .mdl-black-table td {
    border-right: 1px solid #ddd-gray;
    background: #fff;
    text-align: center;
    height: 70px;
    padding: 20px 30px;
    box-sizing: border-box;
    vertical-align: middle; }
    .mdl-black-table td a {
      text-decoration: underline; }
  .mdl-black-table tr > td:first-child {
    border-left: 1px solid #ddd-gray; }
  .mdl-black-table.no-border tr:last-child {
    border-bottom: none; }
  .mdl-black-table.no-border tr > td:first-child {
    border-left: none; }
  .mdl-black-table.no-border tr > td:last-child {
    border-right: none; }
  .mdl-black-table .black-table-name {
    text-align: left; }
  .mdl-black-table .btn-wrap .btn-t {
    width: 140px; }
  .mdl-black-table .btn-wrap .btn {
    width: 140px; }
  .mdl-black-table .color-red {
    display: block;
    font-weight: bold; }
  .mdl-black-table .color-red-message {
    margin-bottom: 10px;
    font-size: 1.2rem; }

.black-table-head td {
  height: 30px;
  padding: 0;
  background: #3e3e3e;
  border-right: none;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-weight: 1.4rem;
  vertical-align: middle; }

.table-active-label {
  width: 130px;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 3px 0;
  box-sizing: border-box; }
  .table-active-label.red {
    background: #f73c35; }
  .table-active-label.green {
    background: #00b4de; }
  .table-active-label.blue {
    background: #0C5671; }
  .table-active-label.gray {
    background: #9B9B9B; }

/* ==============================
module / other
================================= */
body {
  background: #f6f6f6; }
  body.clear {
    background: none; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

.detail-thumb img {
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

.thumb img {
  object-fit: contain;
  font-family: 'object-fit: contain;'; }

.dateSlide {
  font-weight: bold;
  margin-bottom: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .dateSlide .pn {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    background-color: #7d7d7d;
    cursor: pointer;
    color: #fff;
    width: 130px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    font-size: 1.6rem;
    position: relative; }
    .dateSlide .pn:before {
      font-size: 3rem;
      font-weight: bold;
      position: absolute; }
    .dateSlide .pn.prev:before {
      left: 5px; }
    .dateSlide .pn.next:before {
      right: 5px; }
    .dateSlide .pn:hover {
      opacity: .7; }
    .dateSlide .pn.slick-disabled {
      color: #ddd;
      background: #aaa;
      cursor: inherit; }
      .dateSlide .pn.slick-disabled:hover {
        opacity: 1; }

.dateSlideList {
  width: calc(100% - 138px *2); }
  .dateSlideList li {
    background: #fff;
    height: 60px;
    position: relative; }
    .dateSlideList li:after {
      background: #DDD;
      content: "";
      display: inline-block;
      height: 40px;
      position: absolute;
      right: 0px;
      top: 10px;
      width: 1px; }
  .dateSlideList .slick-list {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px; }

.mdl-tab-layout-date {
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  text-align: center; }
  .mdl-tab-layout-date p {
    line-height: 1.4rem;
    margin-top: 12px; }
    .mdl-tab-layout-date p .mon {
      line-height: 1.4rem;
      margin-top: 12px;
      vertical-align: text-top; }
    .mdl-tab-layout-date p .date {
      margin-left: 2px;
      font-size: 2.4rem; }
  .mdl-tab-layout-date .day {
    line-height: 1; }

.sat {
  color: #005275; }

.sun {
  color: #db4233; }

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px) {
  a:hover {
    opacity: 1 !important; }
  .dateSlide {
    margin-bottom: 15px; }
  .dateSlideList {
    width: 100%; }
    .dateSlideList li {
      width: 70px; }
  .event-list-area, .top-spot-list {
    margin: 0 8px 20px; } }

/*----------------------------
mdoal
----------------------------*/
body {
  position: relative; }

/* ==============================
module / header
================================= */
.l-header {
  width: 100%;
  min-width: 1280px; }
  .l-header a {
    text-decoration: none;
    color: inherit; }
  .l-header div {
    line-height: 1; }
  @media screen and (max-width: 640px) {
    .l-header {
      min-width: auto; } }

.header-top {
  background-color: #fff;
  border-top: 4px solid #00bbfb;
  border-bottom: 1px solid #DDD;
  padding: 15px 25px; }

.header-top-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 auto; }
  .header-top-inner:after {
    content: "";
    display: block;
    clear: both; }

.header-left {
  width: calc(100% - 480px);
  vertical-align: middle; }

.header-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 480px;
  vertical-align: middle; }

.header-logo {
  display: inline-block;
  vertical-align: middle; }
  .header-logo img {
    width: 194px; }

.l-header-search {
  display: inline-block !important;
  margin: 0 20px;
  vertical-align: middle; }

.l-search {
  display: inline-block;
  position: relative;
  border: 2px solid #DDD;
  background: #eee;
  border-radius: 3px;
  height: 3.6rem; }
  .l-search .header-input-wrap {
    display: block;
    position: relative;
    float: left;
    box-sizing: border-box;
    height: 3.6rem; }
    .l-search .header-input-wrap.search-select {
      border-right: 1px dashed #DDD;
      width: 80px; }
      .l-search .header-input-wrap.search-select.icon-arrow_bottom {
        position: relative; }
        .l-search .header-input-wrap.search-select.icon-arrow_bottom:before {
          font-weight: bold;
          position: absolute;
          top: 33%;
          margin: auto;
          right: 5px; }
    .l-search .header-input-wrap.search-button {
      top: -2px;
      right: -1px;
      z-index: 1;
      height: 4rem; }

.header-search-input {
  position: relative;
  z-index: 1;
  padding-left: 8px;
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  line-height: normal;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 3.6rem; }
  .header-search-input.button {
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    border-bottom-right-radius: 3px;
    background: #767676;
    color: #fff;
    height: 4rem;
    width: 4rem;
    padding: 0; }
    .header-search-input.button i {
      font-size: 30px; }
    .header-search-input.button:hover {
      opacity: .8; }

input[type="text"].header-search-input {
  border: none;
  width: 380px; }

.header-option-list .option-list-item {
  display: inline-block;
  font-size: .71rem;
  margin-right: 18px;
  vertical-align: middle; }
  .header-option-list .option-list-item:before {
    display: inline-block;
    font-size: 2rem;
    vertical-align: inherit; }
  .header-option-list .option-list-item.select-lng {
    cursor: pointer;
    display: inline-block;
    position: relative;
    z-index: 999; }
    .header-option-list .option-list-item.select-lng i {
      font-size: 1.4rem;
      margin-left: 5px;
      vertical-align: inherit; }
  .header-option-list .option-list-item .lng-list {
    position: absolute;
    z-index: 999;
    width: 100px;
    overflow: hidden;
    height: 0;
    left: 5px;
    -webkit-transition: .3s;
    transition: .3s;
    margin-top: 5px; }
    .header-option-list .option-list-item .lng-list li {
      background-color: #d7edf2;
      border-bottom: 1px solid #00bbfb;
      height: 24px;
      text-align: center; }
      .header-option-list .option-list-item .lng-list li:last-child {
        border-bottom: none; }
      .header-option-list .option-list-item .lng-list li a {
        color: #00bbfb;
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 24px; }
    .header-option-list .option-list-item .lng-list.show {
      height: 124px;
      -webkit-transition: .3s;
      transition: .3s; }

.l-header-snsList {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .l-header-snsList li {
    margin-left: 8px; }
    .l-header-snsList li span {
      background: #ddd;
      color: #fff;
      border-radius: 50%;
      font-size: 26px;
      height: 26px;
      width: 26px; }
      .l-header-snsList li span.icon-twitter {
        background: #55acee; }
      .l-header-snsList li span.icon-facebook1 {
        background: #315096; }
      .l-header-snsList li span.icon-youtube {
        background: #d00f31; }

nav {
  margin: 0 auto;
  width: 100%;
  background: #fff; }
  nav .l-nav-inner {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin: 0 auto;
    width: 1235px; }

.l-header-navList {
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 990px; }
  .l-header-navList > li {
    position: relative;
    width: calc(100% / 9);
    height: 90px;
    text-align: center; }
    .l-header-navList > li:nth-child(2) {
      margin-right: 10px; }
      .l-header-navList > li:nth-child(2):after {
        background: #DDD;
        bottom: 0;
        content: "";
        display: block;
        height: 70px;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px; }
    .l-header-navList > li a {
      display: block;
      width: 100%;
      height: 100%; }
      .l-header-navList > li a > span {
        display: block;
        padding: 60px 0 0; }
        .l-header-navList > li a > span:before {
          font-size: 60px;
          display: block;
          width: 60px;
          height: 60px;
          position: absolute;
          top: 5px;
          left: 0;
          right: 0;
          margin: auto; }
    .l-header-navList > li.event a span {
      color: #81ac1c; }
    .l-header-navList > li.feature a span {
      color: #00b4de; }
    .l-header-navList > li.amusement a span {
      color: #fa9e2c; }
    .l-header-navList > li.tourism a span {
      color: #f73c35; }
    .l-header-navList > li.leaning a span {
      color: #2d8ad7; }
    .l-header-navList > li.food a span {
      color: #e71865; }
    .l-header-navList > li.shopping a span {
      color: #00b063; }
    .l-header-navList > li.relax a span {
      color: #a36b98; }
    .l-header-navList > li.others a span {
      color: #767676; }
  @media screen and (min-width: 981px) {
    .l-header-navList .navList-item:hover a:before, .l-header-navList .current a:before {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: "";
      display: block;
      border: 8px solid transparent;
      width: 0px; }
    .l-header-navList .navList-item:hover a:after, .l-header-navList .current a:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 100%;
      height: 3px; }
    .l-header-navList .navList-item:hover.event a:before, .l-header-navList .current.event a:before {
      border-bottom-color: #81ac1c; }
    .l-header-navList .navList-item:hover.event a:after, .l-header-navList .current.event a:after {
      background: #81ac1c; }
    .l-header-navList .navList-item:hover.feature a:before, .l-header-navList .current.feature a:before {
      border-bottom-color: #00b4de; }
    .l-header-navList .navList-item:hover.feature a:after, .l-header-navList .current.feature a:after {
      background: #00b4de; }
    .l-header-navList .navList-item:hover.amusement a:before, .l-header-navList .current.amusement a:before {
      border-bottom-color: #fa9e2c; }
    .l-header-navList .navList-item:hover.amusement a:after, .l-header-navList .current.amusement a:after {
      background: #fa9e2c; }
    .l-header-navList .navList-item:hover.tourism a:before, .l-header-navList .current.tourism a:before {
      border-bottom-color: #f73c35; }
    .l-header-navList .navList-item:hover.tourism a:after, .l-header-navList .current.tourism a:after {
      background: #f73c35; }
    .l-header-navList .navList-item:hover.leaning a:before, .l-header-navList .current.leaning a:before {
      border-bottom-color: #2d8ad7; }
    .l-header-navList .navList-item:hover.leaning a:after, .l-header-navList .current.leaning a:after {
      background: #2d8ad7; }
    .l-header-navList .navList-item:hover.food a:before, .l-header-navList .current.food a:before {
      border-bottom-color: #e71865; }
    .l-header-navList .navList-item:hover.food a:after, .l-header-navList .current.food a:after {
      background: #e71865; }
    .l-header-navList .navList-item:hover.shopping a:before, .l-header-navList .current.shopping a:before {
      border-bottom-color: #00b063; }
    .l-header-navList .navList-item:hover.shopping a:after, .l-header-navList .current.shopping a:after {
      background: #00b063; }
    .l-header-navList .navList-item:hover.relax a:before, .l-header-navList .current.relax a:before {
      border-bottom-color: #a36b98; }
    .l-header-navList .navList-item:hover.relax a:after, .l-header-navList .current.relax a:after {
      background: #a36b98; }
    .l-header-navList .navList-item:hover.others a:before, .l-header-navList .current.others a:before {
      border-bottom-color: #767676; }
    .l-header-navList .navList-item:hover.others a:after, .l-header-navList .current.others a:after {
      background: #767676; } }

.l-header-subnav {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .l-header-subnav .subnav-menu {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-weight: bold;
    height: 65px;
    margin-left: 10px;
    position: relative;
    text-align: center;
    width: 110px; }
    .l-header-subnav .subnav-menu.skyblue {
      background: #00bbfb;
      color: #fff; }
    .l-header-subnav .subnav-menu.softblue {
      background: #d7edf2;
      color: #00bbfb; }
    .l-header-subnav .subnav-menu a {
      display: block;
      width: 100%;
      height: 100%; }
      .l-header-subnav .subnav-menu a > span {
        display: block;
        font-weight: bold;
        padding: 40px 0 0; }
        .l-header-subnav .subnav-menu a > span:before {
          font-size: 30px;
          font-weight: normal;
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 5px;
          left: 0;
          right: 0;
          margin: auto; }

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  #page-wrap {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 100; }
  #modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .2s;
    transition: .2s;
    z-index: 10001; }
  .l-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    min-width: 100%;
    z-index: 10000; }
  .header-top {
    position: relative;
    height: 50px;
    box-sizing: border-box; }
  .header-top-inner {
    display: block; }
  .header-left {
    width: 100%;
    text-align: center; }
  .header-logo img {
    width: 109.5px; }
  nav {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s; }
    nav .l-nav-inner {
      display: block;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #eee; }
  .nav-slide-cover {
    display: block;
    width: 80%;
    height: 100%;
    padding-bottom: 90px;
    position: fixed;
    top: 50px;
    right: -80%;
    margin: 0 0 0 auto;
    background: #fff;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 1000;
    -webkit-transition: .3s;
    transition: .3s; }
  .l-header-navList {
    display: block;
    width: 100%;
    padding: 20px 10px 25px;
    box-sizing: border-box; }
    .l-header-navList > li {
      position: static;
      width: 100%;
      height: auto;
      text-align: left;
      margin-top: 20px; }
      .l-header-navList > li:first-child {
        margin-top: 0; }
      .l-header-navList > li > a {
        position: relative;
        height: 30px;
        padding-left: 30px;
        line-height: 30px;
        font-size: 1.5rem;
        box-sizing: border-box; }
        .l-header-navList > li > a span {
          position: absolute;
          left: 0;
          top: 0;
          padding: 0;
          display: block; }
          .l-header-navList > li > a span:before {
            position: static;
            margin: 0;
            width: 30px;
            height: 30px;
            font-size: 3rem; }
      .l-header-navList > li:nth-child(2):after {
        display: none; }
  .l-header-subnav {
    display: block;
    padding: 0 10px; }
    .l-header-subnav .subnav-menu {
      width: 100%;
      height: 45px;
      margin: 0; }
      .l-header-subnav .subnav-menu a {
        font-size: 1.4rem; }
        .l-header-subnav .subnav-menu a > span {
          display: inline-block;
          line-height: 45px;
          vertical-align: top;
          padding: 0; }
          .l-header-subnav .subnav-menu a > span:before {
            position: static;
            display: inline-block;
            vertical-align: top; }
      .l-header-subnav .subnav-menu.skyblue {
        margin-bottom: 10px; }
  .lng-list {
    margin-top: 20px;
    padding: 0 10px; }
    .lng-list > li {
      margin-top: 15px; }
      .lng-list > li:first-child {
        margin-top: 0; }
    .lng-list a {
      font-size: 1.3rem; }
  .l-header-snsList {
    margin-top: 20px;
    display: block;
    letter-spacing: -.4em; }
    .l-header-snsList > li {
      display: inline-block;
      letter-spacing: normal; }
  .l-header-search {
    display: none !important; }
  .nav-btn {
    position: absolute;
    right: 10px;
    top: 9px; }
  .nav-btn-menu {
    position: relative;
    width: 20px;
    height: 15px;
    display: block;
    margin: 0 auto; }
    .nav-btn-menu > span {
      position: absolute;
      display: block;
      width: 100%;
      right: 0;
      height: 2px;
      background: #333;
      -webkit-transition: .4s;
      transition: .4s; }
      .nav-btn-menu > span:nth-child(1) {
        top: 0; }
      .nav-btn-menu > span:nth-child(2) {
        top: 6.5px; }
      .nav-btn-menu > span:nth-child(3) {
        bottom: 0; }
  .search-btn-txt {
    font-size: 1rem;
    -webkit-transform: scale(0.8);
    transform: scale(0.8); }
  .search-btn {
    position: absolute;
    left: 10px;
    top: 3px; }
    .search-btn > span {
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 28px;
      display: block;
      text-align: center;
      -webkit-transition: .3s;
      transition: .3s; }
      .search-btn > span:before {
        font-size: 2.8rem; }
    .search-btn .search-btn-txt {
      position: absolute;
      top: 25px;
      width: 50px;
      left: -5px;
      display: block; }
    .search-btn .search-btn-close {
      position: relative;
      width: 40px;
      height: 28px;
      display: block;
      margin: 0 auto;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: .3s;
      transition: .3s; }
      .search-btn .search-btn-close > span {
        position: absolute;
        display: block;
        width: 28px;
        left: 6px;
        height: 2px;
        background: #333;
        -webkit-transition: .3s;
        transition: .3s; }
        .search-btn .search-btn-close > span:nth-child(1) {
          top: 14px;
          transform: rotate(45deg); }
        .search-btn .search-btn-close > span:nth-child(2) {
          bottom: 12px;
          transform: rotate(-45deg); }
  .search-nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out; }
  .search-nav-inner {
    padding: 30px 10px 30px;
    box-sizing: border-box; }
  .front-ttl {
    text-align: center;
    color: #3c3c3c;
    font-weight: bold;
    font-size: 2rem; }
  .front-sub-ttl {
    margin-top: 10px;
    font-size: 1.2rem;
    text-align: center;
    color: #282828; }
  .search-nav-content {
    margin-top: 20px; }
  .l-search {
    display: block; }
  input[type="text"].header-search-input {
    width: 100%;
    border: 1px solid #cacaca;
    background: #eee; }
  .header-input-wrap {
    position: relative; }
    .header-input-wrap:before {
      font-size: 3rem;
      display: block;
      top: 2px;
      right: 0;
      position: absolute;
      color: #767676;
      z-index: 10; } }

/* ==============================
module / footer
================================= */
.l-banner-white {
  width: 100%;
  min-width: 1280px;
  background: #fff; }
  .l-banner-white .inner {
    padding: 60px 0 30px; }
    .l-banner-white .inner .l-banner-list ul {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: start;
      align-items: start;
      -webkit-justify-content: space-between;
      justify-content: space-between; }

.l-banner-gray {
  width: 100%;
  min-width: 1280px; }
  .l-banner-gray .inner {
    padding: 30px; }
  .l-banner-gray .l-banner-carousel {
    margin: 0 auto;
    width: 1141px; }
    .l-banner-gray .l-banner-carousel .l-banner-carousel-body .prev, .l-banner-gray .l-banner-carousel .l-banner-carousel-body .next {
      cursor: pointer;
      font-size: 40px;
      position: absolute;
      top: 10px; }
      .l-banner-gray .l-banner-carousel .l-banner-carousel-body .prev:hover, .l-banner-gray .l-banner-carousel .l-banner-carousel-body .next:hover {
        opacity: 0.8; }
    .l-banner-gray .l-banner-carousel .l-banner-carousel-body .prev {
      left: -40px; }
    .l-banner-gray .l-banner-carousel .l-banner-carousel-body .next {
      right: -40px; }
    .l-banner-gray .l-banner-carousel .l-banner-carousel-item {
      margin: 0 10px; }

.l-footer {
  width: 100%;
  min-width: 1280px;
  background: #fff; }
  .l-footer a {
    color: inherit;
    text-decoration: none; }
  .l-footer .footer-main {
    margin: 0 auto;
    padding: 40px 0;
    position: relative;
    width: 1180px; }
    .l-footer .footer-main .l-footer-row {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: start;
      align-items: start;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
    .l-footer .footer-main .l-footer-menu {
      width: 236px; }
      .l-footer .footer-main .l-footer-menu dt {
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 10px; }
      .l-footer .footer-main .l-footer-menu dd {
        line-height: 2; }
        .l-footer .footer-main .l-footer-menu dd a:hover {
          text-decoration: underline; }
          @media screen and (max-width: 980px) {
            .l-footer .footer-main .l-footer-menu dd a:hover {
              text-decoration: none; } }
  .l-footer .l-footer-sub {
    border-top: 1px solid #DDD;
    position: relative; }
    .l-footer .l-footer-sub .l-footer-pagetop {
      background: #eaeeef;
      -webkit-border-top-left-radius: 5px;
      -moz-border-radius-topleft: 5px;
      border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topright: 5px;
      border-top-right-radius: 5px;
      color: #00bbfb;
      font-weight: bold;
      height: 30px;
      position: absolute;
      top: -31px;
      right: 0;
      width: 140px; }
      .l-footer .l-footer-sub .l-footer-pagetop span {
        display: block;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        width: 100%;
        height: 100%; }
        .l-footer .l-footer-sub .l-footer-pagetop span:hover {
          opacity: .7; }
    .l-footer .l-footer-sub .l-footer-subMenu:after {
      content: "";
      display: block;
      clear: both; }
    .l-footer .l-footer-sub .l-footer-subMenu dl {
      display: inline-block;
      float: left;
      margin-right: 20px; }
      .l-footer .l-footer-sub .l-footer-subMenu dl:after {
        background: #DDD;
        content: "";
        display: inline-block;
        height: 20px;
        width: 1px;
        vertical-align: middle; }
      .l-footer .l-footer-sub .l-footer-subMenu dl:last-child:after {
        background: none; }
      .l-footer .l-footer-sub .l-footer-subMenu dl dt {
        float: left;
        font-weight: bold;
        margin-right: 20px; }
      .l-footer .l-footer-sub .l-footer-subMenu dl dd {
        float: left; }
        .l-footer .l-footer-sub .l-footer-subMenu dl dd ul li {
          display: inline-block;
          margin-right: 20px; }
        .l-footer .l-footer-sub .l-footer-subMenu dl dd ul.l-footer-sns {
          margin-top: -4px; }
          .l-footer .l-footer-sub .l-footer-subMenu dl dd ul.l-footer-sns li {
            color: #767676;
            font-size: 30px;
            line-height: 1;
            margin-right: 10px; }

.l-footer-inner {
  margin: 0 auto;
  padding: 15px 0 7px;
  overflow: hidden;
  width: 1180px; }

.l-footer-navi {
  background: #00bbfb;
  color: #fff; }
  .l-footer-navi .l-footer-inner {
    padding: 10px 0; }
  .l-footer-navi .l-footer-naviList {
    color: #fff;
    font-weight: bold;
    float: left; }
    .l-footer-navi .l-footer-naviList li {
      display: inline-block;
      margin-right: 40px; }
      .l-footer-navi .l-footer-naviList li i {
        font-size: 2rem;
        display: inline;
        vertical-align: sub; }

.copyright {
  float: right;
  font-size: 1rem;
  line-height: 2.4;
  color: #fff; }
  .copyright:after {
    content: "";
    display: block;
    clear: both; }

/* ==============================
Responsive
================================= */
@media screen and (max-width: 980px) {
  .l-footer {
    min-width: 100%; }
    .l-footer .footer-main {
      width: 100%;
      padding: 0; }
      .l-footer .footer-main .l-footer-row {
        display: block; }
      .l-footer .footer-main .l-footer-menu {
        border-top: 1px solid #DDD;
        width: 100%; }
        .l-footer .footer-main .l-footer-menu dt {
          position: relative;
          height: 44px;
          line-height: 44px;
          margin-bottom: 0;
          padding-left: 15px;
          cursor: pointer; }
          .l-footer .footer-main .l-footer-menu dt.active .footer-row-icon-02 {
            opacity: 0; }
        .l-footer .footer-main .l-footer-menu dd {
          background: #f6f6f6;
          overflow: hidden;
          display: none; }
      .l-footer .footer-main .l-footer-menuList {
        overflow: hidden; }
        .l-footer .footer-main .l-footer-menuList li {
          margin: 10px 0;
          padding-left: 25px; }
        .l-footer .footer-main .l-footer-menuList a {
          display: block;
          width: 100%;
          height: 100%; }
    .l-footer .l-footer-sub {
      border-top: none; }
      .l-footer .l-footer-sub .l-footer-pagetop {
        bottom: 0;
        top: initial;
        width: 100%;
        height: 45px; }
        .l-footer .l-footer-sub .l-footer-pagetop span {
          line-height: 45px; }
      .l-footer .l-footer-sub .l-footer-subMenu {
        padding-bottom: 45px; }
        .l-footer .l-footer-sub .l-footer-subMenu dl {
          float: none;
          width: 100%;
          display: block; }
          .l-footer .l-footer-sub .l-footer-subMenu dl:after {
            display: none; }
          .l-footer .l-footer-sub .l-footer-subMenu dl:nth-child(2) {
            border-bottom: 1px solid #DDD; }
          .l-footer .l-footer-sub .l-footer-subMenu dl dt {
            display: block;
            position: relative;
            font-size: 1.6rem;
            margin-right: 0;
            float: none; }
          .l-footer .l-footer-sub .l-footer-subMenu dl dd {
            float: none;
            width: 100%; }
            .l-footer .l-footer-sub .l-footer-subMenu dl dd .footer-access-list li {
              display: block;
              margin: 10px 0;
              margin-right: 0;
              padding-left: 25px; }
            .l-footer .l-footer-sub .l-footer-subMenu dl dd ul.l-footer-sns {
              text-align: center;
              margin: 30px 0; }
              .l-footer .l-footer-sub .l-footer-subMenu dl dd ul.l-footer-sns li {
                font-size: 5rem;
                margin: 0; }
      .l-footer .l-footer-sub .l-footer-menu {
        border-top: 1px solid #DDD;
        width: 100%; }
        .l-footer .l-footer-sub .l-footer-menu dt {
          height: 44px;
          line-height: 44px;
          margin-bottom: 0;
          padding-left: 15px; }
          .l-footer .l-footer-sub .l-footer-menu dt.active .footer-row-icon-02 {
            opacity: 0; }
        .l-footer .l-footer-sub .l-footer-menu dd {
          background: #f6f6f6;
          overflow: hidden; }
    .l-footer .footer-row-icon {
      position: absolute;
      display: block;
      width: 12px;
      height: 12px;
      top: 50%;
      right: 7px;
      transform: translateY(-50%); }
      .l-footer .footer-row-icon span {
        display: block;
        position: absolute;
        background: #a6a6a6; }
    .l-footer .footer-row-icon-01 {
      width: 100%;
      height: 2px;
      top: 5px; }
    .l-footer .footer-row-icon-02 {
      width: 2px;
      height: 100%;
      right: 5px;
      -webkit-transition: .3s;
      transition: .3s;
      opacity: 1; }
  .l-banner-white {
    min-width: 100%; }
    .l-banner-white .inner {
      box-sizing: border-box;
      padding: 30px 8px; }
      .l-banner-white .inner .l-banner-list ul li {
        width: 32%;
        text-align: center;
        margin-bottom: 15px; }
        .l-banner-white .inner .l-banner-list ul li:last-child {
          margin-bottom: 0; }
  .l-banner-gray {
    min-width: 100%; }
    .l-banner-gray .inner {
      padding: 25px 8px;
      box-sizing: border-box; }
    .l-banner-gray .l-banner-carousel {
      width: 100%; }
  .l-footer-inner {
    width: 100%;
    padding: 0; }
  .l-footer-navi {
    text-align: center; }
    .l-footer-navi .l-footer-naviList {
      float: none; }
      .l-footer-navi .l-footer-naviList li {
        display: block;
        margin-right: 0;
        line-height: 2; }
  .copyright {
    float: none; } }

@media screen and (max-width: 639px) {
  .l-banner-white .inner .l-banner-list ul {
    display: block; }
    .l-banner-white .inner .l-banner-list ul li {
      width: 100%;
      text-align: center;
      margin-bottom: 15px; }
      .l-banner-white .inner .l-banner-list ul li:last-child {
        margin-bottom: 0; } }

/* ==============================
module / breadcrumb
================================= */
.breadcrumb-wrap {
  padding: 10px 0;
  background: #fff;
  border-top: 1px solid #eaeeef;
  border-bottom: 1px solid #eaeeef; }

.breadcrumb {
  width: 1180px;
  margin: 0 auto; }
  .breadcrumb > li {
    display: inline-block;
    margin-right: 5px; }
    .breadcrumb > li:after {
      /* use !important to prevent issues with browser extensions that change /font */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e906";
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle; }
    .breadcrumb > li:first-child {
      font-weight: bold; }
    .breadcrumb > li:last-child:after {
      display: none; }
    .breadcrumb > li span {
      vertical-align: middle; }
  .breadcrumb .icon-home {
    font-size: 2rem; }
  .breadcrumb a {
    text-decoration: none;
    color: #3e3e3e;
    vertical-align: middle; }

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .breadcrumb {
    width: 100%; }
    .breadcrumb > li {
      font-size: 1rem; } }

/* ==============================
module / archive
================================= */
.archive-nav {
  width: 1180px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: start;
  justify-content: start; }
  .archive-nav > li {
    -webkit-display: flex;
    display: flex;
    width: 280px;
    height: 60px;
    margin-right: 20px;
    text-align: center; }
    .archive-nav > li:last-child {
      margin-right: 0; }
  .archive-nav a {
    display: block;
    width: 100%;
    background: #fff;
    text-decoration: none;
    border-radius: 5px 5px 0 0; }
  .archive-nav span {
    display: inline-block;
    padding-top: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1; }
  .archive-nav .is-active a {
    color: #fff; }
  .archive-nav [class^="icon-"], .archive-nav [class*=" icon-"] {
    position: relative;
    padding-left: 45px; }
    .archive-nav [class^="icon-"]:before, .archive-nav [class*=" icon-"]:before {
      position: absolute;
      top: 9px;
      left: 0;
      font-size: 4rem;
      font-weight: normal; }
  @media screen and (max-width: 980px) {
    .archive-nav [class^="icon-"], .archive-nav [class*=" icon-"] {
      padding-left: 20px; }
      .archive-nav [class^="icon-"]:before, .archive-nav [class*=" icon-"]:before {
        top: 1px;
        left: -8px;
        font-size: 3rem; }
    .archive-nav .icon-ship:before {
      left: -10px; } }

.archive-content-inner {
  width: 100%;
  max-width: 1180px;
  padding: 45px 0 60px;
  margin: 0 auto; }

.archive-category-list > li {
  display: inline-block;
  letter-spacing: normal;
  margin: 0 8px 10px 0; }

.archive-category-list a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 3px 10px;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  background: #ddd;
  color: #989898;
  box-sizing: border-box;
  border-radius: 3px; }
  .archive-category-list a:hover {
    opacity: 1; }

.archive-list-wrap {
  margin-top: 45px; }

.archive-list:after {
  content: "";
  display: block;
  clear: both; }

.archive-list > li {
  float: left; }
  .archive-list > li:nth-child(even) {
    margin-left: 25px; }

.archive-areamap {
  position: fixed;
  width: 50px;
  height: 200px;
  right: 0;
  top: 330px;
  z-index: 100; }
  .archive-areamap a {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background: #00bbfb;
    color: #fff;
    text-decoration: none;
    border-radius: 5px 0 0 5px;
    box-sizing: border-box;
    box-shadow: 3px 0 7px rgba(0, 0, 0, 0.35); }
  .archive-areamap span {
    display: block;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.1; }
    .archive-areamap span:before {
      font-size: 2.5rem;
      font-weight: normal; }

.archive-while-content {
  background: #fff; }

/* ==============================
module / event
================================= */
.event .page-visual {
  background-image: url(/assets/images/event/archive_visual.jpg);
  border-bottom: 5px solid #98bc43; }

.event .archive-nav a {
  color: #98bc43; }

.event .archive-nav .is-active a {
  color: #fff;
  background: #98bc43;
  border: 2px solid #98bc43; }

.event .archive-category-list a:hover {
  background: #5abc43;
  color: #fff; }

.event .pager-wrap {
  margin-top: 24px; }

.btn-calendar-inner {
  width: 210px;
  margin-left: auto;
  position: relative; }

.btn-calendar .event-calender-btn {
  display: inline-block;
  width: 210px;
  height: 40px;
  background-color: #98bc43;
  border-radius: 50px;
  font-size: 1.6rem;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-weight: bolder;
  right: 0;
  cursor: pointer; }
  .btn-calendar .event-calender-btn:before {
    font-size: 2.6rem;
    vertical-align: sub; }

@media screen and (max-width: 980px) {
  .btn-calendar #event-calendar {
    display: none; }
  .btn-calendar .flatpickr-mobile {
    position: relative;
    display: inline-block;
    width: 210px;
    height: 40px;
    background-color: #98bc43;
    border-radius: 50px;
    font-size: 1.6rem;
    text-align: center;
    color: #fff;
    font-weight: bolder;
    right: 0;
    cursor: pointer; }
    .btn-calendar .flatpickr-mobile:before {
      position: absolute;
      top: 5px;
      left: 10px;
      display: block;
      /* use !important to prevent issues with browser extensions that change /font */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e90b";
      font-size: 2.6rem;
      vertical-align: middle; } }

/* ==============================
module / event 年間の主要イベント
================================= */
.season-list {
  padding: 20px 25px 35px;
  box-sizing: border-box; }
  .season-list > li {
    margin-top: 40px; }
    .season-list > li:first-child {
      margin-top: 0; }
  @media screen and (max-width: 980px) {
    .season-list {
      padding: 7px 7px 30px; }
      .season-list > li {
        margin-top: 30px; } }

.season-list-ttl-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end; }

.season-list-ttl-mon {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px; }
  @media screen and (max-width: 980px) {
    .season-list-ttl-mon {
      font-size: 1.3rem;
      margin-bottom: 0; } }

.season-list-ttl img {
  width: auto;
  max-width: 100%; }

.season-list-content {
  margin-top: 15px;
  display: flex;
  justify-content: space-between; }
  @media screen and (max-width: 980px) {
    .season-list-content {
      display: block; } }

.season-list-content-img img {
  width: auto;
  max-width: 100%; }

@media screen and (max-width: 980px) {
  .season-list-content-img img {
    width: 100%;
    max-width: auto;
    max-width: initial; } }

.season-list-content-table {
  padding-left: 20px;
  width: calc(100% - 375px);
  box-sizing: border-box; }
  .season-list-content-table table {
    width: 100%;
    table-layout: fixed; }
  .season-list-content-table tr {
    border-bottom: 1px solid #eee; }
  .season-list-content-table td {
    padding: 15px 20px;
    font-size: 1.6rem;
    box-sizing: border-box; }
    .season-list-content-table td a {
      font-weight: bold; }
  .season-list-content-table .icon-window {
    display: inline-block;
    margin-left: 5px;
    font-size: 1.8rem;
    width: auto; }
  @media screen and (max-width: 980px) {
    .season-list-content-table {
      padding-left: 0;
      margin-top: 15px;
      width: 100%; }
      .season-list-content-table td {
        font-size: 1.3rem;
        padding: 7px; }
        .season-list-content-table td:nth-child(3) {
          display: none; }
      .season-list-content-table a {
        text-decoration: none; }
      .season-list-content-table .icon-window {
        margin-top: 3px; } }

.season-list-table-head {
  border-bottom: none; }
  .season-list-table-head td {
    background: #eee;
    vertical-align: middle;
    position: relative;
    font-weight: bold;
    color: #646262; }
    .season-list-table-head td:after {
      position: absolute;
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      right: 0;
      top: 0;
      background: #fff; }

.season-list-table-cel1 {
  width: 180px; }
  @media screen and (max-width: 980px) {
    .season-list-table-cel1 {
      width: 30%; } }

.season-list-table-cel3 {
  width: 180px; }

.spring .season-list-ttl-mon {
  color: #ec93c1; }

@media screen and (max-width: 980px) {
  .spring .season-list-ttl img {
    width: 141.5px; } }

.summer .season-list-ttl-mon {
  color: #4ebfcc; }

@media screen and (max-width: 980px) {
  .summer .season-list-ttl img {
    width: 153px; } }

.autumn .season-list-ttl-mon {
  color: #f6a168; }

@media screen and (max-width: 980px) {
  .autumn .season-list-ttl img {
    width: 156.5px; } }

.winter .season-list-ttl-mon {
  color: #8f91d4; }

@media screen and (max-width: 980px) {
  .winter .season-list-ttl img {
    width: 154px; } }

/* ==============================
module / feature
================================= */
.feature .page-visual {
  background-image: url(/assets/images/feature/archive_visual.jpg); }

.feature .archive-list-wrap {
  margin-top: 0; }

.feature .archive-list {
  float: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap; }
  .feature .archive-list > li {
    float: inherit; }
    .feature .archive-list > li:nth-child(even) {
      margin-left: 0; }
  .feature .archive-list:after {
    display: none; }

.feature .mdl-article-spot .thumb-area {
  overflow: hidden;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px; }
  .feature .mdl-article-spot .thumb-area img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: inherit;
    height: 100%;
    width: auto; }

.feature .mdl-article-spot .text-area {
  padding: 15px; }

.feature .pager-wrap {
  margin-top: 24px; }

/* ==============================
module / course
================================= */
.course .page-visual {
  background-image: url(/assets/images/course/archive_visual.jpg); }

.course .archive-list-wrap {
  margin-top: 0; }

.course .archive-list {
  float: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap; }
  .course .archive-list > li {
    float: inherit; }
    .course .archive-list > li:nth-child(even) {
      margin-left: 0; }
  .course .archive-list:after {
    display: none; }

.course .mdl-article-spot .thumb-area {
  overflow: hidden;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  -moz-border-radius-topright: 5px;
  border-top-right-radius: 5px; }
  .course .mdl-article-spot .thumb-area img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: inherit; }

.course .mdl-article-spot .text-area {
  padding: 15px; }

.course .pager-wrap {
  margin-top: 24px; }

/* ==============================
module / usefulinfo
================================= */
.usefulinfo .page-visual {
  background-image: url(/assets/images/usefulinfo/archive_visual.jpg);
  border-bottom: 5px solid #00bbfb; }

.usefulinfo .archive-nav a {
  color: #00bbfb; }

.usefulinfo .archive-nav .is-active a {
  color: #fff;
  background: #00bbfb;
  border: 2px solid #00bbfb; }

.usefulinfo .archive-content-inner {
  width: 100%;
  max-width: 1180px;
  padding: 45px 0 60px;
  margin: 0 auto; }

.usefulinfo .archive-list-wrap {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 0;
  padding: 30px; }

.usefulinfo .archive-list > li {
  float: none;
  border-bottom: 1px solid #eaeeef;
  margin-bottom: 30px; }
  .usefulinfo .archive-list > li:nth-child(even) {
    margin-left: 0; }
  .usefulinfo .archive-list > li:last-child {
    border: none;
    margin-bottom: 0; }

/* ==============================
module / search
================================= */
.search .archive-content-inner {
  width: 100%;
  max-width: 1180px;
  padding: 45px 0 60px;
  margin: 0 auto; }
  @media screen and (max-width: 980px) {
    .search .archive-content-inner {
      background: #fff;
      padding-top: 15px; } }

.search .archive-list-wrap {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 0;
  padding: 30px; }
  @media screen and (max-width: 980px) {
    .search .archive-list-wrap {
      border-radius: 0;
      padding: 15px 7px; } }

.search .archive-list > li {
  float: none;
  border-bottom: 1px solid #eaeeef;
  margin-bottom: 30px; }
  .search .archive-list > li:nth-child(even) {
    margin-left: 0; }
  .search .archive-list > li:last-child {
    border: none;
    margin-bottom: 0; }

.search .archive-num {
  font-size: 1.8rem;
  font-weight: bold;
  color: #4b4b4b;
  margin-bottom: 5px; }
  @media screen and (max-width: 980px) {
    .search .archive-num {
      margin: 0 7px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eaeeef;
      font-size: 1rem; } }

.search .search-even-col {
  width: 100%;
  box-sizing: border-box;
  vertical-align: middle; }

.search .table-calendar-wrap {
  position: relative;
  display: inline-block;
  width: 290px; }
  .search .table-calendar-wrap:before {
    /* use !important to prevent issues with browser extensions that change /font */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    display: block;
    content: "\e90b";
    width: auto;
    height: auto;
    top: 50%;
    left: 8px;
    font-size: 2.2rem;
    z-index: 2;
    color: #989898;
    transform: translateY(-50%); }
  .search .table-calendar-wrap input {
    width: 100%;
    padding-left: 30px;
    font-size: 1.6rem;
    font-weight: bold; }
  @media screen and (max-width: 980px) {
    .search .table-calendar-wrap {
      margin-bottom: 10px; }
      .search .table-calendar-wrap input {
        min-height: 40px; } }

.search .table-calendar-center {
  font-size: 1.6rem;
  font-weight: bold;
  display: inline-block;
  padding: 0 15px;
  color: #767676;
  vertical-align: middle; }
  @media screen and (max-width: 980px) {
    .search .table-calendar-center {
      margin-bottom: 10px;
      font-size: 1.5rem;
      vertical-align: baseline; } }

.flatpickr-calendar {
  overflow: hidden; }

.flatpickr-months {
  height: 60px;
  background: #989898; }

.flatpickr-prev-month {
  height: 60px !important;
  box-sizing: border-box; }
  .flatpickr-prev-month svg {
    margin-top: 10px;
    color: #fff !important;
    fill: #fff !important; }

.flatpickr-next-month {
  height: 60px !important;
  box-sizing: border-box; }
  .flatpickr-next-month svg {
    margin-top: 10px;
    color: #fff !important;
    fill: #fff !important; }

.flatpickr-month {
  height: 60px !important;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row-reverse;
  align-items: center; }

.flatpickr-current-month {
  top: 8px; }

.flatpickr-weekdays {
  margin-top: 10px; }

.flatpickr-current-month {
  height: 100% !important;
  width: auto !important;
  display: block !important;
  padding: 0 !important;
  left: 150px !important;
  top: 13px !important; }

.cur-month {
  display: block !important;
  font-size: 1.2rem !important;
  color: #fff !important;
  text-align: left; }

.cur-year {
  font-size: 1.2rem !important;
  vertical-align: top  !important;
  margin-top: 3px !important; }

.numInputWrapper {
  color: #fff !important; }

.flatpickr-add-mon {
  width: 60px;
  font-size: 4rem;
  color: #fff;
  position: absolute;
  left: 90px; }

.prevMonthDay {
  pointer-events: none; }

.nextMonthDay {
  pointer-events: none; }

.flatpickr-day:nth-child(7n) {
  color: #4094DD !important; }

.flatpickr-day:nth-child(7n+1) {
  color: #CE2222 !important; }

.flatpickr-weekday:nth-child(7) {
  color: #4094DD !important; }

.flatpickr-weekday:nth-child(1) {
  color: #CE2222 !important; }

.flatpickr-day.selected {
  border: none !important;
  background: #7d7d7d !important;
  color: #fff !important; }

.calendar-green .flatpickr-months {
  background: #98bc43; }

/* ==============================
module / areamap
================================= */
.areamap .page-visual {
  background-image: url(/assets/images/areamap/archive_visual.jpg);
  border-bottom: 5px solid #00bbfb; }

.areamap .archive-nav a {
  color: #00bbfb; }

.areamap .archive-nav .is-active a {
  color: #fff;
  background: #00bbfb;
  border: 2px solid #00bbfb; }

.areamap .archive-content-inner {
  width: 100%;
  max-width: 1180px;
  padding: 45px 0 60px;
  margin: 0 auto; }

.areamap .archive-list-wrap {
  background-color: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-top: 0;
  padding: 30px; }

.areamap .archive-list > li {
  float: none;
  border-bottom: 1px solid #eaeeef;
  margin-bottom: 30px; }
  .areamap .archive-list > li:nth-child(even) {
    margin-left: 0; }
  .areamap .archive-list > li:last-child {
    border: none;
    margin-bottom: 0; }

/* ==============================
module / access
================================= */
.access .page-visual {
  background-image: url(/assets/images/access/archive_visual.jpg);
  border-bottom: 5px solid #00bbfb; }

.access .archive-nav a {
  color: #00bbfb; }

.access .archive-nav .is-active a {
  color: #fff;
  background: #00bbfb;
  border: 2px solid #00bbfb; }

.access-inner {
  position: relative;
  width: 100%;
  max-width: 1180px;
  background: #fff;
  margin: 0 auto;
  padding: 40px;
  margin-top: 45px;
  margin-bottom: 60px;
  box-sizing: border-box; }
  @media screen and (max-width: 980px) {
    .access-inner {
      padding: 20px; } }

.access-ttl-wrap {
  padding-bottom: 40px;
  border-bottom: 1px solid #00bbfb; }
  .access-ttl-wrap.no-border {
    border-bottom: none;
    padding-bottom: 0; }

.access-ttl {
  text-align: center;
  color: #00bbfb;
  font-size: 3.6rem;
  vertical-align: middle;
  font-weight: bold; }
  .access-ttl [class^="icon-"], .access-ttl [class*=" icon-"] {
    vertical-align: middle; }
    .access-ttl [class^="icon-"]:before, .access-ttl [class*=" icon-"]:before {
      font-size: 5rem; }
  @media screen and (max-width: 980px) {
    .access-ttl {
      font-size: 2.4rem; }
      .access-ttl [class^="icon-"]:before, .access-ttl [class*=" icon-"]:before {
        font-size: 3.4rem; } }

.access-ttl-txt {
  margin-top: 30px;
  text-align: center; }
  @media screen and (max-width: 980px) {
    .access-ttl-txt {
      text-align: left; }
      .access-ttl-txt br {
        display: none; } }

.access-content {
  margin-top: 40px; }

.access-list > li {
  margin-top: 50px; }
  .access-list > li:first-child {
    margin-top: 0; }

.access-list-col-left {
  float: left;
  width: calc(100% - 356px); }
  @media screen and (max-width: 980px) {
    .access-list-col-left {
      float: none;
      width: 100%; } }

.access-list-col-right {
  float: right;
  width: 326px;
  margin-left: 30px; }
  @media screen and (max-width: 980px) {
    .access-list-col-right {
      float: none;
      width: 100%;
      margin-left: 0;
      margin-top: 10px; } }

.access-list-ttl {
  color: #00bbfb;
  font-size: 2.4rem;
  font-weight: bold; }
  @media screen and (max-width: 980px) {
    .access-list-ttl {
      font-size: 1.8rem; } }

.access-list-txt {
  margin-top: 5px; }

.access-list-map {
  margin-top: 30px; }

.access-list-map-ttl {
  font-size: 1.8rem;
  font-weight: bold; }

.access-list-map-attention {
  margin-top: 10px;
  text-align: right;
  font-size: 1.4rem; }
  @media screen and (max-width: 980px) {
    .access-list-map-attention {
      font-size: 1.2rem; } }

.access-list-map-img {
  margin-top: 5px;
  padding: 20px 0;
  text-align: center;
  border: 1px solid #d2d2d2;
  box-sizing: border-box; }
  .access-list-map-img img {
    width: auto; }
  @media screen and (max-width: 980px) {
    .access-list-map-img {
      padding: 10px;
      overflow-x: auto; }
      .access-list-map-img img {
        width: 518px; } }

.access-parking-guide {
  margin-top: 50px; }

.parking-guide-inner {
  background: #f7f7f7;
  padding: 10px 20px 40px;
  box-sizing: border-box; }
  @media screen and (max-width: 980px) {
    .parking-guide-inner {
      padding: 10px 10px 30px; } }

.parking-guide-ttl {
  background: #00bbfb;
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold;
  padding: 10px 15px; }
  @media screen and (max-width: 980px) {
    .parking-guide-ttl {
      font-size: 1.8rem;
      padding: 10px 10px; } }

.parking-list {
  margin-top: 30px; }
  .parking-list > li {
    margin-top: 30px; }
    .parking-list > li:first-child {
      margin-top: 0; }
  @media screen and (max-width: 980px) {
    .parking-list > li {
      margin-top: 20px; } }

.parking-list-inner {
  display: table;
  table-layout: fixed;
  width: 100%; }
  @media screen and (max-width: 639px) {
    .parking-list-inner {
      display: block; } }

.parking-list-img {
  width: 250px;
  display: table-cell;
  vertical-align: top;
  box-sizing: border-box; }
  @media screen and (max-width: 639px) {
    .parking-list-img {
      display: block;
      width: 100%; } }

.parking-list-content {
  display: table-cell;
  padding-left: 20px;
  vertical-align: top;
  box-sizing: border-box; }
  @media screen and (max-width: 639px) {
    .parking-list-content {
      display: block;
      width: 100%;
      padding-left: 0;
      margin-top: 5px; } }

.parking-list-ttl {
  font-size: 2.4rem;
  font-weight: bold; }
  .parking-list-ttl a {
    text-decoration: none; }
  .parking-list-ttl .icon-window {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 36px;
    margin-left: 5px;
    vertical-align: top;
    font-size: 1.8rem; }
    .parking-list-ttl .icon-window:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
  @media screen and (max-width: 980px) {
    .parking-list-ttl {
      font-size: 1.6rem; }
      .parking-list-ttl .icon-window {
        width: 30px;
        height: 24px; } }

.access-def-list > li {
  border-top: 1px solid #eee;
  padding: 20px 0; }

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .archive-nav {
    background: #f7f7f7;
    width: 100%;
    padding: 15px 7px 0;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box; }
    .archive-nav > li {
      width: 49%;
      height: 35px;
      margin-bottom: 7px;
      margin-right: 2%; }
      .archive-nav > li:nth-child(even) {
        margin-right: 0; }
    .archive-nav span {
      padding-top: 8px;
      font-size: 1.4rem; }
    .archive-nav a {
      font-size: 1.4rem;
      border-radius: 5px;
      border: 2px solid;
      box-sizing: border-box; }
    .archive-nav .is-active a {
      border: none; }
  .archive-content {
    padding: 0 7px;
    box-sizing: border-box; }
  .archive-content-inner {
    padding-top: 20px; }
  .archive-category-list > li {
    margin: 0 5px 7px 0; }
  .archive-category-list a {
    font-size: 1rem; }
  .archive-list-wrap {
    margin-top: 20px; }
  .archive-list > li:nth-child(even) {
    margin-left: 0; }
  .archive-areamap {
    width: 75px;
    height: 75px;
    right: 7px;
    top: auto;
    bottom: 30px; }
    .archive-areamap a {
      padding-top: 10px;
      border-radius: 50% 50%; }
    .archive-areamap .icon-area {
      display: block; }
    .archive-areamap span {
      display: inline;
      font-size: 1.1rem; }
    .archive-areamap .archive-areamap-txt {
      display: block; }
  .event .page-visual {
    border-bottom: none; }
  .feature .archive-list > li {
    width: 100%; }
  .feature .mdl-article-spot {
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: table;
    width: 100%;
    height: 116px;
    overflow: hidden;
    padding: 8px; }
    .feature .mdl-article-spot .thumb-area {
      border-radius: 0;
      display: table-cell;
      width: 100px;
      height: 100px; }
      .feature .mdl-article-spot .thumb-area img {
        height: 100%;
        width: auto; }
    .feature .mdl-article-spot .text-area {
      display: table-cell;
      padding: 0 0 0 8px;
      font-size: 1rem; }
      .feature .mdl-article-spot .text-area .title {
        font-size: 1.1rem; }
  .mdl-col-list a::after {
    display: none; }
  .course .archive-list > li {
    width: 100%; }
  .course .mdl-article-spot {
    border: 1px solid #ddd;
    box-sizing: border-box;
    display: table;
    width: 100%;
    height: 116px;
    overflow: hidden;
    padding: 8px; }
    .course .mdl-article-spot .thumb-area {
      border-radius: 0;
      display: table-cell;
      width: 100px;
      height: 100px; }
      .course .mdl-article-spot .thumb-area img {
        height: 100%;
        width: auto; }
    .course .mdl-article-spot .text-area {
      display: table-cell;
      padding: 0 0 0 8px;
      font-size: 1rem; }
      .course .mdl-article-spot .text-area .title {
        font-size: 1.1rem; }
  .mdl-col-list a::after {
    display: none; }
  .usefulinfo .page-visual {
    border-bottom: none; }
  .usefulinfo .archive-content {
    padding: 0px; }
  .usefulinfo .archive-content-inner {
    padding-top: 20px; }
  .usefulinfo .archive-list-wrap {
    padding: 20px 7px;
    background-color: #fff;
    border-radius: 0; }
  .usefulinfo .archive-list > li {
    margin-bottom: 15px; } }

/* ==============================
module / detail
================================= */
.detail-wrap {
  width: 100%;
  padding: 30px 0 60px;
  background: #cde9ef; }

.detail {
  max-width: 1180px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box; }

.detail-inner {
  padding: 30px 10px 55px; }

.detail-ttl {
  font-size: 2.4rem; }

.detail-ttl-txt {
  margin-right: 5px;
  vertical-align: middle; }

.detail-header {
  padding: 0 30px 30px;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box; }

.detail-category {
  letter-spacing: -.4em; }
  .detail-category > li {
    display: inline-block;
    margin-right: 10px;
    background: #eaeeef;
    letter-spacing: normal; }
  .detail-category a {
    padding: 5px 10px;
    color: #3e3e3e;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    text-decoration: none; }
  .detail-category + .detail-ttl {
    margin-top: 20px; }

.detail-ttl-bottom > li {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding-left: 25px; }
  .detail-ttl-bottom > li:before {
    position: absolute;
    font-size: 2.6rem;
    top: -5px;
    left: 0; }

.detail-content {
  display: table;
  table-layout: fixed;
  width: 1100px;
  margin: 0 auto; }

.detail-left {
  display: table-cell;
  position: relative;
  vertical-align: top; }

.detail-right {
  display: table-cell;
  width: 392px;
  vertical-align: top; }

.detail-label {
  display: inline-block;
  padding: 3px 10px;
  margin-left: 5px;
  text-align: center;
  font-size: 1.4rem;
  color: #fff; }
  .detail-label.event {
    background: #81ac1c; }
  .detail-label.feature {
    background: #00b4de; }
  .detail-label.amusement {
    background: #fa9e2c; }
  .detail-label.tourism {
    background: #f73c35; }
  .detail-label.leaning {
    background: #2d8ad7; }
  .detail-label.food {
    background: #e71865; }
  .detail-label.shopping {
    background: #00b063; }
  .detail-label.relax {
    background: #a36b98; }
  .detail-label.others {
    background: #767676; }

.detail-sns {
  position: absolute;
  height: 170px;
  top: 100px;
  left: -65px;
  z-index: 10; }
  .detail-sns > li {
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50% 50%;
    margin-top: 10px; }
    .detail-sns > li:first-child {
      margin-top: 0; }
    .detail-sns > li span {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      color: #fff; }

.detail-sns-facebook {
  background: #355193; }
  .detail-sns-facebook span {
    font-size: 5rem; }

.detail-sns-twitter {
  background: #57aeed; }
  .detail-sns-twitter span {
    font-size: 3.5rem; }

.detail-sns-line {
  background: #19bc03; }
  .detail-sns-line span {
    font-size: 5rem; }

.detail-txt-wrap {
  margin-top: 30px;
  padding: 0 40px 0  0; }
  .detail-txt-wrap img {
    max-width: 100%; }

.detail-txt {
  margin-top: 40px;
  line-height: 2.25;
  letter-spacing: 0.01em; }

.detail-side {
  margin-top: 30px;
  padding: 4px;
  border-radius: 5px;
  box-sizing: border-box; }
  .detail-side .icon-window {
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem; }
  .detail-side.basic {
    background: #eee; }
    .detail-side.basic .detail-side-ttl:before {
      content: url(assets/images/detail/icon_01.png);
      display: block;
      top: -11px; }
  .detail-side.coupon {
    background: url(assets/images/common/bg_01.png); }
    .detail-side.coupon .detail-side-ttl {
      border-color: #fcd9d9; }
      .detail-side.coupon .detail-side-ttl:before {
        color: #eb6877; }
  .detail-side.event {
    background: #e8f0d5; }
    .detail-side.event .detail-side-ttl {
      border-color: #e8f0d5; }
      .detail-side.event .detail-side-ttl:before {
        color: #b2c488; }
  .detail-side + .detail-side {
    margin-top: 35px; }

.detail-side-inner {
  padding: 20px 20px 40px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px; }

.detail-side-ttl {
  position: relative;
  padding: 0 0 10px 35px;
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 1px solid #eee; }
  .detail-side-ttl:before {
    position: absolute;
    font-size: 3rem;
    left: 0;
    top: -7px; }

.detail-side-content {
  margin-top: 20px; }

.detail-side-list > li {
  margin-top: 30px; }
  .detail-side-list > li:first-child {
    margin-top: 0; }

.detail-side-list-ttl {
  font-weight: bold;
  font-size: 1.4rem; }
  .detail-side-list-ttl a {
    text-decoration: none; }

.detail-side-list-txt {
  margin-top: 10px; }

.detail-station-list > li {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-top: 10px; }
  .detail-station-list > li:first-child {
    margin-top: 0; }

.detail-station-left {
  display: table-cell;
  width: 90px;
  padding-right: 10px;
  box-sizing: border-box;
  vertical-align: top; }

.detail-station-right {
  display: table-cell;
  vertical-align: top; }

.detail-map {
  margin-top: 40px; }
  .detail-map iframe {
    width: 100%;
    height: 440px; }

.detail-side-qr {
  padding: 10px;
  margin-top: 40px;
  background: #f5f5f5;
  box-sizing: border-box;
  border-radius: 5px; }

.detail-side-qr-inner {
  display: table;
  table-layout: fixed;
  width: 100%; }

.detail-side-qr-left {
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  box-sizing: border-box; }

.detail-side-qr-right {
  width: 144px;
  display: table-cell;
  vertical-align: middle; }

.detail-side-qr-ttl {
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem; }

.detail-side-qr-btn {
  display: block;
  background: #fff;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #cdcdcd;
  text-decoration: none;
  border-radius: 5px; }
  .detail-side-qr-btn span {
    display: inline-block;
    font-size: 2rem;
    vertical-align: middle; }
    .detail-side-qr-btn span:before {
      color: #3e3e3e; }
  .detail-side-qr-btn .detail-side-qr-btn-txt {
    font-size: 1.4rem;
    color: #3e3e3e;
    text-decoration: none;
    letter-spacing: 0.01em; }

.detail-thumb {
  margin-top: 15px;
  text-align: center;
  letter-spacing: -.4rem; }
  .detail-thumb > li {
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    letter-spacing: normal; }
  .detail-thumb img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: contain;'; }
  @media screen and (max-width: 980px) {
    .detail-thumb > li {
      width: 13px;
      height: 13px;
      margin-right: 7px;
      background: #989898;
      border-radius: 50% 50%; }
      .detail-thumb > li.thumbnail-current {
        background: #333; }
    .detail-thumb img {
      display: none; } }

/* ==============================
module / relation-list
================================= */
.l-relation-list {
  background: #fff; }

.detail-relation-list {
  width: 1180px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

/* ==============================
module / detail-spot
================================= */
.detail-spot-list {
  width: 1180px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: start;
  justify-content: start;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }
  .detail-spot-list > li {
    margin-left: 45px; }
    .detail-spot-list > li:first-child {
      margin-left: 0; }

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .detail-wrap {
    background: #fff;
    padding: 0; }
  .detail-inner {
    padding: 20px 0 30px; }
  .detail-header {
    padding: 0 10px 15px; }
  .detail-sns {
    left: 7px; }
    .detail-sns > li {
      width: 40px;
      height: 40px; }
      .detail-sns > li span {
        width: 40px;
        height: 40px;
        line-height: 40px; }
  .detail-sns-facebook span {
    font-size: 4rem; }
  .detail-sns-line span {
    font-size: 4rem; }
  .detail-ttl {
    font-size: 1.7em;
    line-height: 1; }
  .detail-ttl-txt {
    display: block; }
  .detail-label {
    font-size: 1rem;
    margin-left: 0;
    margin-right: 5px; }
  .detail-ttl-bottom {
    margin-top: 5px; }
    .detail-ttl-bottom > li {
      display: block;
      font-size: 1rem;
      padding-left: 20px;
      margin: 5px 0 0; }
      .detail-ttl-bottom > li:first-child {
        margin-top: 0; }
      .detail-ttl-bottom > li:before {
        font-size: 2rem; }
  .detail-txt-wrap {
    margin-top: 15px;
    padding: 0 7px 0;
    font-size: 1.3rem; }
  .detail-content {
    display: block;
    width: 100%; }
  .detail-left {
    display: block;
    width: 100%; }
  .detail-right {
    display: block;
    width: 100%; }
  .detail-map iframe {
    height: 435px; }
  .detail-right {
    margin-top: 35px;
    padding: 0 7px 0;
    box-sizing: border-box; }
  .l-relation-list {
    background: #cde9ef; }
    .l-relation-list .inner {
      padding: 20px 7px 30px;
      box-sizing: border-box; }
  .detail-relation-list {
    width: 100%; }
  .l-detail-spot .inner {
    padding: 20px 7px 30px;
    box-sizing: border-box; }
  .detail-spot-list {
    width: 100%; }
    .detail-spot-list > li {
      width: 49%; }
      .detail-spot-list > li:nth-child(even) {
        margin-left: 1%; }
      .detail-spot-list > li:nth-child(odd) {
        margin-left: 0; }
    .detail-spot-list .mdl-article-spot {
      width: 100%; } }

/* ==============================
module / top
================================= */
.l-main {
  position: relative; }

.top-section h2 {
  font-size: 2.4rem;
  line-height: 1;
  margin-bottom: 45px;
  text-align: center; }
  .top-section h2 span {
    vertical-align: middle; }
    .top-section h2 span.en {
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 0.01em;
      font-size: 4rem;
      margin-right: 30px;
      position: relative; }
      .top-section h2 span.en:after {
        background: #3e3e3e;
        bottom: 0;
        content: "";
        display: inline-block;
        height: 36px;
        margin: auto 0 auto 30px;
        position: absolute;
        top: 0;
        vertical-align: middle;
        width: 2px; }
    .top-section h2 span.ja {
      margin-left: 30px; }

.top-section.l-pkup h2 span.en:after {
  background: #fff; }

.visual {
  position: relative;
  width: 100%;
  height: 440px;
  background-image: url(assets/images/top/bg_dot.png), url(assets/images/top/visual_top.jpg);
  background-position: center bottom;
  background-repeat: repeat, no-repeat;
  background-size: auto, cover;
  box-sizing: border-box; }

.visual-inner {
  height: 100%;
  margin: 0 auto;
  position: relative;
  width: 1180px; }
  .visual-inner .visual-lead {
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 190px; }
    .visual-inner .visual-lead .visual-lead-main {
      color: #fff;
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      text-shadow: 0px 0px 10px rgba(33, 33, 33, 0.5); }

.news {
  background: #fff;
  box-shadow: 0px 2px 1px rgba(183, 183, 183, 0.34);
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 410px;
  width: 1180px;
  z-index: 99; }
  .news a {
    text-decoration: none;
    color: inherit; }
  .news .l-news {
    display: table;
    width: 100%;
    table-layout: fixed;
    line-height: 60px; }
    .news .l-news dt {
      display: table-cell;
      box-sizing: border-box;
      vertical-align: top;
      background: #00bbfb;
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
      height: 60px;
      text-align: center;
      width: 145px; }
    .news .l-news dd {
      display: table-cell;
      box-sizing: border-box;
      vertical-align: top;
      padding-left: 20px; }
      .news .l-news dd .l-news-btn {
        color: #00bbfb;
        display: inline-block;
        float: right;
        font-weight: bold;
        margin-right: 40px;
        position: relative; }
        .news .l-news dd .l-news-btn i {
          font-size: 2rem;
          font-weight: bold;
          margin: -1rem 0 0 6px;
          top: 50%;
          position: absolute; }

.l-tc-panel a {
  position: relative;
  z-index: 2; }

.cover {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1; }

.top-category {
  background: url(assets/images/top/bg_panel.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: auto;
  position: relative;
  width: 100%; }
  .top-category a {
    color: inherit;
    display: block;
    height: 100%;
    text-decoration: none;
    width: 100%; }

.l-tc-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 45px 0;
  position: relative;
  width: 1210px;
  z-index: 1; }
  .l-tc-inner .l-tc-panel {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    border: 2px solid #DDD;
    box-sizing: border-box;
    display: block;
    height: 290px;
    margin: 15px;
    overflow: hidden;
    position: relative;
    text-transform: uppercase;
    width: calc(100% / 4 - 30px); }
    .l-tc-inner .l-tc-panel.event {
      border-color: #81ac1c; }
    .l-tc-inner .l-tc-panel.event .icon, .l-tc-inner .l-tc-panel.event .category-slug {
      color: #81ac1c; }
    .l-tc-inner .l-tc-panel.event .hover-action {
      background-color: #81ac1c; }
    .l-tc-inner .l-tc-panel.feature {
      border-color: #00b4de; }
    .l-tc-inner .l-tc-panel.feature .icon, .l-tc-inner .l-tc-panel.feature .category-slug {
      color: #00b4de; }
    .l-tc-inner .l-tc-panel.feature .hover-action {
      background-color: #00b4de; }
    .l-tc-inner .l-tc-panel.amusement {
      border-color: #fa9e2c; }
    .l-tc-inner .l-tc-panel.amusement .icon, .l-tc-inner .l-tc-panel.amusement .category-slug {
      color: #fa9e2c; }
    .l-tc-inner .l-tc-panel.amusement .hover-action {
      background-color: #fa9e2c; }
    .l-tc-inner .l-tc-panel.tourism {
      border-color: #f73c35; }
    .l-tc-inner .l-tc-panel.tourism .icon, .l-tc-inner .l-tc-panel.tourism .category-slug {
      color: #f73c35; }
    .l-tc-inner .l-tc-panel.tourism .hover-action {
      background-color: #f73c35; }
    .l-tc-inner .l-tc-panel.leaning {
      border-color: #2d8ad7; }
    .l-tc-inner .l-tc-panel.leaning .icon, .l-tc-inner .l-tc-panel.leaning .category-slug {
      color: #2d8ad7; }
    .l-tc-inner .l-tc-panel.leaning .hover-action {
      background-color: #2d8ad7; }
    .l-tc-inner .l-tc-panel.food {
      border-color: #e71865; }
    .l-tc-inner .l-tc-panel.food .icon, .l-tc-inner .l-tc-panel.food .category-slug {
      color: #e71865; }
    .l-tc-inner .l-tc-panel.food .hover-action {
      background-color: #e71865; }
    .l-tc-inner .l-tc-panel.shopping {
      border-color: #00b063; }
    .l-tc-inner .l-tc-panel.shopping .icon, .l-tc-inner .l-tc-panel.shopping .category-slug {
      color: #00b063; }
    .l-tc-inner .l-tc-panel.shopping .hover-action {
      background-color: #00b063; }
    .l-tc-inner .l-tc-panel.relax {
      border-color: #a36b98; }
    .l-tc-inner .l-tc-panel.relax .icon, .l-tc-inner .l-tc-panel.relax .category-slug {
      color: #a36b98; }
    .l-tc-inner .l-tc-panel.relax .hover-action {
      background-color: #a36b98; }
    .l-tc-inner .l-tc-panel.others {
      border-color: #767676; }
    .l-tc-inner .l-tc-panel.others .icon, .l-tc-inner .l-tc-panel.others .category-slug {
      color: #767676; }
    .l-tc-inner .l-tc-panel.others .hover-action {
      background-color: #767676; }
    .l-tc-inner .l-tc-panel a {
      position: relative;
      z-index: 2; }
      .l-tc-inner .l-tc-panel a:hover {
        opacity: 1; }
        @media screen and (min-width: 981px) {
          .l-tc-inner .l-tc-panel a:hover .icon, .l-tc-inner .l-tc-panel a:hover h3, .l-tc-inner .l-tc-panel a:hover .category-slug {
            color: #fff; } }

.panel-inner {
  bottom: 0;
  height: 150px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 150px; }
  .panel-inner .icon {
    font-size: 8.5rem;
    transition: color 0.2s ease-in-out; }
  .panel-inner h3 {
    font-size: 2rem;
    transition: color 0.2s ease-in-out; }
  .panel-inner .category-slug {
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.01em;
    font-weight: bold;
    transition: color 0.2s ease-in-out; }

.l-tc-panel .hover-action {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1; }

.l-tc-panel:hover .hover-action {
  width: 400%;
  height: 400%; }

.cover {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1; }

.top-event-list {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  flex-wrap: wrap; }

.l-hs-list {
  background: #d7edf2; }
  .l-hs-list .top-spot-list {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: start;
    align-items: start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    flex-wrap: wrap; }

.l-pkup {
  background: url(assets/images/event/fireworks.jpg) no-repeat center;
  background-size: cover;
  color: #fff;
  height: 675px;
  position: relative;
  z-index: 0;
  overflow: hidden; }
  .l-pkup:before {
    content: "";
    background: inherit;
    -webkit-filter: blur(10px);
    -moz-filter: blur(10px);
    -o-filter: blur(10px);
    -ms-filter: blur(10px);
    filter: blur(10px);
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    z-index: -1; }
  .l-pkup .btn {
    text-decoration: none; }
    .l-pkup .btn:hover {
      opacity: .8; }
    .l-pkup .btn.white {
      background: inherit;
      color: #fff;
      border: 1px solid #fff; }

.top-pickup-contents {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 40px; }
  .top-pickup-contents .thumbnail {
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 360px;
    width: 540px; }
  .top-pickup-contents .text {
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    font-size: 2rem;
    padding-left: 50px; }
    .top-pickup-contents .text a {
      display: block;
      text-decoration: none;
      color: inherit;
      width: 100%;
      height: 100%; }
      .top-pickup-contents .text a:hover {
        opacity: .8; }
    .top-pickup-contents .text .pickup-ttl {
      font-size: 3.4rem;
      line-height: 1;
      margin-bottom: 20px; }
    .top-pickup-contents .text .pickup-date {
      display: block;
      font-size: 1.8rem;
      margin-bottom: 50px; }

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px) {
  .visual {
    height: 250px; }
  .visual-inner {
    width: 100%; }
    .visual-inner .visual-lead {
      top: 110px; }
      .visual-inner .visual-lead .visual-lead-main {
        font-size: 2.5rem; }
  .news {
    height: 30px;
    margin: 0 8px;
    top: 235px;
    width: calc(100% - 16px); }
    .news .l-news {
      line-height: 30px; }
      .news .l-news dt {
        font-size: .9rem;
        height: 30px;
        width: 70px; }
      .news .l-news dd {
        font-size: 1rem;
        padding-left: 10px; }
  .l-tc-inner {
    padding: 35px 0 15px;
    width: 100%; }
    .l-tc-inner .l-tc-panel {
      border: none;
      margin: 3px 3px 3px 10px;
      height: 50px;
      width: calc(100% / 2 - 13px); }
      .l-tc-inner .l-tc-panel:nth-child(even) {
        margin: 3px 10px 3px 3px; }
      .l-tc-inner .l-tc-panel:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        position: absolute;
        top: 0;
        left: 0; }
      .l-tc-inner .l-tc-panel.event:after {
        border-top-color: #81ac1c;
        border-left-color: #81ac1c; }
      .l-tc-inner .l-tc-panel.feature:after {
        border-top-color: #00b4de;
        border-left-color: #00b4de; }
      .l-tc-inner .l-tc-panel.amusement:after {
        border-top-color: #fa9e2c;
        border-left-color: #fa9e2c; }
      .l-tc-inner .l-tc-panel.tourism:after {
        border-top-color: #f73c35;
        border-left-color: #f73c35; }
      .l-tc-inner .l-tc-panel.leaning:after {
        border-top-color: #2d8ad7;
        border-left-color: #2d8ad7; }
      .l-tc-inner .l-tc-panel.food:after {
        border-top-color: #e71865;
        border-left-color: #e71865; }
      .l-tc-inner .l-tc-panel.shopping:after {
        border-top-color: #00b063;
        border-left-color: #00b063; }
      .l-tc-inner .l-tc-panel.relax:after {
        border-top-color: #a36b98;
        border-left-color: #a36b98; }
      .l-tc-inner .l-tc-panel.others:after {
        border-top-color: #767676;
        border-left-color: #767676; }
  .panel-inner {
    box-sizing: border-box;
    height: 50px;
    line-height: 1;
    text-align: left;
    padding: 10px 5px 5px 45px;
    width: auto; }
    .panel-inner .icon {
      font-size: 4.5rem;
      left: 0;
      line-height: 50px;
      position: absolute;
      top: 0; }
    .panel-inner h3 {
      font-size: 1.4rem;
      letter-spacing: -.1rem; }
    .panel-inner .category-slug {
      font-size: .9rem; }
  .top-section h2 {
    font-size: initial;
    margin-bottom: 25px; }
    .top-section h2 span.en {
      font-size: 2.4rem;
      display: block;
      margin-right: 0; }
      .top-section h2 span.en:after {
        display: none; }
    .top-section h2 span.ja {
      font-weight: normal;
      margin: 5px 0; }
  .l-pkup {
    height: auto; }
  .top-pickup-contents {
    display: block;
    padding: 0 8px;
    box-sizing: border-box;
    margin-bottom: 20px; }
    .top-pickup-contents .thumbnail {
      display: block;
      width: 100%;
      height: 200px; }
    .top-pickup-contents .text {
      display: block;
      font-size: 1.4rem;
      font-weight: bold;
      padding-left: 0;
      margin-top: 15px; }
      .top-pickup-contents .text .pickup-ttl {
        font-size: 1.7rem;
        margin-bottom: 8px; }
      .top-pickup-contents .text .pickup-date {
        font-size: 1rem;
        margin-bottom: 20px; } }

/* ==============================
module / other
================================= */
body {
  background: #f6f6f6; }
  body.clear {
    background: none; }

.clearfix:after {
  content: "";
  display: block;
  clear: both; }

/* ==============================
module / map
================================= */
#map {
  width: 100%;
  max-width: 660px;
  height: 677px;
  border-color: #e5e5e5;
  margin-left: auto; }
  @media screen and (max-width: 980px) {
    #map {
      max-width: auto;
      max-width: initial;
      height: 80vh; } }

.map-balloon-name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center; }

.map-balloon-link {
  text-align: center; }

.gm-style-iw > div {
  display: block !important;
  width: max-content;
  margin: 20px auto 0 !important; }

.areamap .archive-content-inner {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 45px;
  margin-bottom: 60px;
  overflow: hidden; }

.map-list-wrap, .map-detail-wrap {
  width: 520px;
  height: 677px;
  padding: 20px 0;
  background-color: #fff;
  position: absolute;
  top: -20px;
  left: 0;
  box-shadow: 1px 0px 3px -1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 1;
  transition: .3s; }

.map-side-close {
  position: absolute;
  width: 40px;
  top: 20px;
  right: -40px;
  color: #fff;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 0 5px 5px 0; }
  @media screen and (max-width: 980px) {
    .map-side-close {
      display: none; } }
  .map-side-close span {
    display: block;
    width: 100%;
    text-align: center;
    color: #fff;
    line-height: 1.2; }
  .map-side-close .icon-prev {
    font-size: 1.8rem;
    font-weight: bold; }

.map-side-close-inner {
  padding: 20px 0; }

.map-list-visual {
  width: 100%;
  height: 158px;
  background-image: url("assets/images/areamap/map_visual.jpg");
  position: relative;
  z-index: 2; }
  .map-list-visual .map-visual-inner {
    text-align: center; }
    .map-list-visual .map-visual-inner .area-title {
      width: 100%;
      position: absolute;
      top: 50px;
      font-size: 3rem;
      color: #ffffff;
      font-weight: bolder;
      text-shadow: 0 0 8px rgba(0, 0, 0, 0.2); }
      .map-list-visual .map-visual-inner .area-title .icon-area {
        font-size: 4rem;
        vertical-align: sub; }

.mdl-map-list {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  overflow-y: auto; }
  .mdl-map-list > li {
    position: relative;
    padding-bottom: 25px;
    display: inline-table;
    width: 210px;
    vertical-align: top; }
    .mdl-map-list > li span {
      display: inline-block;
      padding-left: 15px;
      text-decoration: none;
      line-height: 1.3;
      font-size: 1.6rem;
      color: #3e3e3e;
      font-weight: bolder;
      cursor: pointer; }
      .mdl-map-list > li span:hover {
        color: #2d8ad7; }
  .mdl-map-list .icon-arrow_right:before {
    position: absolute;
    padding-left: 0;
    left: 0;
    top: 0px;
    font-size: 1.6rem;
    font-weight: bold; }

.map-detail-wrap {
  overflow: scroll; }

.map-detail-visual {
  width: 100%;
  background-color: #eaeeef;
  background-image: none; }
  .map-detail-visual .map-visual-inner {
    position: relative;
    width: 100%; }
    @media screen and (max-width: 980px) {
      .map-detail-visual .map-visual-inner .detail-thumb img {
        display: inline-block; } }
  .map-detail-visual .thumb-area {
    box-sizing: border-box;
    height: 158px;
    position: relative;
    width: 230px;
    vertical-align: top;
    overflow: hidden;
    white-space: nowrap;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    @media screen and (max-width: 980px) {
      .map-detail-visual .thumb-area {
        height: auto; } }
  .map-detail-visual .spot-info-wrap {
    width: 290px;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top;
    padding: 15px 20px;
    box-sizing: border-box;
    vertical-align: top; }
    @media screen and (max-width: 980px) {
      .map-detail-visual .spot-info-wrap {
        padding: 10px; } }
    .map-detail-visual .spot-info-wrap .spot-title {
      font-size: 2.2rem;
      font-weight: bolder; }
      @media screen and (max-width: 980px) {
        .map-detail-visual .spot-info-wrap .spot-title {
          font-size: 1.4rem; } }
    .map-detail-visual .spot-info-wrap .spot-desc {
      padding-bottom: 10px; }
      @media screen and (max-width: 980px) {
        .map-detail-visual .spot-info-wrap .spot-desc {
          font-size: 1rem; } }

.map-spot-btn {
  font-size: 1.6rem;
  height: 40px;
  line-height: 40px; }
  @media screen and (max-width: 980px) {
    .map-spot-btn {
      font-size: 1.2rem;
      height: 25px;
      line-height: 25px;
      margin: 0;
      width: 100%; } }

.map-event-btn {
  font-size: 1.8rem;
  height: 64px;
  line-height: 64px;
  background: none;
  border: 2px solid #00bbfb;
  color: #00bbfb; }
  @media screen and (max-width: 980px) {
    .map-event-btn {
      font-size: 1.2rem;
      height: 40px;
      line-height: 40px; } }

.map-eventlist-wrap {
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto; }
  @media screen and (max-width: 980px) {
    .map-eventlist-wrap {
      padding: 15px; } }
  .map-eventlist-wrap .detail-side-ttl {
    border: none;
    color: #767676;
    padding-bottom: 0; }
    @media screen and (max-width: 980px) {
      .map-eventlist-wrap .detail-side-ttl {
        font-size: 1.2rem;
        padding-left: 25px; }
        .map-eventlist-wrap .detail-side-ttl:before {
          font-size: 2rem;
          top: -4px; } }

.mdl-article-mapspot {
  box-shadow: none;
  border-radius: 0px;
  margin-bottom: 0; }
  .mdl-article-mapspot a {
    color: #3e3e3e; }
  .mdl-article-mapspot > li {
    border-bottom: 1px solid #e5e5e5; }
  @media screen and (max-width: 980px) {
    .mdl-article-mapspot {
      margin-bottom: 20px; }
      .mdl-article-mapspot > li {
        border-bottom: none; } }
  .mdl-article-mapspot .article-inner {
    padding: 20px 0;
    vertical-align: top; }
    @media screen and (max-width: 980px) {
      .mdl-article-mapspot .article-inner {
        padding: 10px 0; }
        .mdl-article-mapspot .article-inner .detail-thumb img {
          display: inline-block; } }
  .mdl-article-mapspot .thumb-area {
    box-sizing: border-box;
    height: 90px;
    position: relative;
    width: 90px;
    overflow: hidden;
    white-space: nowrap;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    @media screen and (max-width: 980px) {
      .mdl-article-mapspot .thumb-area {
        height: 52px;
        width: 52px; } }
  .mdl-article-mapspot .text-area {
    box-sizing: border-box;
    padding-left: 20px;
    position: relative;
    vertical-align: top;
    display: table-cell;
    box-sizing: border-box;
    vertical-align: top; }
    .mdl-article-mapspot .text-area .title {
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1.33;
      margin-bottom: 10px;
      position: relative; }
      @media screen and (max-width: 980px) {
        .mdl-article-mapspot .text-area .title {
          font-size: 1.1rem; } }
    .mdl-article-mapspot .text-area .panel-info {
      font-size: 1.6rem; }
      @media screen and (max-width: 980px) {
        .mdl-article-mapspot .text-area .panel-info {
          font-size: 1rem; }
          .mdl-article-mapspot .text-area .panel-info span:before {
            font-size: 1.6rem; } }
  .mdl-article-mapspot a {
    text-decoration: none; }
    .mdl-article-mapspot a:after {
      border: none; }

.map-slide {
  width: 520px;
  height: 677px;
  padding: 20px 0;
  left: -100%;
  background-color: #fff;
  position: absolute;
  top: -20px;
  box-shadow: 1px 0px 3px -1px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: left .3s; }
  @media screen and (max-width: 980px) {
    .map-slide {
      display: block;
      position: fixed;
      width: 85%;
      height: 80%;
      padding: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      opacity: 0;
      visibility: hidden; }
      .map-slide.modal-open {
        opacity: 1;
        visibility: visible;
        z-index: 10002; } }

@media screen and (max-width: 980px) {
  #map-slide-front {
    display: none; } }

.modal-close {
  display: none; }
  @media screen and (max-width: 980px) {
    .modal-close {
      display: block;
      margin-top: 15px;
      text-align: center; } }

.modal-close-inner {
  display: inline-block; }
  .modal-close-inner img {
    width: 14px;
    vertical-align: middle; }
  .modal-close-inner span {
    display: inline-block;
    margin-left: 5px;
    font-size: 1.4rem;
    font-weight: bold;
    vertical-align: middle; }

/* ==============================
Responsive
================================= */
@media screen and (max-width: 980px) {
  a:hover {
    opacity: 1 !important; }
  .dateSlide {
    margin-bottom: 15px; }
  .dateSlideList {
    width: 100%; }
    .dateSlideList li {
      width: 70px; }
  .event-list-area, .top-spot-list {
    margin: 0 8px 20px; } }

/* ==============================
module / search
================================= */
.search .l-search {
  width: 100%;
  background-color: #ffffff;
  height: 45px;
  display: block;
  box-sizing: border-box; }

@media screen and (max-width: 980px) {
  .search .archive-content {
    padding: 0; } }

.search .page-ttl-txt {
  color: #3e3e3e;
  text-shadow: none; }

.search .page-ttl-bottom {
  color: #3e3e3e;
  text-shadow: none; }

.search .search-button {
  width: 11rem;
  border: none;
  position: absolute;
  right: 0;
  height: 100%; }
  .search .search-button .button {
    background-color: #767676;
    height: 100%;
    width: 100%;
    font-size: 1.8rem;
    color: #fff;
    font-weight: bolder;
    border-radius: 0 5px 5px 0; }

.event-search-keyword .l-search {
  width: 100%;
  background-color: #ffffff;
  height: 45px;
  display: block;
  box-sizing: border-box; }

.event-search-keyword .search-button {
  width: 11rem;
  border: none;
  position: absolute;
  right: 0;
  height: 100%; }
  .event-search-keyword .search-button .button {
    background-color: #767676;
    height: 100%;
    width: 100%;
    font-size: 1.8rem;
    color: #fff;
    font-weight: bolder;
    border-radius: 0 5px 5px 0; }

.search-keyword {
  width: 100%;
  position: absolute;
  left: 0;
  height: 100%; }
  .search-keyword .search-input {
    width: 100%;
    height: 100%;
    border: none;
    font-size: 1.8rem; }
  @media screen and (max-width: 980px) {
    .search-keyword:before {
      /* use !important to prevent issues with browser extensions that change /font */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e91d";
      color: #989898;
      position: absolute;
      top: 2px;
      right: 5px;
      font-size: 3.5rem; }
    .search-keyword .search-input {
      padding-right: 40px;
      font-size: 1.6rem; } }

.form {
  width: 703px;
  margin: 0 auto; }
  @media screen and (max-width: 980px) {
    .form {
      width: 100%;
      padding: 0 7px;
      box-sizing: border-box; } }

@media screen and (max-width: 980px) {
  .event .form {
    padding: 0; } }

.search-wrap {
  background-color: #d7edf2;
  padding: 5px; }

.event .search-wrap {
  background: #dcdcdc; }

.mdl-serch-input-list {
  display: flex;
  padding: 20px 0; }
  .mdl-serch-input-list > li {
    margin-right: 30px;
    margin-bottom: 10px; }
  @media screen and (max-width: 980px) {
    .mdl-serch-input-list {
      display: block;
      padding: 10px 0; }
      .mdl-serch-input-list > li {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 20px; } }

.search-more {
  margin-top: 40px; }

.search-list > li {
  display: block;
  opacity: 1;
  visibility: visible; }

.search-archive-list-wrap {
  position: relative; }

/* ==============================
module / #search-spot #search-event
================================= */
#search-event {
  display: none; }
  @media screen and (max-width: 980px) {
    #search-event {
      padding-bottom: 10px; } }

#search-spot {
  display: none; }
  #search-spot .panel-block span {
    font-size: 1.6rem;
    font-weight: normal; }
  @media screen and (max-width: 980px) {
    #search-spot {
      padding-bottom: 10px; } }

/* ==============================
module / course detail
================================= */
.course-detail .detail-wrap {
  width: 100%;
  padding: 30px 0 60px;
  background: #f6f6f6; }

.course-detail .detail {
  font-size: 1.6rem;
  max-width: 1180px;
  margin: 0 auto;
  background: #fff;
  border-radius: 0;
  box-sizing: border-box; }
  .course-detail .detail p {
    line-height: 2; }

.course-detail .page-visual {
  background-image: url(/assets/images/course/detail/sightseeing/course_visual.jpg); }

.course-detail .detail-inner {
  padding: 60px 0 40px; }

.course-detail .detail-content {
  width: 100%;
  margin: 0 auto; }

.course-detail .lead {
  display: table;
  margin: 0 40px 35px;
  width: 1100px; }
  .course-detail .lead p {
    display: table-cell;
    padding-right: 50px; }
  .course-detail .lead .summary {
    background: #f6f6f6;
    display: table-cell;
    width: 605px; }
    .course-detail .lead .summary p {
      background: #00bbfb;
      box-sizing: border-box;
      color: #fff;
      font-size: 1.8rem;
      font-weight: bold;
      display: block;
      height: 42px;
      line-height: 42px;
      padding-left: 24px;
      width: 100%; }
    .course-detail .lead .summary ul {
      margin: 24px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: start;
      align-items: start;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      flex-wrap: wrap; }
      .course-detail .lead .summary ul li {
        line-height: 2;
        width: 50%; }
        .course-detail .lead .summary ul li a {
          color: #00bbfb;
          text-decoration: none; }
          .course-detail .lead .summary ul li a:hover {
            text-decoration: underline; }

.course-detail .course-spot {
  padding: 50px 0;
  display: table;
  margin: 0 auto;
  width: 1100px; }
  .course-detail .course-spot .text-area {
    display: table-cell;
    padding-right: 50px;
    vertical-align: top;
    width: 570px;
    position: relative; }
    .course-detail .course-spot .text-area .btn-wrap {
      bottom: 0;
      position: absolute;
      width: 570px; }
    .course-detail .course-spot .text-area p {
      margin-bottom: 100px; }
    @media screen and (max-width: 980px) {
      .course-detail .course-spot .text-area p {
        margin-bottom: 0; } }
  .course-detail .course-spot .thumb-area {
    display: table-cell;
    vertical-align: bottom; }
    .course-detail .course-spot .thumb-area:after {
      content: "";
      display: block;
      clear: both; }
    .course-detail .course-spot .thumb-area .main-thumb {
      width: 480px;
      margin-bottom: 15px; }
    .course-detail .course-spot .thumb-area .sub-thumb {
      float: left;
      width: calc((100% - 15px) / 2); }
    .course-detail .course-spot .thumb-area img:nth-child(3) {
      float: right; }

.course-detail .detail-ttl {
  color: #00bbfb;
  font-size: 3.6rem;
  margin-bottom: 10px; }

.course-detail .detail-ttl-sub {
  font-size: 2.4rem;
  margin-bottom: 15px; }

.course-detail .detail-ttl-sub-top {
  border-bottom: 1px solid #00bbfb;
  color: #00bbfb;
  display: block;
  font-size: 1.6rem;
  margin-bottom: 10px; }

.course-detail .interval {
  background: #f6f6f6;
  padding: 40px 0 20px;
  position: relative; }
  .course-detail .interval:before {
    content: "";
    border: 30px solid transparent;
    border-top-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto; }
  .course-detail .interval:after {
    content: "";
    border: 30px solid transparent;
    border-top-color: #f6f6f6;
    position: absolute;
    bottom: -60px;
    right: 0;
    left: 0;
    width: 0px;
    height: 0px;
    margin: auto; }
  .course-detail .interval p {
    color: #777;
    font-weight: bold;
    letter-spacing: 0.01em;
    text-align: center; }
  .course-detail .interval .bus:after {
    content: "";
    background: url(assets/images/course/detail/icon_bus.png) no-repeat;
    display: inline-block;
    width: 58px;
    height: 34px;
    margin: 0 0 5px 15px;
    background-size: contain;
    vertical-align: middle; }
  .course-detail .interval .walk:after {
    content: "";
    background: url(assets/images/course/detail/icon_walk.png) no-repeat;
    display: inline-block;
    width: 27px;
    height: 43px;
    margin: 0 0 5px 15px;
    background-size: contain;
    vertical-align: middle; }
  .course-detail .interval .train:after {
    content: "";
    background: url(assets/images/course/detail/icon_train.png) no-repeat;
    display: inline-block;
    width: 56px;
    height: 35px;
    margin: 0 0 5px 15px;
    background-size: contain;
    vertical-align: middle; }
  .course-detail .interval.spot {
    box-sizing: border-box;
    height: auto;
    min-height: 240px;
    padding: 40px;
    display: table;
    width: 100%; }
    .course-detail .interval.spot .text-area, .course-detail .interval.spot .thumb-area {
      display: table-cell;
      vertical-align: middle; }
    .course-detail .interval.spot .thumb-area {
      width: 300px; }
    .course-detail .interval.spot .detail-ttl {
      color: #777;
      font-size: 3rem;
      margin-bottom: 0; }
    .course-detail .interval.spot p {
      text-align: left; }
    .course-detail .interval.spot img {
      height: 160px;
      width: 300px; }

.course-detail .detail-map {
  margin-top: 40px;
  padding: 0 40px; }
  .course-detail .detail-map h2 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 20px; }
    .course-detail .detail-map h2 i:before {
      font-size: 5rem;
      vertical-align: bottom; }
  .course-detail .detail-map .detail-map-wrap {
    position: relative;
    overflow: hidden;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd; }
  .course-detail .detail-map iframe {
    width: 100%;
    height: 546px; }
  .course-detail .detail-map #map {
    max-width: 100%;
    height: 546px; }
  .course-detail .detail-map .map-list-wrap, .course-detail .detail-map .map-detail-wrap {
    display: none;
    box-sizing: border-box;
    padding: 50px 0 0;
    top: 0;
    width: 360px;
    height: 546px; }
    .course-detail .detail-map .map-list-wrap .area-title, .course-detail .detail-map .map-detail-wrap .area-title {
      font-size: 1.8rem;
      font-weight: bold;
      margin-left: 30px; }
      .course-detail .detail-map .map-list-wrap .area-title i:before, .course-detail .detail-map .map-detail-wrap .area-title i:before {
        font-size: 3rem;
        vertical-align: bottom; }
  .course-detail .detail-map .mdl-map-list > li {
    color: #00bbfb;
    font-weight: bold;
    width: 100%; }

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .course-detail .detail-wrap {
    background: #fff;
    padding: 0; }
  .course-detail .detail {
    font-size: 1.3rem;
    line-height: 2.25;
    letter-spacing: 0.01em; }
  .course-detail .detail-inner {
    padding: 40px 0; }
  .course-detail .lead {
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 7px; }
    .course-detail .lead p {
      display: block;
      padding-right: 0; }
    .course-detail .lead .summary {
      display: block;
      margin-top: 20px;
      overflow: hidden;
      width: 100%; }
      .course-detail .lead .summary ul li {
        width: 100%;
        line-height: 2.25;
        font-weight: bold; }
  .course-detail .course-spot {
    width: 100%;
    padding: 50px 7px 30px;
    box-sizing: border-box;
    display: block; }
    .course-detail .course-spot .text-area {
      display: block;
      width: 100%;
      padding-right: 0; }
      .course-detail .course-spot .text-area .btn-wrap {
        position: inherit;
        width: 100%;
        margin: 10px 0; }
    .course-detail .course-spot .thumb-area {
      display: block; }
      .course-detail .course-spot .thumb-area .main-thumb {
        width: 100%;
        margin-bottom: 10px; }
      .course-detail .course-spot .thumb-area .sub-thumb {
        width: calc((100% - 10px) / 2); }
  .course-detail .detail-ttl {
    font-size: 1.7em;
    line-height: 1; }
  .course-detail .detail-ttl-sub-top {
    font-size: .8em; }
  .course-detail .detail-ttl-sub {
    font-size: 1.3em; }
  .course-detail .detail-content {
    display: block;
    width: 100%; }
  .course-detail .interval {
    display: block; }
    .course-detail .interval.spot {
      padding: 40px 7px 30px;
      display: block; }
      .course-detail .interval.spot .text-area, .course-detail .interval.spot .thumb-area {
        display: block; }
      .course-detail .interval.spot .detail-ttl {
        font-size: 1.7em;
        line-height: 1; }
      .course-detail .interval.spot .thumb-area {
        width: 100%;
        margin: 20px 0 0; }
  .course-detail .detail-map {
    box-sizing: border-box;
    padding: 0 7px; }
    .course-detail .detail-map h2 {
      font-size: 1.5em; }
      .course-detail .detail-map h2 i:before {
        font-size: 1.7em; }
    .course-detail .detail-map .detail-map-wrap {
      border: none; }
    .course-detail .detail-map .map-list-wrap, .course-detail .detail-map .map-detail-wrap {
      display: none;
      height: 435px; }
    .course-detail .detail-map .mdl-map-list {
      padding: 0; }
    .course-detail .detail-map #map {
      height: 435px;
      margin-top: -47px; }
    .course-detail .detail-map iframe {
      height: 435px; } }

/* =========================================================
 State
========================================================= */
/* ==============================
動的
================================= */
#page-wrap.is-active {
  opacity: 1;
  visibility: visible; }

#modal-wrap.is-active {
  opacity: 1;
  visibility: visible; }

body.is-fixed {
  position: fixed;
  width: 100%;
  height: 100%; }

nav.is-active {
  opacity: 1;
  visibility: visible; }
  nav.is-active .nav-slide-cover {
    right: 0; }

.is-active .nav-btn-menu > span:nth-child(1) {
  top: 7px;
  transform: rotate(45deg); }

.is-active .nav-btn-menu > span:nth-child(2) {
  right: -200%; }

.is-active .nav-btn-menu > span:nth-child(3) {
  bottom: 6px;
  transform: rotate(-45deg); }

.search-nav.is-active {
  opacity: 1;
  visibility: visible;
  z-index: 1000; }

.search-btn.is-active .icon-search {
  opacity: 0;
  visibility: hidden; }

.search-btn.is-active .search-btn-close {
  opacity: 1;
  visibility: visible; }

.search-btn.is-deactivate {
  color: #989898; }

.nav-btn.is-deactivate .nav-btn-menu > span {
  background: #989898; }

.nav-btn.is-deactivate .search-btn-txt {
  color: #989898; }

.search-more-link .icon-arrow_bottom {
  transform: rotate(0deg);
  transition: .3s; }

.search-more-link.is-open .icon-arrow_bottom {
  transform: rotate(180deg); }

/* ==============================
module / タブナビゲーション
================================= */
.mdl-tab-nav > li.is-active .tab-nav-btn {
  color: #fff;
  background: #00b4de;
  border: 1px solid #00b4de;
  border-bottom: none; }

.mdl-tab-content > li {
  display: none; }
  .mdl-tab-content > li.is-active {
    display: block; }

/* ==============================
module / detail sns追従
================================= */
/* ==============================
module / areamap
================================= */
.mdl-map-list .is-active span {
  color: #2d8ad7; }

.map-list-wrap.is-hide, .is-hide.map-detail-wrap {
  left: -20%; }

.map-slide.is-selected {
  left: 0; }

/* ==============================
module / search
================================= */
