/* ==============================
module / archive
================================= */
.archive-nav{
  width: 1180px;
  margin:0 auto;
  @include flex-left;
  >li{
    -webkit-display: flex;
    display: flex;
    width: 280px;
    height: 60px;
    margin-right:20px;
    text-align: center;
    &:last-child{
      margin-right: 0;
    }
  }
  a{
    display: block;
    width: 100%;
    background: #fff;
    text-decoration: none;
    border-radius: 5px 5px 0 0;
  }
  span{
    display: inline-block;
    padding-top: 20px;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
  }
  .is-active{
    a{
      color: #fff;
    }
  }
  [class^="icon-"] ,[class*=" icon-"]{
    position: relative;
    padding-left: 45px;
    &:before{
      position: absolute;
      top: 9px;
      left: 0;
      font-size: 4rem;
      font-weight: normal;
    }
  }
  @include  max-980{
    [class^="icon-"] ,[class*=" icon-"]{
      padding-left: 20px;
      &:before{
        top: 1px;
        left: -8px;
        font-size: 3rem;
      }
    }
    .icon-ship{
      &:before{
        left: -10px;
      }
    }
  }
}
.archive-content-inner{
  width: 100%;
  max-width: 1180px;
  padding:45px 0 60px;
  margin:0 auto;
}
.archive-category-list{
  >li{
    display: inline-block;
    letter-spacing: normal;
    margin: 0 8px 10px 0;
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    padding:3px 10px;
    font-size: 1.8rem;
    font-weight: bold;
    text-decoration: none;
    background: #ddd;
    color: #989898;
    box-sizing: border-box;
    border-radius: 3px;
    &:hover{
      opacity: 1;
    }
  }
}
.archive-list-wrap{
  margin-top: 45px;
}
//archive-list
.archive-list{
  @include clearfix;
  >li{
    float: left;
    &:nth-child(even){
      margin-left: 25px;
    }
  }
}
.archive-areamap{
  position: fixed;
  width: 50px;
  height: 200px;
  right: 0;
  top:330px;
  z-index: 100;
  a{
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 20px;
    background: $color-skyblue;
    color:#fff;
    text-decoration: none;
    border-radius: 5px 0 0 5px;
    box-sizing: border-box;
    box-shadow: 3px 0 7px rgba(0,0,0,.35);
  }
  span{
    display: block;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.1;
    &:before{
      font-size: 2.5rem;
      font-weight: normal;
    }
  }
}
.archive-while-content{
  background: #fff;
}
/* ==============================
module / event
================================= */
.event{
  .page-visual{
    background-image: url(/assets/images/event/archive_visual.jpg);
    border-bottom:5px solid #98bc43;
  }
  //archive-nav
  .archive-nav{
    a{
      color: #98bc43;
    }
    .is-active{
      a{
        color: #fff;
        background: #98bc43;
        border: 2px solid #98bc43;
      }
    }
  }
  .archive-category-list{
    a{
      &:hover{
        background: #5abc43;
        color: #fff;
      }
    }
  }
  .pager-wrap{
    margin-top: 24px;
  }
}
.btn-calendar-inner{
  width: 210px;
  margin-left: auto;
  position: relative;

}
.btn-calendar{
  .event-calender-btn{
    display: inline-block;
    width: 210px;
    height: 40px;
    background-color: #98bc43;
    border-radius: 50px;
    font-size: 1.6rem;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-weight: bolder;
    right: 0;
    cursor: pointer;
    &:before{
      font-size: 2.6rem;
      vertical-align: sub;
    }
  }
  @include max-980{
    #event-calendar{
      display: none;
    }
    .flatpickr-mobile{
      position: relative;
      display: inline-block;
      width: 210px;
      height: 40px;
      background-color: #98bc43;
      border-radius: 50px;
      font-size: 1.6rem;
      text-align: center;
      color: #fff;
      font-weight: bolder;
      right: 0;
      cursor: pointer;
      &:before{
        position: absolute;
        top:5px;
        left: 10px;
        display: block;
        @include  icon;
        content: "\e90b";
        font-size: 2.6rem;
        vertical-align: middle;
      }
    }
  }
}
// @include max-980{
//   #event-calendar{
//     display: none;
//   }
// }
// #event-calendar-sp{
//   display: none;
//   @include max-980{
//     color: #fff;
//     display: flex;
//
//     position: relative;
//     &:before{
//       position: absolute;
//       left: 20px;
//       top: 8px;
//       font-size: 2.6rem;
//       vertical-align: sub;
//     }
//     .event-calender-btn{
//       border: none;
//     }
//     input::placeholder {
//       color: #fff;
//     }
//   }
// }
/* ==============================
module / event 年間の主要イベント
================================= */
.season-list{
  padding:20px 25px 35px;
  box-sizing: border-box;
  >li{
    margin-top:40px;
    &:first-child{
      margin-top: 0;
    }
  }
  @include max-980{
    padding:7px 7px 30px;
    >li{
      margin-top:30px;

    }
  }
}
.season-list-ttl-box{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include max-980{
    // align-items: center;

  }
}
.season-list-ttl-mon{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  @include max-980{
    font-size: 1.3rem;
    margin-bottom: 0;
  }
}
.season-list-ttl{
  img{
    width: auto;
    max-width: 100%;
  }
}
.season-list-content{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  @include max-980{
    display: block;
  }
}
.season-list-content-img{
  img{
    width: auto;
    max-width: 100%;
  }
  @include max-980{
    img{
      width: 100%;
      max-width: auto;
      max-width: initial;

    }
  }
}
.season-list-content-table{
  padding-left: 20px;
  width: calc(100% - 375px);
  box-sizing: border-box;
  table{
    width: 100%;
    table-layout: fixed;
  }
  tr{
    border-bottom: 1px solid #eee;
  }
  td{
    padding:15px 20px;
    font-size: 1.6rem;
    box-sizing: border-box;
    a{
      font-weight: bold;
    }
  }
  .icon-window{
    display: inline-block;
    margin-left: 5px;
    font-size: 1.8rem;
    width: auto;

  }
  @include max-980{
    padding-left: 0;
    margin-top: 15px;
    width: 100%;
    td{
      font-size: 1.3rem;
      padding:7px;
      &:nth-child(3){
        display: none;
      }
    }
    a{
      text-decoration: none;
    }
    .icon-window{
      margin-top: 3px;
    }
  }
}
.season-list-table-head{
  border-bottom: none;
  td{
    background: #eee;
    vertical-align: middle;
    position: relative;
    font-weight: bold;
    color: #646262;
    &:after{
      position: absolute;
      content: "";
      display: block;
      width: 3px;
      height: 100%;
      right: 0;
      top:0;
      background: #fff;
    }
  }
}
.season-list-table-cel1{
  width: 180px;
  @include max-980{
    width: 30%;
  }
}
.season-list-table-cel3{
  width: 180px;
}
////
.spring{
  .season-list-ttl-mon{
    color: #ec93c1;
  }
  @include max-980{
    .season-list-ttl{
      img{
        width: 141.5px;
      }
    }
  }
}
.summer{
  .season-list-ttl-mon{
    color: #4ebfcc;
  }
  @include max-980{
    .season-list-ttl{
      img{
        width: 153px;
      }
    }
  }
}
.autumn{
  .season-list-ttl-mon{
    color: #f6a168;
  }
  @include max-980{
    .season-list-ttl{
      img{
        width: 156.5px;
      }
    }
  }
}
.winter{
  .season-list-ttl-mon{
    color: #8f91d4;
  }
  @include max-980{
    .season-list-ttl{
      img{
        width: 154px;
      }
    }
  }

}

/* ==============================
module / feature
================================= */
.feature{
  .page-visual{
    background-image: url(/assets/images/feature/archive_visual.jpg);
  }
  .archive-list-wrap  {
    margin-top: 0;
  }
  .archive-list {
    float: inherit;
    @include flex-start;
    flex-wrap: wrap;
    >li{
      float: inherit;
      &:nth-child(even){
        margin-left: 0;
      }
    }
    &:after {
      display: none;
    }
  }
  .mdl-article-spot {
    .thumb-area {
      overflow: hidden;
      @include border-top-left-radius(5px);
      @include border-top-right-radius(5px);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: inherit;
        height: 100%;
        width: auto;
      }
    }
    .text-area {
      padding: 15px;
    }
  }
  .pager-wrap{
    margin-top: 24px;
  }
}

/* ==============================
module / course
================================= */
.course{
  .page-visual{
    background-image: url(/assets/images/course/archive_visual.jpg);
  }
  .archive-list-wrap  {
    margin-top: 0;
  }
  .archive-list {
    float: inherit;
    @include flex-start;
    flex-wrap: wrap;
    >li{
      float: inherit;
      &:nth-child(even){
        margin-left: 0;
      }
    }
    &:after {
      display: none;
    }
  }
  .mdl-article-spot {
    .thumb-area {
      overflow: hidden;
      @include border-top-left-radius(5px);
      @include border-top-right-radius(5px);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: inherit;
      }
    }
    .text-area {
      padding: 15px;
    }
  }
  .pager-wrap{
    margin-top: 24px;
  }
}

/* ==============================
module / usefulinfo
================================= */
.usefulinfo{
  .page-visual{
    background-image: url(/assets/images/usefulinfo/archive_visual.jpg);
    border-bottom:5px solid #00bbfb;
  }
  //archive-nav
  .archive-nav{
    a{
      color: #00bbfb;
    }
    .is-active{
      a{
        color: #fff;
        background: #00bbfb;
        border: 2px solid #00bbfb;
      }
    }
  }
  .archive-content-inner{
    width: 100%;
    max-width: 1180px;
    padding:45px 0 60px;
    margin:0 auto;
  }
  .archive-list-wrap{
    background-color: #fff;
    @include border-radius(5px);
    margin-top: 0;
    padding: 30px;
  }
  .archive-list{
    >li{
      float: none;
      border-bottom: 1px solid #eaeeef;
      margin-bottom: 30px;
      &:nth-child(even){
        margin-left: 0;
      }
      &:last-child{
        border: none;
        margin-bottom: 0;
      }

    }
  }
}


/* ==============================
module / search
================================= */
.search{
  .archive-content-inner{
    width: 100%;
    max-width: 1180px;
    padding:45px 0 60px;
    margin:0 auto;
    @include max-980{
      background: #fff;
      padding-top: 15px;
    }
  }
  .archive-list-wrap{
    background-color: #fff;
    @include border-radius(5px);
    margin-top: 0;
    padding: 30px;
    @include max-980{
      border-radius: 0;
      padding:15px 7px;
    };
  }
  .archive-list{
    >li{
      float: none;
      border-bottom: 1px solid #eaeeef;
      margin-bottom: 30px;
      &:nth-child(even){
        margin-left: 0;
      }
      &:last-child{
        border: none;
        margin-bottom: 0;
      }
    }
  }
  .archive-num{
    font-size: 1.8rem;
    font-weight: bold;
    color: $color-accent;
    margin-bottom: 5px;
    @include max-980{
      margin:0 7px;
      padding-bottom: 10px;
      border-bottom: 1px solid #eaeeef;
      // background: #fff;
      // margin-bottom: 0;
      font-size: 1rem;
    };
  }
  //search-event
  .search-even-col{
    width: 100%;
    box-sizing: border-box;
    vertical-align: middle;
  }
  .table-calendar-wrap{
    position: relative;
    display: inline-block;
    width: 290px;
    &:before{
      @include icon;
      position: absolute;
      display: block;
      content: "\e90b";
      width: auto;
      height: auto;
      top: 50%;
      left: 8px;
      font-size: 2.2rem;
      z-index: 2;
      color: $color-input;
      transform:translateY(-50%);
    }
    input{
      width: 100%;
      padding-left: 30px;
      font-size: 1.6rem;
      font-weight: bold;
    }
    @include max-980{
      margin-bottom: 10px;
      input{
        min-height:40px;
      }
    }
  }
  .table-calendar-center{
    font-size: 1.6rem;
    font-weight: bold;
    display: inline-block;
    padding: 0 15px;
    color: $color-gray;
    vertical-align: middle;
    @include max-980{
      margin-bottom: 10px;
      font-size: 1.5rem;
      vertical-align: baseline;


    }
  }
}
//カレンダー
.flatpickr-calendar{
  overflow: hidden;
}
.flatpickr-months{
  height: 60px;
  background: #989898;
}
.flatpickr-prev-month{
  height: 60px !important;
  box-sizing: border-box;
  svg{
    margin-top: 10px;
    color: #fff !important;
    fill: #fff !important;
  }
}
.flatpickr-next-month{
  height: 60px !important;
  box-sizing: border-box;
  svg{
    margin-top: 10px;
    color: #fff !important;
    fill: #fff !important;
  }
}
.flatpickr-month{
  height: 60px !important;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row-reverse;
  align-items:center;
}
.flatpickr-current-month{
  top: 8px;
}
.flatpickr-weekdays{
  margin-top:10px;
}
.flatpickr-current-month{
  height: 100% !important;
  width: auto !important;
  display: block !important;
  padding:0 !important;
  left: 150px !important;
  top: 13px !important;
}
.cur-month{
  display: block !important;
  font-size: 1.2rem !important;
  color: #fff !important;
  text-align: left;
}
.cur-year{
  font-size: 1.2rem !important;
  vertical-align: top  !important;
  margin-top: 3px !important;
}
.numInputWrapper{
  color: #fff !important;
}
.flatpickr-add-mon{
  width: 60px;
  font-size: 4rem;
  color: #fff ;
  position: absolute;
  left: 90px;
}
.prevMonthDay{
  pointer-events: none;
}
.nextMonthDay{
  pointer-events: none;
}
.flatpickr-day:nth-child(7n){
  color: #4094DD !important;
}
.flatpickr-day:nth-child(7n+1){
  color: #CE2222 !important;
}
.flatpickr-weekday:nth-child(7){
  color: #4094DD !important;
}
.flatpickr-weekday:nth-child(1){
  color: #CE2222 !important;
}
.flatpickr-day{
  &.selected{
    border:none !important;
    background: #7d7d7d !important;
    color: #fff !important;
  }
}
.calendar-green{
  .flatpickr-months{
    background: #98bc43;
  }
}
/* ==============================
module / areamap
================================= */
.areamap{
  .page-visual{
    background-image: url(/assets/images/areamap/archive_visual.jpg);
    border-bottom:5px solid #00bbfb;
  }
  //archive-nav
  .archive-nav{
    a{
      color: #00bbfb;
    }
    .is-active{
      a{
        color: #fff;
        background: #00bbfb;
        border: 2px solid #00bbfb;

      }
    }
  }
  .archive-content-inner{
    width: 100%;
    max-width: 1180px;
    padding:45px 0 60px;
    margin:0 auto;
  }
  .archive-list-wrap{
    background-color: #fff;
    @include border-radius(5px);
    margin-top: 0;
    padding: 30px;
  }
  .archive-list{
    >li{
      float: none;
      border-bottom: 1px solid #eaeeef;
      margin-bottom: 30px;
      &:nth-child(even){
        margin-left: 0;
      }
      &:last-child{
        border: none;
        margin-bottom: 0;
      }

    }
  }
}

/* ==============================
module / access
================================= */
.access{
  .page-visual{
    background-image: url(/assets/images/access/archive_visual.jpg);
    border-bottom:5px solid #00bbfb;
  }
  //archive-nav
  .archive-nav{
    a{
      color: #00bbfb;
    }
    .is-active{
      a{
        color: #fff;
        background: #00bbfb;
        border: 2px solid #00bbfb;

      }
    }
  }
}
.access-inner{
  position: relative;
  width: 100%;
  max-width: 1180px;
  background: #fff;
  margin: 0 auto;
  padding:40px;
  margin-top: 45px;
  margin-bottom: 60px;
  box-sizing: border-box;
  @include  max-980{
    padding:20px;
  }
}
.access-ttl-wrap{
  padding-bottom: 40px;
  border-bottom: 1px solid $color-skyblue;
  &.no-border{
    border-bottom: none;
    padding-bottom:0;
  }
}
.access-ttl{
  text-align: center;
  color: $color-skyblue;
  font-size: 3.6rem;
  vertical-align: middle;
  font-weight: bold;
  [class^="icon-"] ,[class*=" icon-"]{
    vertical-align: middle;
    &:before{
      font-size: 5rem;
    }
  }
  @include  max-980{
    font-size: 2.4rem;
    [class^="icon-"] ,[class*=" icon-"]{
      &:before{
        font-size: 3.4rem;
      }
    }

  }
}
.access-ttl-txt{
  margin-top: 30px;
  text-align: center;
  @include  max-980{
    text-align: left;
    br{
      display: none;
    }
  }
}
.access-content{
  margin-top: 40px;
}
//access-list
.access-list{
  >li{
    margin-top: 50px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.access-list-col-left{
  float: left;
  width: calc(100% - 356px);
  @include  max-980{
    float: none;
    width: 100%;
  }

}
.access-list-col-right{
  float:right;
  width: 326px;
  margin-left: 30px;
  @include  max-980{
    float: none;
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
}
.access-list-ttl{
  color: $color-skyblue;
  font-size: 2.4rem;
  font-weight: bold;
  @include  max-980{
    font-size: 1.8rem;

  }
}
.access-list-txt{
  margin-top: 5px;
}
//access-list-map
.access-list-map{
  margin-top: 30px;
}
.access-list-map-ttl{
  font-size: 1.8rem;
  font-weight: bold;
}
.access-list-map-attention{
  margin-top: 10px;
  text-align: right;
  font-size: 1.4rem;
  @include  max-980{
    font-size: 1.2rem;
  }
}
.access-list-map-img{
  margin-top: 5px;
  padding:20px 0;
  text-align: center;
  border:1px solid #d2d2d2;
  box-sizing: border-box;
  img{
    width: auto;
  }
  @include  max-980{
    padding:10px;
    overflow-x: auto;
    img{
      width: 518px;
    }
  }
}
.access-parking-guide{
  margin-top: 50px;
}
.parking-guide-inner{
  background:#f7f7f7;
  padding:10px 20px 40px;
  box-sizing: border-box;
   @include max-980{
     padding:10px 10px 30px;
   }
}
.parking-guide-ttl{
  background:$color-skyblue;
  color: #fff;
  font-size: 2.4rem;
  font-weight: bold;
  padding:10px 15px;
  @include max-980{
    font-size:1.8rem;
    padding:10px 10px;
  }
}
.parking-list{
  margin-top:30px;
  >li{
    margin-top: 30px;
    &:first-child{
      margin-top: 0;
    }
  }
  @include max-980{
    >li{
    margin-top: 20px;
    }
  }
}
.parking-list-inner{
  display: table;
  table-layout: fixed;
  width: 100%;
  @include max-640{
    display: block;
  }
}
.parking-list-img{
  width: 250px;
  display: table-cell;
  vertical-align:top;
  box-sizing: border-box;
  @include max-640{
    display: block;
    width: 100%;
  }
}
.parking-list-content{
  display: table-cell;
  padding-left: 20px;
  vertical-align:top;
  box-sizing: border-box;
  @include max-640{
    display: block;
    width: 100%;
    padding-left: 0;
    margin-top: 5px;
  }
}
.parking-list-ttl{
  font-size:2.4rem;
  font-weight: bold;
  a{
    text-decoration: none;
  }
  .icon-window{
    display:inline-block;
    position: relative;
    width:30px;
    height: 36px;
    margin-left: 5px;
    vertical-align: top;
    font-size:1.8rem;
    &:before{
      position: absolute;
      top:50%;
      transform:translateY(-50%);
    }
  }
  @include max-980{
    font-size:1.6rem;
    .icon-window{
      width:30px;
      height: 24px;
    }
  }
}
.access-def-list{
  >li{
    border-top:1px solid #eee;
    padding:20px 0;
  }
}

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px) {
  .archive-nav{
    background: #f7f7f7;
    width: 100%;
    padding:15px 7px 0;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    >li{
      width: 49%;
      height: 35px;
      margin-bottom: 7px;
      margin-right: 2%;
      &:nth-child(even){
        margin-right: 0;
      }
    }
    span{
      padding-top: 8px;
      font-size: 1.4rem;
    }
    a{
      font-size: 1.4rem;
      border-radius: 5px;
      border:2px solid;
      box-sizing: border-box;
    }
    .is-active{
      a{
        border:none;
      }
    }
  }
  .archive-content{
    padding:0 7px;
    box-sizing: border-box;
  }
  .archive-content-inner{
    padding-top: 20px;
  }
  .archive-category-list{
    >li{
      margin: 0 5px 7px 0;
    }
    a{
      font-size: 1rem;
    }
  }
  .archive-list-wrap{
    margin-top: 20px;
  }
  //.archive-list
  .archive-list{
    >li{
      &:nth-child(even){
        margin-left: 0;
      }
    }
  }
  //archive-areamap
  .archive-areamap{
    width: 75px;
    height: 75px;
    right: 7px;
    top:auto;
    bottom:30px;
    a{
      padding-top: 10px;
      border-radius: 50% 50%;
    }
    .icon-area{
      display: block;
    }
    span{
      display: inline;
      font-size: 1.1rem;
    }
    .archive-areamap-txt{
      display: block;
    }
  }


  //event
  .event{
    .page-visual{
      border-bottom: none;
    }
  }

  //feature
  .feature {
    .archive-list > li {
      width: 100%;
    }
    .mdl-article-spot {
      border: 1px solid #ddd;
      box-sizing: border-box;
      display: table;
      width: 100%;
      height: 116px;
      overflow: hidden;
      padding: 8px;
      .thumb-area {
        border-radius: 0;
        display: table-cell;
        width: 100px;
        height: 100px;
        img {
          height: 100%;
          width: auto;
        }
      }
      .text-area {
        display: table-cell;
        padding: 0 0 0 8px;
        font-size: 1rem;
        .title {
          font-size: 1.1rem;
        }
      }
    }
  }
  .mdl-col-list a::after {
    display: none;
  }

  //course
  .course {
    .archive-list > li {
      width: 100%;
    }
    .mdl-article-spot {
      border: 1px solid #ddd;
      box-sizing: border-box;
      display: table;
      width: 100%;
      height: 116px;
      overflow: hidden;
      padding: 8px;
      .thumb-area {
        border-radius: 0;
        display: table-cell;
        width: 100px;
        height: 100px;
        img {
          height: 100%;
          width: auto;
        }
      }
      .text-area {
        display: table-cell;
        padding: 0 0 0 8px;
        font-size: 1rem;
        .title {
          font-size: 1.1rem;
        }
      }
    }
  }
  .mdl-col-list a::after {
    display: none;
  }


  //usefulinfo
  .usefulinfo{
    .page-visual{
      border-bottom: none;
    }
    .archive-content{
      padding: 0px;
    }
    .archive-content-inner{
      padding-top: 20px;
    }
    .archive-list-wrap{
      padding: 20px 7px;
      background-color: #fff;
      border-radius: 0;
    }
    .archive-list{
      >li{
        margin-bottom: 15px;
      }
    }
  }
}
