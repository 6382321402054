/* ==============================
module / other
================================= */
body{
  background: $color-def-bg;
  &.clear{
    background: none;
  }
}

.clearfix{
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}
//detail-thumb
.detail-thumb
img{
  object-fit: contain;
  font-family: 'object-fit: contain;'
}
.thumb{
  img{
    object-fit: contain;
    font-family: 'object-fit: contain;'
  }
}

//dateSlideList
.dateSlide {
  font-weight: bold;
  margin-bottom: 45px;
  @include flex-start;
  .pn {
    @include border-radius(5px);
    background-color: #7d7d7d;
    cursor: pointer;
    color: #fff;
    width: 130px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    text-align: center;
    font-size: 1.6rem;
    position: relative;
    &:before {
      font-size: 3rem;
      font-weight: bold;
      position: absolute;
    }
    &.prev:before {
      left: 5px;
    }
    &.next:before {
      right: 5px;
    }
    &:hover {
      opacity: .7;
    }
    &.slick-disabled {
      color: #ddd;
      background: #aaa;
      cursor: inherit;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.dateSlideList {
  width: calc(100% - 138px *2);
  li {
    background: #fff;
    height: 60px;
    position: relative;
    &:after {
      background: $color-def-border;
      content: "";
      display: inline-block;
      height: 40px;
      position: absolute;
      right: 0px;
      top: 10px;
      width: 1px;
    }
  }
  .slick-list {
    @include border-radius(5px);
  }
}
//mdl-tab-layout-date
.mdl-tab-layout-date {
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  text-align: center;
  p {
    line-height: 1.4rem;
    margin-top: 12px;
    .mon {
      line-height: 1.4rem;
      margin-top: 12px;
      vertical-align: text-top;
    }
    .date {
      margin-left: 2px;
      font-size: 2.4rem;
    }
  }
  .day {
    line-height: 1;
  }
}
//sat & sun
.sat{
  color: #005275;
}
.sun {
  color: #db4233;
}

/* ==============================
 Responsive
================================= */
@media screen and (max-width: 980px){
  a{
    &:hover{
      opacity: 1 !important;
    }
  }
  .dateSlide {
    margin-bottom: 15px;
  }
  .dateSlideList {
    width: 100%;
    li {
      width: 70px;
    }
  }
  .event-list-area, .top-spot-list {
    margin: 0 8px 20px;
  }
}


/*----------------------------
mdoal
----------------------------*/
body{
  position: relative;
}
