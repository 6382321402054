/* ==============================
module / btn
================================= */
.btn-wrap{
  text-align: center;
  line-height: 1;
}
.btn{
  background: $color-skyblue;
  display: inline-block;
  text-decoration: none;
  color: #fff;
  width: 100%;
  max-width: 615px;
  padding:0 10px;
  font-size:2.4rem;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
  overflow: hidden;
  box-sizing: border-box;
  &:hover {
    opacity: 0.8;
  }
}

@media screen and (max-width:980px){
  .btn {
    font-size: 1.5rem;
    margin: 0 8px;
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;
    width: calc(100% - 16px);
  }
}
// .btn-l{
//   display: inline-block;
//   width: 100%;
//   max-width: 640px;
//   padding:0 10px;
//   font-size:1.6rem;
//   height: 55px;
//   line-height: 55px;
//   text-align: center;
//   border-radius: 5px;
//   font-weight: bold;
//   overflow: hidden;
//   box-sizing: border-box;
//   @include btn-color;
//   &.red-g{
//     line-height: 52px;
//   }
//   &.blue-g{
//     line-height: 52px;
//   }
//   &.yaloow-g{
//     line-height: 52px;
//   }
//   &.gray-g{
//     line-height: 52px;
//   }
//   &.white{
//     line-height: 53px;
//   }
//   &.green-white{
//     line-height: 53px;
//   }
// }
// .btn-s{
//   display: inline-block;
//   width: 100%;
//   max-width: 640px;
//   padding:0 10px;
//   font-size:1.2rem;
//   height: 30px;
//   text-align: center;
//   line-height: 30px;
//   border-radius: 5px;
//   font-weight: bold;
//   overflow: hidden;
//   box-sizing: border-box;
//   @include btn-color;
//   &.red-g{
//     line-height: 27px;
//   }
//   &.blue-g{
//     line-height: 27px;
//   }
//   &.yaloow-g{
//     line-height: 27px;
//   }
//   &.gray-g{
//     line-height: 27px;
//   }
//   &.white{
//     line-height: 28px;
//   }
//   &.green-white{
//     line-height: 28px;
//   }
// }
// //テーブル用
// .btn-t{
//   display: inline-block;
//   width: 160px;
//   padding:0 10px;
//   font-size:1.2rem;
//   height: 30px;
//   text-align: center;
//   line-height: 30px;
//   border-radius: 5px;
//   font-weight: bold;
//   overflow: hidden;
//   box-sizing: border-box;
//   @include btn-color;
//   &.red-g{
//     line-height: 27px;
//   }
//   &.blue-g{
//     line-height: 27px;
//   }
//   &.yaloow-g{
//     line-height: 27px;
//   }
//   &.gray-g{
//     line-height: 27px;
//   }
//   &.white{
//     line-height: 28px;
//   }
//   &.green-white{
//     line-height: 28px;
//   }
// }
// //追従
// //ifxed-bottom
// .fixed-bottom{
//   position: fixed;
//   width: 100%;
//   bottom:0;
//   left: 0;
//   background:rgba(242,242,242,.8);
//   z-index: 1000;
// }
// .fixed-bottom-inner{
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: top;
//   align-items: top;
//   -webkit-justify-content: center;
//   justify-content: center;
//   width: 1100px;
//   margin:0 auto;
//   padding:22px 0;
//   text-align: center;
// }
// .fixed-bottom-left{
//   width: 250px;
// }
// .fixed-bottom-right{
//   width: 380px;
//   margin-left: 20px;
// }
// .fixed-bottom-clear{
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: center;
//   align-items: center;
//   margin-left: 70px;
// }
// //btn-wrap-search
// .btn-wrap-search{
//   padding:30px 0 25px;
//   box-sizing: border-box;
//   .btn{
//     width: 200px;
//   }
//   .btn-l{
//     width: 280px;
//   }
// }
// //sideに入ったら
// .side-col{
//   .btn{
//     font-size: 1.2rem;
//   }
//   .btn-wrap{
//     margin-top: 10px;
//     &:first-child{
//       margin-top: 0;
//     }
//   }
// }
// //mdl-gray-btn-block
// .mdl-gray-btn-block{
//   width: 100%;
//   table-layout: fixed;
//   display: table;
//   background: #ddd;
//   border:1px solid #dddle;
//   box-sizing: border-box;
// }
// .gray-btn-block-left{
//   display: table-cell;
//   width: 100%;
//   padding:15px;
//   box-sizing: border-box;
//   vertical-align: middle;
// }
// .gray-btn-block-right{
//   display: table-cell;
//   width: 210px;
//   padding:15px;
//   box-sizing: border-box;
//   vertical-align: middle;
// }
// //page-head-btn
// .page-head-btn{
//   position: relative;
//   .btn-wrap{
//     position: absolute;
//     width: 200px;
//     bottom: 0;
//     right: 0;
//   }
// }
//
// //modal-btns
// //モーダル内専用
// .modal-btns{
//   .btn-wrap{
//     margin-top:0;
//   }
// }
//
// //btn-box-two
// .btn-box-two{
//   display: -webkit-flex;
//   display: flex;
//   -webkit-align-items: center;
//   align-items: center;
//   -webkit-justify-content: center;
//   justify-content: center;
//   >.btn-wrap{
//     margin:0 10px;
//   }
// }
//
//
