@charset "utf-8";
//mixin

//clearfix
@mixin clearfix{
  &:after{
    content: "";
    display: block;
    clear: both;
  }
}
//@media
@mixin max-640 {
    @media screen and (max-width : 639px) {
        @content;
    }
}
@mixin max-768 {
    @media screen and (max-width : 767px) {
        @content;
    }
}
@mixin max-980 {
    @media screen and (max-width : 980px) {
        @content;
    }
}

//font-family
@import url(http://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat);

@mixin min{
  font-family: 'Noto Sans Japanese', sans-serif;
}

@mixin en {
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.01em;
}
//icon
@mixin icon{
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//color
//base
$color-def:#3e3e3e;
$color-def-bg:#f6f6f6;
$color-def-border:#DDD;
$color-accent:#4b4b4b;
$color-input:#989898;


// primary
$color-skyblue:#00bbfb;
$color-softblue:#d7edf2;

// secondary
$color-ygreen:#81ac1c;
$color-turquoise:#00b4de;
$color-orange:#fa9e2c;
$color-red:#f73c35;
$color-blue:#2d8ad7;
$color-pink:#e71865;
$color-green:#00b063;
$color-purple:#a36b98;
$color-gray:#767676;

//background
// $color-bg-table:#E6E6E6;
// $color-bg-table-blue:#F2F9F9;
// $color-bg-table-gray:#F2F2F2;

//link,btn
$color-link:#2d8ad7;

@mixin gr-red{
  background: rgba(221,44,44,1);
  background: -moz-linear-gradient(top, rgba(221,44,44,1) 0%, rgba(178,22,22,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(221,44,44,1)), color-stop(100%, rgba(178,22,22,1)));
  background: -webkit-linear-gradient(top, rgba(221,44,44,1) 0%, rgba(178,22,22,1) 100%);
  background: -o-linear-gradient(top, rgba(221,44,44,1) 0%, rgba(178,22,22,1) 100%);
  background: -ms-linear-gradient(top, rgba(221,44,44,1) 0%, rgba(178,22,22,1) 100%);
  background: linear-gradient(to bottom, rgba(221,44,44,1) 0%, rgba(178,22,22,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dd2c2c', endColorstr='#b21616', GradientType=0 );
}
@mixin gr-blue{
  background: rgba(82,160,209,1);
  background: -moz-linear-gradient(top, rgba(82,160,209,1) 0%, rgba(6,71,136,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(82,160,209,1)), color-stop(100%, rgba(6,71,136,1)));
  background: -webkit-linear-gradient(top, rgba(82,160,209,1) 0%, rgba(6,71,136,1) 100%);
  background: -o-linear-gradient(top, rgba(82,160,209,1) 0%, rgba(6,71,136,1) 100%);
  background: -ms-linear-gradient(top, rgba(82,160,209,1) 0%, rgba(6,71,136,1) 100%);
  background: linear-gradient(to bottom, rgba(82,160,209,1) 0%, rgba(6,71,136,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#52a0d1', endColorstr='#064788', GradientType=0 );
}
@mixin gr-yellow{
  background: rgba(254,213,79,1);
  background: -moz-linear-gradient(top, rgba(254,213,79,1) 0%, rgba(193,129,26,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(254,213,79,1)), color-stop(100%, rgba(193,129,26,1)));
  background: -webkit-linear-gradient(top, rgba(254,213,79,1) 0%, rgba(193,129,26,1) 100%);
  background: -o-linear-gradient(top, rgba(254,213,79,1) 0%, rgba(193,129,26,1) 100%);
  background: -ms-linear-gradient(top, rgba(254,213,79,1) 0%, rgba(193,129,26,1) 100%);
  background: linear-gradient(to bottom, rgba(254,213,79,1) 0%, rgba(193,129,26,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fed54f', endColorstr='#c1811a', GradientType=0 );
}
@mixin gr-gray{
background: rgba(239,239,239,1);
background: -moz-linear-gradient(top, rgba(239,239,239,1) 0%, rgba(193,193,193,1) 100%);
background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(239,239,239,1)), color-stop(100%, rgba(193,193,193,1)));
background: -webkit-linear-gradient(top, rgba(239,239,239,1) 0%, rgba(193,193,193,1) 100%);
background: -o-linear-gradient(top, rgba(239,239,239,1) 0%, rgba(193,193,193,1) 100%);
background: -ms-linear-gradient(top, rgba(239,239,239,1) 0%, rgba(193,193,193,1) 100%);
background: linear-gradient(to bottom, rgba(239,239,239,1) 0%, rgba(193,193,193,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#efefef', endColorstr='#c1c1c1', GradientType=0 );
}
//other
$colro-form:#9B9B9B;

$color-auction-1:#C47474;
$color-auction-2:#5395B7;
$color-auction-3:#F49310;
$color-auction-4:#84D04D;
$color-auction-5:#BF9514;


//color set
$cate-color: (
 event: #{$color-ygreen},
 feature: #{$color-turquoise},
 amusement: #{$color-orange},
 tourism: #{$color-red},
 leaning: #{$color-blue},
 food: #{$color-pink},
 shopping: #{$color-green},
 relax: #{$color-purple},
 others: #{$color-gray}
);

//btn-color set
@mixin btn-color {
  text-decoration: none;
  &:hover{
    opacity: .8;
  }
  &.white{
    background: inherit;
    color: #fff;
    border: 1px solid #fff;
  }
}

//icon
@mixin icon {
  /* use !important to prevent issues with browser extensions that change /font */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

//table set
@mixin table {
  display: table;
  width: 100%;
  table-layout: fixed;
}
@mixin table-cell {
  display: table-cell;
  box-sizing: border-box;
  vertical-align: top;
}
//flex set
@mixin flex-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@mixin flex-start {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@mixin flex-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
  -webkit-justify-content: start;
  justify-content: start;
}

//border-radius set
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin border-top-left-radius($radius) {
  -webkit-border-top-left-radius: $radius;
      -moz-border-radius-topleft: $radius;
          border-top-left-radius: $radius;
}
@mixin border-top-right-radius($radius) {
  -webkit-border-top-right-radius: $radius;
      -moz-border-radius-topright: $radius;
          border-top-right-radius: $radius;
}
@mixin border-bottom-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
      -moz-border-radius-bottomright: $radius;
          border-bottom-right-radius: $radius;
}
@mixin border-bottom-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
      -moz-border-radius-bottomleft: $radius;
          border-bottom-left-radius: $radius;
}
