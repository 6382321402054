/* ==============================
layout/layout
================================= */
.inner{
  width: 100%;
  max-width: 1180px;
  margin:0 auto;
  padding: 60px 0;
}
.page-wrap{
  width: 1180px;
  padding-top: 20px;
  padding-bottom: 60px;
  margin:0 auto;
  box-sizing: border-box;
}
.l-main{
  width: 100%;
  min-width: 1280px;
}
.l-side-container{
  display: table;
  width: 100%;
  table-layout: fixed;
}
.l-side{
  width: 270px;
  display: table-cell;
  box-sizing: border-box;
  vertical-align: top;
}
.l-content{
  padding-left: 30px;
  display: table-cell;
  box-sizing: border-box;
  vertical-align: top;
}

@media screen and (max-width:980px) {
  .inner {
    max-width: inherit;
    padding: 25px 0 30px;
  }
  .l-main{
    min-width: 100%;
    margin-top: 50px;
  }
}
