/* ==============================
module / form
================================= */
input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="date"],
textarea
{
  padding:7px;
  border-radius: 4px;
  font-size: 1.2rem;
  border:1px solid #D5D5D5;
  box-sizing: border-box;
  color: $color-input;
}
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="email"]{
  width: 130px;
}
input[type="text"]{
  width: 66.7%;
}
//textarea
textarea{
  width: 75%;
  resize: none;
  &.bid{
    width: 100%;
    height: 60px;
  }
}
select{
  width: 130px;
  padding:0 8px;
  height: 30px;
  border-radius: 4px;
  font-size: 1.2rem;
  border:1px solid #D5D5D5;
  box-sizing: border-box;
}
input[type="radio"],
input[type="checkbox"]
{
  margin-right: 5px;
}
label{
  display:-webkit-flex;
  display:flex;
  -webkit-align-items: top;
  align-items: top;
  input[type="radio"]{
    margin-top: 0;
  }
  input[type="checkbox"]{
    margin-top: 0;
  }
  >span{
    display: inline-block;
    vertical-align: top;
    line-height:1.71;
  }
}
.select-cover{
  position: relative;
  display: inline-block;
  &:before{
    position: absolute;
    top: 8px;
    right: 7px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #363636;
    pointer-events: none;
  }
  &:after{
    position: absolute;
    bottom: 8px;
    right: 7px;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #363636;
    pointer-events: none;
  }
  &.full{
    width: 66.7%;
    select{
    width: 100%;
    }
  }
  &.max{
    width: 100%;
    select{
    width: 100%;
    }
  }
}
.table-calendar-wrap{
  position: relative;
  display: inline-block;
  &:before{
    position: absolute;
    display: block;
    content: url(/images/common/calendar.png);
    width: 16px;
    height: 16px;
    top:50%;
    right: 8px;
    transform:translateY(-50%);
    z-index: 2;
  }
}
input[type="text"].table-calendar{
  width: auto;
}
.table-calendar{
  position: relative;
  // background: #F2F2F2;
  // background: rgba(242,242,242,.5);
  z-index: 1;
}
.panel-block{
  label{
    transition: .3s;
  }
  input[type="radio"]{
    display: none;
    &:checked{
      +label{
        background: url(assets/images/common/radio_ck.png) no-repeat  center left;
        background-size:24px 24px;
      }
    }
    +label{
      background: url(assets/images/common/radio.png) no-repeat  center left;
      background-size:24px 24px;
    }
  }
  span{
    font-size: 1.8rem;
    font-weight: bold;
    padding-left: 30px;
    color: #767676;
  }
  @include max-980{
    input[type="radio"]{
      &:checked{
        +label{
          background: url(assets/images/common/radio_ck_sp.png) no-repeat  center left;
          background-size:18px 18px;
        }
      }
      +label{
        background: url(assets/images/common/radio_sp.png) no-repeat  center left;
        background-size:18px 18px;
      }
    }
    span{
      font-size: 1.3rem;
      font-weight: bold;
      padding-left: 20px;
      color: #767676;
    }
  }
}
