/* ==============================
module / course detail
================================= */
.course-detail {
  .detail-wrap{
    width: 100%;
    padding:30px 0 60px;
    background: $color-def-bg;
  }
  .detail{
    font-size: 1.6rem;
    max-width: 1180px;
    margin:0 auto;
    background: #fff;
    border-radius: 0;
    box-sizing: border-box;
    p {
      line-height: 2;
    }
  }
  .page-visual{
    background-image: url(/assets/images/course/detail/sightseeing/course_visual.jpg);
  }
  .detail-inner{
    padding:60px 0 40px;
  }
  .detail-content{
    width: 100%;
    margin:0 auto;
  }

  //lead
  .lead {
    display: table;
    margin:0 40px 35px;
    width: 1100px;
    p {
      display: table-cell;
      padding-right: 50px;
    }
    .summary {
      background: $color-def-bg;
      display: table-cell;
      width: 605px;
      p {
        background: $color-skyblue;
        box-sizing: border-box;
        color: #fff;
        font-size: 1.8rem;
        font-weight: bold;
        display: block;
        height: 42px;
        line-height: 42px;
        padding-left: 24px;
        width: 100%;
      }
      ul {
        margin: 24px;
        @include flex-start;
        flex-wrap: wrap;
        li {
          line-height: 2;
          width: 50%;
          a {
            color: $color-skyblue;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .course-spot {
    padding: 50px 0;
    display: table;
    margin: 0 auto;
    width: 1100px;
    .text-area {
      display: table-cell;
      padding-right: 50px;
      vertical-align: top;
      width: 570px;
      position: relative;
      .btn-wrap {
        bottom: 0;
        position: absolute;
        width: 570px;
      }
      p{
        margin-bottom:100px;
      }
      @include max-980{
        p{
          margin-bottom:0;
        }
      }
    }
    .thumb-area {
      display: table-cell;
      vertical-align: bottom;
      @include clearfix;
      .main-thumb {
        width: 480px;
        margin-bottom: 15px;
      }
      .sub-thumb {
        float: left;
        width: calc((100% - 15px) / 2);
      }
      img:nth-child(3) {
        float: right;
      }
    }
  }
  //detail-ttl
  .detail-ttl{
    color: $color-skyblue;
    font-size: 3.6rem;
    margin-bottom: 10px;
  }
  .detail-ttl-sub {
    font-size: 2.4rem;
    margin-bottom: 15px;
  }
  .detail-ttl-sub-top{
    border-bottom: 1px solid $color-skyblue;
    color: $color-skyblue;
    display: block;
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  //interval {
  .interval {
    background: $color-def-bg;
    padding: 40px 0 20px;
    position: relative;
    &:before {
      content: "";
      border: 30px solid transparent;
      border-top-color: #fff;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 0px;
      height: 0px;
      margin: auto;
    }
    &:after {
      content: "";
      border: 30px solid transparent;
      border-top-color: $color-def-bg;
      position: absolute;
      bottom: -60px;
      right: 0;
      left: 0;
      width: 0px;
      height: 0px;
      margin: auto;
    }
    p {
      color: #777;
      font-weight: bold;
      letter-spacing: 0.01em;
      text-align: center;
    }
    .bus {
      &:after {
        content:"";
        background: url(assets/images/course/detail/icon_bus.png) no-repeat;
        display: inline-block;
        width: 58px;
        height: 34px;
        margin: 0 0 5px 15px;
        background-size: contain;
        vertical-align: middle;
      }
    }
     .walk {
      &:after {
        content:"";
        background: url(assets/images/course/detail/icon_walk.png) no-repeat;
        display: inline-block;
        width: 27px;
        height: 43px;
        margin: 0 0 5px 15px;
        background-size: contain;
        vertical-align: middle;
      }
    }
     .train {
      &:after {
        content:"";
        background: url(assets/images/course/detail/icon_train.png) no-repeat;
        display: inline-block;
        width: 56px;
        height: 35px;
        margin: 0 0 5px 15px;
        background-size: contain;
        vertical-align: middle;
      }
    }
    &.spot {
      box-sizing: border-box;
      height: auto;
      min-height: 240px;
      padding: 40px;
      display: table;
      width: 100%;
      .text-area, .thumb-area {
        display: table-cell;
        vertical-align: middle;
      }
      .thumb-area {
        width: 300px;
      }
      .detail-ttl {
        color: #777;
        font-size: 3rem;
        margin-bottom: 0;
      }
      p {
        text-align: left;
      }
      img {
        height: 160px;
        width: 300px;
      }
    }
  }
  //detail-map
  .detail-map{
    margin-top: 40px;
    padding: 0 40px;
    h2 {
      font-size: 2.4rem;
      font-weight: bold;
      margin-bottom: 20px;
      i {
        &:before {
          font-size: 5rem;
          vertical-align: bottom;
        }
      }
    }
    .detail-map-wrap {
      position: relative;
      overflow: hidden;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
    iframe{
      width: 100%;
      height: 546px;
      // pointer-events:none;
    }
    #map {
      // max-width: 740px;
      max-width: 100%;
      height: 546px;
      // margin-top: -46px;
    }
    .map-list-wrap {
      display: none;
      box-sizing: border-box;
      padding: 50px 0 0;
      top: 0;
      width: 360px;
      height: 546px;
      .area-title {
        font-size: 1.8rem;
        font-weight: bold;
        // text-align: center;
        margin-left: 30px;
        i {
          &:before {
            font-size: 3rem;
            vertical-align: bottom;
          }
        }
      }
    }
    .mdl-map-list > li {
      color: $color-skyblue;
      font-weight: bold;
      width: 100%;
    }
  }
}


/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px){
  .course-detail {
    .detail-wrap{
      background: #fff;
      padding:0;
    }
    .detail {
      font-size: 1.3rem;
      line-height: 2.25;
      letter-spacing: 0.01em;
    }
    .detail-inner {
      padding: 40px 0;
    }
    //lead
    .lead {
      box-sizing: border-box;
      display: block;
      width: 100%;
      margin: 0;
      padding: 0 7px;
      p {
        display: block;
        padding-right: 0;
      }
      .summary {
        display: block;
        margin-top: 20px;
        overflow: hidden;
        width: 100%;
        ul {
          li {
            width: 100%;
            line-height: 2.25;
            font-weight: bold;
          }
        }
      }
    }
    //course-spot
    .course-spot {
      width: 100%;
      padding: 50px 7px 30px;
      box-sizing: border-box;
      display:block;
      .text-area {
        display: block;
        width: 100%;
        padding-right: 0;
        .btn-wrap {
          position: inherit;
          width: 100%;
          margin: 10px 0;
        }
      }
      .thumb-area {
        display: block;
        .main-thumb {
          width: 100%;
          margin-bottom: 10px;
        }
        .sub-thumb {
          width: calc((100% - 10px) / 2);
        }
      }
    }
    //detail-ttl
    .detail-ttl{
      font-size: 1.7em;
      line-height: 1;
    }
    .detail-ttl-sub-top {
      font-size: .8em;
    }
    .detail-ttl-sub {
      font-size: 1.3em;
    }
    .detail-content{
      display: block;
      width: 100%;
    }

    //interval
    .interval {
      display: block;
      &.spot {
        padding: 40px 7px 30px;
        display: block;
        .text-area, .thumb-area {
          display: block;
        }
        .detail-ttl {
          font-size: 1.7em;
          line-height: 1;
        }
        .thumb-area {
          width: 100%;
          margin: 20px 0 0;
        }
      }
    }
    .detail-map{
      box-sizing: border-box;
      padding: 0 7px;
      h2 {
        font-size: 1.5em;
        i {
          &:before {
            font-size: 1.7em;
          }
        }
      }
      .detail-map-wrap {
        border: none;
      }
      .map-list-wrap {
        display: none;
        height: 435px;
      }
      .mdl-map-list {
        padding: 0;
      }
      #map {
        height: 435px;
        margin-top:-47px;
      }
      iframe{
        height: 435px;
      }
    }
  }
}
