/* ==============================
module / detail
================================= */
.detail-wrap{
  width: 100%;
  padding:30px 0 60px;
  background: #cde9ef;
}
.detail{
  max-width: 1180px;
  margin:0 auto;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
}
.detail-inner{
  padding:30px 10px 55px;
}
//detail-ttl
.detail-ttl{
  font-size: 2.4rem;
}
.detail-ttl-txt{
  margin-right: 5px;
  vertical-align: middle;
}
//detail-header
.detail-header{
  padding:0 30px 30px;
  border-bottom: 1px solid #e6e6e6;
  box-sizing: border-box;
}
.detail-category{
  letter-spacing: -.4em;
  >li{
    display: inline-block;
    margin-right: 10px;
    background:#eaeeef;
    letter-spacing: normal;
  }
  a{
    padding:5px 10px;
    color: #3e3e3e;
    display: block;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    text-decoration:none;
  }
  +.detail-ttl{
    margin-top: 20px;
  }
}
.detail-ttl-bottom{
  >li{
    position: relative;
    display: inline-block;
    margin-right: 20px;
    padding-left: 25px;
    &:before{
      position: absolute;
      font-size: 2.6rem;
      top:-5px;
      left: 0;
    }
  }
}
.detail-date{
}
.detail-adderess{
}
.detail-content{
  display: table;
  table-layout: fixed;
  width: 1100px;
  margin:0 auto;
}
.detail-left{
  display: table-cell;
  position: relative;
  vertical-align: top;
}
.detail-right{
  display: table-cell;
  width: 392px;
  vertical-align: top;
}
//detail-label
.detail-label{
  display: inline-block;
  padding:3px 10px;
  margin-left: 5px;
  text-align: center;
  font-size: 1.4rem;
  color: #fff;
  &.event{
    background: $color-ygreen;
  }
  &.feature{
    background: $color-turquoise;
  }
  &.amusement{
    background: $color-orange;
  }
  &.tourism{
    background: $color-red;
  }
  &.leaning{
    background: $color-blue;
  }
  &.food{
    background: $color-pink;
  }
  &.shopping{
    background: $color-green;
  }
  &.relax{
    background: $color-purple;
  }
  &.others{
    background: $color-gray;
  }
}
//detail-sns
.detail-sns{
  position: absolute;
  height: 170px;
  top: 100px;
  left: -65px;
  z-index: 10;
  >li{
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50% 50%;
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
    span{
      display: inline-block;
      height: 50px;
      line-height: 50px;
      color: #fff;
    }
  }
}
.detail-sns-facebook{
  background: #355193;
  span{
    font-size: 5rem;
  }
}
.detail-sns-twitter{
  background: #57aeed;
  span{
    font-size: 3.5rem;
  }
}
.detail-sns-line{
  background: #19bc03;
  span{
    font-size: 5rem;
  }
}
//detail-txt
.detail-txt-wrap{
  margin-top: 30px;
  padding:0 40px 0  0;
  img{
    max-width: 100%;
  }
}
.detail-txt{
  margin-top: 40px;
  line-height:2.25;
  letter-spacing:0.01em;
}
//detail-side
.detail-side{
  margin-top: 30px;
  padding:4px;
  border-radius: 5px;
  box-sizing: border-box;
  .icon-window{
    display: inline-block;
    vertical-align: middle;
    font-size: 1.8rem;
  }
  &.basic{
    background: #eee;
    .detail-side-ttl{
      &:before{
        content: url(assets/images/detail/icon_01.png);
        display: block;
        top:-11px;
      }
    }
  }
  &.coupon{
    background: url(assets/images/common/bg_01.png);
    .detail-side-ttl{
      border-color: #fcd9d9;
      &:before{
        color: #eb6877;
      }
    }
  }
  &.event{
    background: #e8f0d5;
    .detail-side-ttl{
      border-color: #e8f0d5;
      &:before{
        color: #b2c488;
      }
    }
  }
  +.detail-side{
    margin-top: 35px;
  }
}
.detail-side-inner{
  padding:20px 20px 40px;
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
}
.detail-side-ttl{
  position: relative;
  padding:0 0 10px 35px;
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  &:before{
    position: absolute;
    font-size: 3rem;
    left: 0;
    top: -7px;
  }

}
.detail-side-content{
  margin-top: 20px;
}
.detail-side-list{
  >li{
    margin-top: 30px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.detail-side-list-ttl{
  font-weight: bold;
  font-size: 1.4rem;
  a{
    text-decoration:none;
  }
}
.detail-side-list-txt{
  margin-top: 10px;
}
.detail-station-list{
  >li{
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-top: 10px;
    &:first-child{
      margin-top: 0;
    }
  }
}
.detail-station-left{
  display: table-cell;
  width: 90px;
  padding-right: 10px;
  box-sizing: border-box;
  vertical-align: top;
}
.detail-station-right{
  display: table-cell;
  vertical-align: top;
}
//detail-map
.detail-map{
  margin-top: 40px;
  iframe{
    width: 100%;
    height: 440px;
  }
}
//detail-side-qr
.detail-side-qr{
  padding:10px;
  margin-top: 40px;
  background: #f5f5f5;
  box-sizing: border-box;
  border-radius: 5px;
}
.detail-side-qr-inner{
  display: table;
  table-layout: fixed;
  width: 100%;
}
.detail-side-qr-left{
  display: table-cell;
  vertical-align: middle;
  padding-right: 10px;
  box-sizing: border-box;
}
.detail-side-qr-right{
  width: 144px;
  display: table-cell;
  vertical-align: middle;
}
.detail-side-qr-ttl{
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem;
}
.detail-side-qr-btn{
  display: block;
  background: #fff;
  padding:10px;
  margin-top: 20px;
  border:1px solid #cdcdcd;
  text-decoration: none;
  border-radius: 5px;
  span{
    display: inline-block;
    font-size: 2rem;
    vertical-align: middle;
    &:before{
      color: $color-def;
    }
  }
  .detail-side-qr-btn-txt{
    font-size: 1.4rem;
    color: $color-def;
    text-decoration: none;
    letter-spacing:0.01em;
  }

}
//slide
.detail-thumb{
  margin-top: 15px;
  text-align: center;
  letter-spacing: -.4rem;
  >li{
    display: inline-block;
    width: 50px;
    height: 50px;
    margin-right: 20px;
    letter-spacing: normal;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: contain;'
  }
  @media screen and (max-width: 980px) {
    >li{
      width: 13px;
      height: 13px;
      margin-right:7px;
      background: #989898;
      border-radius: 50% 50%;
      &.thumbnail-current{
        background: #333;
      }
    }
    img{
      display: none;
    }
  }
}

/* ==============================
module / relation-list
================================= */
.l-relation-list{
  background: #fff;
}
.detail-relation-list{
  width: 1180px;
  margin: 0 auto;
  @include flex-center;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  >li{
  }
}
/* ==============================
module / detail-spot
================================= */
.l-detail-spot{
}
.detail-spot-list{
  width: 1180px;
  margin: 0 auto;
  @include flex-left;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  >li{
    margin-left: 45px;
    &:first-child{
      margin-left: 0;
    }
  }
}

/* ============================================================
@media screen and (max-width: 980px)
=============================================================== */
@media screen and (max-width: 980px){
  .detail-wrap{
    background: #fff;
    padding:0;
  }
  .detail-inner{
    padding:20px 0 30px;
  }
  .detail-header{
    padding:0 10px 15px;
  }
  //.detail-sns
  .detail-sns{
    left: 7px;
    >li{
      width: 40px;
      height: 40px;

      span{
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .detail-sns-facebook{
    span{
      font-size: 4rem;
    }
  }
  .detail-sns-line{
    span{
      font-size: 4rem;
    }
  }
  //detail-ttl
  .detail-ttl{
    font-size: 1.7em;
    line-height: 1;
  }
  .detail-ttl-txt{
    display: block;
  }
  //detail-label
  .detail-label{
    font-size: 1rem;
    margin-left:0;
    margin-right: 5px;
  }
  //.detail-ttl-bottom
  .detail-ttl-bottom{
    margin-top: 5px;
    >li{
      display: block;
      font-size: 1rem;
      padding-left: 20px;
      margin:5px 0 0;
      &:first-child{
        margin-top: 0;
      }
      &:before{
        font-size: 2rem;
      }
    }
  }
  //.detail-txt-wrap
  .detail-txt-wrap{
    margin-top: 15px;
    padding:0 7px 0;
    font-size: 1.3rem;
  }
  .detail-content{
    display: block;
    width: 100%;
  }
  .detail-left{
    display: block;
    width: 100%;
  }
  .detail-right{
    display: block;
    width: 100%;
  }
  .detail-map{
    iframe{
      height: 435px;
    }
  }
  //detail-right
  .detail-right{
    margin-top: 35px;
    padding:0 7px 0;
    box-sizing: border-box;
  }
  //l-relation-list
  .l-relation-list{
    background: #cde9ef;
    .inner{
      padding:20px 7px 30px;
      box-sizing: border-box;
    }
  }
  //detail-relation-list
  .detail-relation-list{
    width: 100%;
    >li{
    }
  }

  // l-detail-spot
  .l-detail-spot{
    .inner{
      padding:20px 7px 30px;
      box-sizing: border-box;
    }
  }
  .detail-spot-list{
    width: 100%;
    >li{
      width: 49%;
      &:nth-child(even){
        margin-left: 1%;
      }
      &:nth-child(odd){
        margin-left: 0;
      }
    }
    .mdl-article-spot{
      width: 100%;
    }
  }


}
