@charset "utf-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

/*-----------------------------------------------------------------
	Customize: tcm 4f
	Last Updated : 2018.06.28
-----------------------------------------------------------------*/

/*-----------------------------------------------------------------
		基本設定
-----------------------------------------------------------------*/
/*要素のフォントサイズやマージン・パディングをリセット*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
	margin:0;
	padding:0;
	border:0;
	outline:0;
}
body, div, pre, p, blockquote, form, fieldset, input, textarea, select, option, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, table, th, td, tr, embed, object, a, img {
	word-wrap: break-word;
}
html{
	font-size: 62.5%;
}

body {
	font-size: 1.4rem;
	line-height:1;
	font-family: "メイリオ", Meiryo,"ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", Verdana, "游ゴシック", YuGothic,  sans-serif;
  -webkit-text-size-adjust: 100%;
}

/*新規追加要素のデフォルトはすべてインライン要素になっているので、section要素などをブロック要素へ変更*/
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section {
	display:block;
}

blockquote, q {
	quotes:none;
}

/*blockquote要素、q要素の前後にコンテンツを追加しないように指定*/
blockquote:before, blockquote:after,
q:before, q:after {
	content:'';
	content:none;
}

/*縦方向の揃え位置を中央揃えに指定しています*/
input, select {
	vertical-align:middle;
}

/*画像を縦に並べた時に余白が出ないように*/
img {
	border: 0;
	font-size: 0;
	line-height: 0;
}
table {
	border-spacing: 0;
	border-collapse: collapse;
}

/* クリア */
.clearfix:after{
	content: ".";
	display: block;
	height: 0;
	clear: both;
	visibility: hidden;
	font-size: 1px;
}
.clearfix{
	display: inline-table;
}
 /* Hides from IE-mac \*/
* html .clearfix{
	height: 1%;
}
.clearfix{
	display: block;
}
 /* End hide from IE-mac */

*:first-child+html .clearfix { /* IE7用 */
	width: 100%;
}

/*-----------------------------------------------------------------
		調整用
-----------------------------------------------------------------*/
/* 整列系 */
.ta-center { text-align: center !important;}
.ta-right { text-align: right;}
.ta-left { text-align: left;}

/* フォント関連 */
.font-normal { font-weight: normal;}
.fs100 { font-size: 100% !important;}
.fs100 li { font-size: 100% !important;}

/* マージン調整 */
.mt0  { margin-top: 0px  !important;}
.mt5  { margin-top: 5px  !important;}
.mt10 { margin-top: 10px !important;}
.mt15 { margin-top: 15px !important;}
.mt20 { margin-top: 20px !important;}
.mt25 { margin-top: 25px !important;}
.mt30 { margin-top: 30px !important;}
.mt35 { margin-top: 35px !important;}
.mt40 { margin-top: 40px !important;}
.mt45 { margin-top: 45px !important;}
.mt50 { margin-top: 50px !important;}

.mb0  { margin-bottom: 0px  !important;}
.mb5  { margin-bottom: 5px  !important;}
.mb10 { margin-bottom: 10px !important;}
.mb15 { margin-bottom: 15px !important;}
.mb20 { margin-bottom: 20px !important;}
.mb25 { margin-bottom: 25px !important;}
.mb30 { margin-bottom: 30px !important;}
.mb35 { margin-bottom: 35px !important;}
.mb40 { margin-bottom: 40px !important;}
.mb45 { margin-bottom: 45px !important;}
.mb50 { margin-bottom: 50px !important;}
.mb60 { margin-bottom: 60px !important;}
.mb80 { margin-bottom: 80px !important;}
.mb100 { margin-bottom: 100px !important;}
.mb110 { margin-bottom: 110px !important;}
.mb120 { margin-bottom: 120px !important;}

.ml0  { margin-left: 0px  !important;}
.ml5  { margin-left: 5px  !important;}
.ml10 { margin-left: 10px !important;}
.ml15 { margin-left: 15px !important;}
.ml20 { margin-left: 20px !important;}
.ml25 { margin-left: 25px !important;}
.ml30 { margin-left: 30px !important;}
.ml35 { margin-left: 35px !important;}
.ml40 { margin-left: 40px !important;}
.ml45 { margin-left: 45px !important;}
.ml50 { margin-left: 50px !important;}

.mr0  { margin-right: 0px  !important;}
.mr5  { margin-right: 5px  !important;}
.mr10 { margin-right: 10px !important;}
.mr15 { margin-right: 15px !important;}
.mr20 { margin-right: 20px !important;}
.mr25 { margin-right: 25px !important;}
.mr30 { margin-right: 30px !important;}
.mr35 { margin-right: 35px !important;}
.mr40 { margin-right: 40px !important;}
.mr45 { margin-right: 45px !important;}
.mr50 { margin-right: 50px !important;}


.pt0  { padding-top: 0px  !important;}
.pt5  { padding-top: 5px  !important;}
.pt10 { padding-top: 10px !important;}
.pt15 { padding-top: 15px !important;}
.pt20 { padding-top: 20px !important;}
.pt25 { padding-top: 25px !important;}
.pt30 { padding-top: 30px !important;}
.pt35 { padding-top: 35px !important;}
.pt40 { padding-top: 40px !important;}
.pt45 { padding-top: 45px !important;}
.pt50 { padding-top: 50px !important;}

.pb0  { padding-bottom: 0px  !important;}
.pb5  { padding-bottom: 5px  !important;}
.pb10 { padding-bottom: 10px !important;}
.pb15 { padding-bottom: 15px !important;}
.pb20 { padding-bottom: 20px !important;}
.pb25 { padding-bottom: 25px !important;}
.pb30 { padding-bottom: 30px !important;}
.pb35 { padding-bottom: 35px !important;}
.pb40 { padding-bottom: 40px !important;}
.pb45 { padding-bottom: 45px !important;}
.pb50 { padding-bottom: 50px !important;}
.pb60 { padding-bottom: 60px !important;}
.pb80 { padding-bottom: 80px !important;}
.pb100 { padding-bottom: 100px !important;}
.pb110 { padding-bottom: 110px !important;}
.pb120 { padding-bottom: 120px !important;}

.pl0  { padding-left: 0px  !important;}
.pl5  { padding-left: 5px  !important;}
.pl10 { padding-left: 10px !important;}
.pl15 { padding-left: 15px !important;}
.pl20 { padding-left: 20px !important;}
.pl25 { padding-left: 25px !important;}
.pl30 { padding-left: 30px !important;}
.pl35 { padding-left: 35px !important;}
.pl40 { padding-left: 40px !important;}
.pl45 { padding-left: 45px !important;}
.pl50 { padding-left: 50px !important;}

.pr0  { padding-right: 0px  !important;}
.pr5  { padding-right: 5px  !important;}
.pr10 { padding-right: 10px !important;}
.pr15 { padding-right: 15px !important;}
.pr20 { padding-right: 20px !important;}
.pr25 { padding-right: 25px !important;}
.pr30 { padding-right: 30px !important;}
.pr35 { padding-right: 35px !important;}
.pr40 { padding-right: 40px !important;}
.pr45 { padding-right: 45px !important;}
.pr50 { padding-right: 50px !important;}
